import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from "reactstrap";
import { authHeader } from '../helpers/auth-header';
import { getApplicationType, getContactID, getSupplierID, getSupportDetails, handleBackground } from "../helpers/general";
import { history } from '../helpers/history';
import Login from './Auth/Login';
import Translation from './controls/Translation';
import Footer from './layout/Footer';
import NavMenu from './layout/NavMenu';

const moment = require('moment');

require('datatables.net-bs4');

const Home = () => {
    const [finalSearchString, setFinalSearchString] = useState('');
    const [currentDate, setCurrentDate] = useState('');
    const [tenderCounts, setTenderCounts] = useState({ intend: -1, public: -1, total: -1 });
    const [supportDetails, setSupportDetails] = useState({ telephoneNo: '', email: '', address: '' });
    const [user, setUser] = useState({ supplierID: -1, contactID: -1 });
    const [systemNotifications, setSystemNotifications] = useState({ notifications: [], importantNotifications: [] });
    const [application, setApplication] = useState('');
    const [showPublicTenderSearch, setShowPublicTenderSearch] = useState(false);

    useEffect(() => {
        getApplicationType()
            .then(application => {
                setApplication(application);

                setUser(prev => ({
                    ...prev,
                    supplierID: getSupplierID(),
                    contactID: getContactID()
                }));
            });
    }, []);


    useEffect(() => {
        if (application !== '') {
            fetch(`./Settings/GetNotifications?systemName=${application}&loggedIn=${false}`, {
                method: 'GET',
                headers: authHeader(false, true)
            })
                .then(response => response.json())
                .then(notificationsData => {
                    if (notificationsData) {
                        setSystemNotifications({ notifications: notificationsData.notifications, importantNotifications: notificationsData.importantNotifications });
                    }
                })
                .catch(err => {
                    console.log("ERROR")
                    console.log(err);
                });

            fetch(`./NavMenu/CheckPageEnabled?pageTitle=Nav_PublicTenders&supplierID=${user.supplierID}&systemName=${application}`, {
                method: 'GET',
                headers: authHeader(false, true)
            })
                .then(response => response.json())
                .then(isEnabled => {
                    setShowPublicTenderSearch(isEnabled);
                })
        }
    }, [application]);


    useEffect(() => {
        if (user.supplierID > 0) {
            history.push('/dashboard');
        }

        setCurrentDate(new moment().format('YYYY-MM-DD'));
        handleBackground();

        getSupportDetails().then(data => {
            setSupportDetails({ telephoneNo: data.telephoneNo, email: data.email, address: data.address });
        });

        return () => {
            setSupportDetails({ telephoneNo: '', email: '', address: '' });
        }
    }, [user]);


    useEffect(() => {
        if (currentDate) {
            setFinalSearchString(`filter=Date>'${currentDate}'`);
        }
    }, [currentDate]);


    useEffect(() => {
        if (finalSearchString !== '' && currentDate && application !== '') {
            fetch(`./Tenders/GetLiveIntendTenders?strEOIDeadline=${currentDate}&application=${application}`, {
                method: 'GET',
                headers: authHeader(false, true)
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        setTenderCounts({ intend: data.intendTenders, public: data.publicTenders, total: data.totalLiveTenders });
                    }
                })
                .catch(err => {
                    console.log("ERROR")
                    console.log(err);
                })
        }
    }, [application, currentDate, finalSearchString])

    const handleTenderSearch = () => {
        history.push({ pathname: `./publictenders`, state: { searchObject: { keywords: document.getElementById('tenderSearch').value, orderByDirection: 0, orderBy: 0 } } });
    }

    return (
        <>
            <NavMenu />
            <main className="main-content u-container">
                {Object.keys(systemNotifications.importantNotifications).length > 0 &&
                    <div className="c-system-notifications c-system-notifications--important">
                        <h2 className="c-system-notifications__title">Important Notifications</h2>
                        <ul className="c-system-notifications__list">
                            {systemNotifications.importantNotifications.map((notification, i) => {
                                return (
                                    <li className="c-system-notifications__list-item" key={i}>
                                        <span dangerouslySetInnerHTML={{ __html: notification.notificationText }} />
                                    </li>
                                )
                            })
                            }
                        </ul>
                    </div>
                }

                {Object.keys(systemNotifications.notifications).length > 0 &&
                    <div className="c-system-notifications">
                        <h2 className="c-system-notifications__title">System Notifications</h2>
                        <ul className="c-system-notifications__list">
                            {systemNotifications.notifications.map((notification, i) => {
                                return (
                                    <li className="c-system-notifications__list-item" key={i}>
                                        <span dangerouslySetInnerHTML={{ __html: notification.notificationText }} />
                                    </li>
                                )
                            })
                            }
                        </ul>
                    </div>
                }

                <div>
                    <Row>
                        <Col xs={12} md={6} lg={6} xl={7} className="c-intro-text" style={{ fontSize: "1rem" }}>
                            <h2 className="u-component-spacing u-branded-underline">
                                <Translation ID='Sell2Name' />
                            </h2>
                            <Translation ID="HomeSell2Description1" customClass='u-component-spacing' paragraph={true} />
                            <Translation ID="HomeSell2Description2" customClass='u-component-spacing' paragraph={true} />
                            <Translation ID="HomeSell2Description3" customClass='u-component-spacing' paragraph={true} />
                        </Col>
                        <Col xs={12} md={{ size: 5, offset: 1 }} lg={{ size: 5, offset: 1 }} xl={{ size: 4, offset: 1 }} className="padding-w-25">
                            <Login />
                        </Col>
                    </Row>

                    <Card body className="u-component-spacing--3x">
                        <Row>
                            <Col xxl={5} className="col-xxl-mod u-flex-column">
                                <div className="u-component-spacing--2x u-flex-center">
                                    <h3 className="c-broken-underline" style={{ fontSize: "1.4rem" }}>
                                        Live Tenders Currently on <Translation ID='Sell2Name' />
                                    </h3>
                                </div>
                                <div className="c-stat-counter u-flex-center u-component-spacing">
                                    {tenderCounts.intend > -1 &&
                                        <p className="c-stat-counter__stat">
                                            <strong className="c-stat-counter__stat-figure js-intend-tenders" style={{ "--num": tenderCounts.intend }}></strong>
                                            <span className="c-stat-counter__label">In-tend tenders</span>
                                        </p>
                                    }
                                    {tenderCounts.public > -1 &&
                                        <p className="c-stat-counter__stat">
                                            <strong className="c-stat-counter__stat-figure js-public-tenders" style={{ "--num": tenderCounts.public }}></strong>
                                            <span className="c-stat-counter__label">Public tenders</span>
                                        </p>
                                    }
                                    {tenderCounts.total > -1 &&
                                        <p className="c-stat-counter__stat">
                                            <strong className="c-stat-counter__stat-figure js-total-tenders" style={{ "--num": tenderCounts.total }}></strong>
                                            <span className="c-stat-counter__label">Total tenders</span>
                                        </p>
                                    }
                                </div>

                                <div className="u-flex-center u-flex-column__100pc">
                                    <p style={{ fontWeight: 500, padding: "2rem 0 3rem", fontSize: "1.125rem", textAlign: "center" }}>
                                        Still need help? Please call us on <a className="u-no-wrap" href={`tel:${supportDetails.telephoneNo}`}>{supportDetails.telephoneNo}</a>
                                    </p>
                                </div>
                            </Col>

                            {showPublicTenderSearch === true &&
                                <Col xxl={7} className="col-xxl-mod">
                                    <div className="u-component-spacing--2x u-flex-center">
                                        <h3 className="c-broken-underline" style={{ fontSize: "1.4rem" }} >Search for Tenders</h3>
                                    </div>

                                    <form onSubmit={() => { handleTenderSearch() }}>
                                        <div className="u-flex-center">
                                            <div className="c-curved-input u-component-spacing">
                                                <div className="c-floating-label u-component-spacing">
                                                    <input className="c-floating-label__input" data-error-message="Please enter a keyword" placeholder=" " type="search" name="tenderSearch" id="tenderSearch" required />
                                                    <label className="c-floating-label__label" htmlFor="tenderSearch">Search by keyword</label>
                                                </div>
                                                <input type="submit" className="c-curved-input__submit c-cta u-component-spacing" value="Search" onClick={() => handleTenderSearch()} />
                                            </div>
                                        </div>
                                    </form>
                                </Col>
                            }
                        </Row>
                    </Card>
                </div>
            </main>

            <Footer
                infoStrap={true}
            />

        </>
    )
}

export default Home;