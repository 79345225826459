import React, { useEffect, useState } from 'react';
import { getResourceString } from '../../helpers/general';

const Translation = (props) => {
    let { ID, StringOnly = false, required = false, customClass = 'c-form__label', htmlFor = '', paragraph = false } = props;
    const [resource, setResource] = useState('');

    useEffect(() => {
        let isCancelled = false;

        getResourceString(ID).then(data => {
            if (!isCancelled) {
                setResource(data);
            }
        })

        return () => {
            isCancelled = true;
        };
    }, [ID]);

    function createMarkup() {
        return { __html: resource }
    }

    if (paragraph) {
        return (<p className={customClass} dangerouslySetInnerHTML={createMarkup()} />)
    }
    else {
        // if the htmlFor is not provided, then it is not a label - thus just return text.
        if (htmlFor === '') {
            return resource;
        }
        else {
            if (StringOnly) {
                return (
                    <>
                        <label htmlFor={htmlFor} className={required ? 'c-form__label--required ' + customClass : customClass}>resource</label>
                    </>
                );
            } else {
                return (
                    <>
                        <label htmlFor={htmlFor} className={(required ? 'c-form__label--required ' : '') + customClass} dangerouslySetInnerHTML={createMarkup()} />
                    </>
                );
            }
        }
    }
}

export default Translation;