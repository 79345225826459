import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { getApplicationType, handleBackground, pageEnabled } from "../../helpers/general";
import Footer from "../layout/Footer";
import NavMenu from "../layout/NavMenu";
import PublicTenderSearch from "../Tenders/PublicTenderSearch";
import TenderSearchResults from "../Tenders/TenderSearchResults";

require('datatables.net-bs4');

const PublicTenders = () => {
    const [isSearching, setIsSearching] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);
    const [searchParams, setSearchParams] = useState({ searchObject: {}, prevLocation: '' });
    const location = useLocation();
    const [isPageEnabled, setIsPageEnabled] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);

    useEffect(() => {
        pageEnabled('Nav_PublicTenders', '/404').then(enabled => { setIsPageEnabled(enabled); });

        handleBackground();
    }, [])

    useEffect(() => {
        getApplicationType()
            .then(applicationName => {
                if (location.state && location.state.searchObject) {
                    setSearchParams({
                        searchObject: {
                            keywords: location.state.searchObject.keywords,
                            orderBy: location.state.searchObject.orderBy,
                            orderByDirection: location.state.searchObject.orderByDirection,
                            systemName: applicationName
                        },
                        prevLocation: location.pathname
                    });
                } else {
                    setSearchParams({
                        searchObject: {
                            keywords: '',
                            orderByDirection: 0,
                            orderBy: 0,
                            systemName: applicationName
                        },
                        prevLocation: location.pathname
                    });
                }
            });
        setFirstLoad(false);
    }, [location.pathname, location.state])

    if (isPageEnabled === true) {
        return (
            <>
                <NavMenu />
                <main>
                    <PublicTenderSearch
                        setIsSearching={setIsSearching}
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                        setPageNumber={setPageNumber}
                    />
                    <TenderSearchResults
                        searchParams={searchParams}
                        isSearching={isSearching}
                        pages
                        loggedIn={false}
                        setIsSearching={setIsSearching}
                        firstLoad={firstLoad}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                    />
                </main>
                <Footer />
            </>
        )
    } else {
        return null;
    }
}

export default PublicTenders;