import "bootstrap/dist/css/bootstrap.css";
import React, { useState } from "react";
import { Button, Tooltip } from 'reactstrap';


const UpgradeButton = props => {
    const { packageID, price, upgradePermission, handleProceedToPay } = props;
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    return (
        <>
            <Button id={`upgrade${packageID}`} className="c-price-plans__cta u-no-wrap" disabled={!upgradePermission} onClick={() => handleProceedToPay(packageID, price)}>
                Upgrade
            </Button>
            {!upgradePermission &&
                <Tooltip
                    placement="top"
                    isOpen={tooltipOpen}
                    target={`upgrade${packageID}`}
                    toggle={toggleTooltip}
                >
                    You do not have the right permissions to upgrade the account.
                </Tooltip>
            }
        </>
    );
};

export default UpgradeButton;