import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Login from '../Auth/Login';
import NavMenu from '../layout/NavMenu';
import { useLocation } from "react-router-dom";
import { getApplicationType, handleBackground } from "../../helpers/general";
import Footer from "../layout/Footer";
import { authHeader } from "../../helpers/auth-header";
import { Link } from "react-router-dom";

require('datatables.net-bs4');

// This is to allow the SPA to use parameters - as the routing only allows useParams when directed from within the application
function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const EmailVerification = () => {

    let query = useQuery();
    const vid = query.get('vid');

    const [verified, setVerified] = useState('');

    useEffect(() => {
        handleBackground();

        if (vid) {
            getApplicationType().then(application => {
                fetch(`./Registration/CompleteRegistration?vid=${vid}&applicationName=${application}`, {
                    method: 'GET',
                    headers: authHeader(false, true),
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.success === true) {
                            setVerified(true);
                        } else {
                            setVerified(false);
                        }
                    })
                    .catch(err => { });
            });
        }
    }, [vid]);


    return (
        <>
            <NavMenu />
            {verified === true &&
                <div className="main-content u-container u-container--small container-fluid">
                    <div className="u-flex-center u-component-spacing" style={{ color: "rgb(255, 255, 255)" }}>
                        <h2 className="u-component-spacing u-branded-underline">Email Address Verified</h2>
                    </div>

                    <div className="row u-component-spacing">
                        <div className="col-md u-component-spacing--2x">
                            <div className="card main-content__inner" style={{ height: "calc(100% - 1rem)" }} >
                                <div className="card-body">
                                    <div className="c-card card u-component-spacing" style={{ height: "calc(100% - 1rem)" }}>
                                        <div className="card-body">
                                            <h3 className="c-broken-underline u-component-spacing">Registration Complete</h3>
                                            <div className="c-form u-component-spacing--2x">
                                                <p>
                                                    Thank you for verifying your account. Your registration is now complete and your account has been set up
                                                </p>                                            
                                                <div className="c-login-forms__form u-component-spacing">
                                                    <Login />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {verified === false &&
                <div className="main-content u-container u-container--small container-fluid">
                    <div className="u-flex-center u-component-spacing" style={{ color: "rgb(255, 255, 255)" }}>
                        <h2 className="u-component-spacing u-branded-underline">Email Address Verification Error</h2>
                    </div>

                    <div className="row u-component-spacing">
                        <div className="col-md u-component-spacing--2x">
                            <div className="card main-content__inner" style={{ height: "calc(100% - 1rem)" }} >
                                <div className="card-body">
                                    <div className="c-card card u-component-spacing" style={{ height: "calc(100% - 1rem)" }}>
                                        <div className="card-body">
                                            <h3 className="c-broken-underline u-component-spacing">Unable to Verify Email</h3>
                                            <p className="u-component-spacing">There has been a problem verifying your email.</p>
                                            <p className="u-component-spacing">Please contact our Sell2 support team.</p>

                                            <div className="u-flex-end u-component-spacing">
                                                <Link to='/home'>Return to home page</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {!verified === '' &&
                <></>
            }

            <Footer />
        </>
    )
}

export default EmailVerification;