import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from 'reactstrap';
import { authHeader } from '../../helpers/auth-header';
import { getApplicationType, getContactID, getSupplierID, pageEnabled } from "../../helpers/general";
import { history } from '../../helpers/history';
import QuantitySelector from "../controls/QuantitySelector";
import Translation from "../controls/Translation";

require('datatables.net-bs4');

const Products = () => {
    const [services, setServices] = useState([]);
    const [canPurchaseAddons, setCanPurchaseAddons] = useState(null);
    const [quantities, setQuantities] = useState({});
    const [total, setTotal] = useState(0)
    const [user, setUser] = useState({ supplierID: -1, contactID: -1 });
    const [packageDetails, setPackageDetails] = useState({});
    const [purchasePermission, setPurchasePermission] = useState(false);
    const [isPageEnabled, setIsPageEnabled] = useState(false);

    useEffect(() => {
        pageEnabled('Nav_Products', '/404').then(enabled => { setIsPageEnabled(enabled); });

        setUser(prev => ({
            ...prev,
            supplierID: getSupplierID(),
            contactID: getContactID()
        }))
    }, []);

    useEffect(() => {
        if (user.supplierID > 0) {
            fetch(`./Permissions/CanPurchaseProducts?contactID=${user.contactID}`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        setPurchasePermission(data);
                    }
                })
                .catch(error => console.log('Error: ', error));

            getApplicationType()
                .then(application => {
                    fetch(`./Suppliers/GetSupplierPackageDetails?supplierID=${user.supplierID}&systemName=${application}`, {
                        method: 'GET',
                        headers: authHeader()
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data) {
                                setPackageDetails({ package: data.packageName, expiryDate: data.expiryDate, allowances: data.allowanceDetails })
                            }
                        });
                });

            fetch(`./Packages/GetPackageServices?supplierID=${user.supplierID}`, {
                method: 'GET',
                headers: authHeader(false, true)
            })
                .then(response => response.json())
                .then(packageServices => {
                    setServices(packageServices);
                })
                .catch(err => {
                    console.log(err);
                });

            fetch(`./Packages/PackageAllowAddonsBySupplierID?supplierID=${user.supplierID}`, {
                method: 'GET',
                headers: authHeader(false, false)
            })
                .then(response => response.json())
                .then(data => {
                    setCanPurchaseAddons(data);
                })
                .catch(err => {
                    console.log(err);
                });

            return () => {
                setPackageDetails({});
            }
        }
    }, [user])


    const handleSetValue = (id, value, subTotal) => {
        setQuantities(prev => ({
            ...prev,
            [id]: value
        }))

        setTotal(total + subTotal);
    }

    const handleAddToBasket = () => {
        if (total > 0) {
            let data = [];
            Object.entries(quantities).forEach(ctrl => {
                let name = ctrl[0];
                let value = ctrl[1];

                if (value) {
                    data.push({
                        field: name,
                        value: value.value ? parseInt(value.value) : value
                    });
                }
            });

            fetch(`./Payment/CreateBasket?supplierID=${user.supplierID}&contactID=${user.contactID}&totalPrice=${total}`, {
                method: 'POST',
                headers: authHeader(false, false),
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(data => {
                    history.push(`/basket`)
                })
                .catch(err => {
                    console.log("Error");
                });
        }
    }

    if (isPageEnabled === true) {
        return (
            <main>
                <div className="padding-l-25 padding-r-20 card main-content__inner">
                    {Object.keys(packageDetails).length > 0 &&
                        <>
                            <Card body>
                                <h3 className="c-broken-underline u-component-spacing">
                                    Package
                                </h3>
                                <div className="row u-component-spacing--2x">
                                    <div className="col-md">
                                        Your current package is: <strong><Translation ID={packageDetails.package} /></strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md">
                                        <input type="submit" className="c-cta u-component-spacing" value="Upgrade Package" onClick={() => history.push('/upgrade')} />
                                    </div>
                                </div>
                            </Card>

                            <Card body>
                                <h3 className="c-broken-underline u-component-spacing">
                                    <Translation ID="AdditionalProducts" />
                                </h3>
                                {purchasePermission &&
                                    <div className="row u-component-spacing">
                                        <div className="col-md u-component-spacing--2x">

                                            {Object.keys(services).length > 0 && services.map((service, i) => {
                                                return (
                                                    <div className="c-product-row" key={i}>
                                                        <QuantitySelector
                                                            id={service.displayID}
                                                            name={service.name}
                                                            description={service.description}
                                                            priceLabel={`${service.priceExc} / ${service.name}`}
                                                            price={service.priceExc}
                                                            quantities={quantities}
                                                            handleChange={handleSetValue}
                                                            packagePermitsAddons={canPurchaseAddons && packageDetails.allowances.find(x => x.uniqueID === service.uniqueID).allowance !== '-1'}
                                                            maxQuantity={service.valueType === 'Boolean' ? 1 : null}

                                                        />
                                                    </div>
                                                )
                                            })
                                            }
                                            <hr />
                                            {canPurchaseAddons &&
                                                <>
                                                    <div className="c-total-cost">
                                                        <Row>
                                                            <Col xs={10}>
                                                                <p className="c-total-cost__label">Total:</p>
                                                            </Col>
                                                            <Col xs={2}>
                                                                <p className="c-total-cost__value">&pound;{total}</p>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    {total > 0 &&
                                                        <Row style={{ marginTop: '20px' }}>
                                                            <Col md={12} className='c-proceed-to-pay'>
                                                                <button className="c-cta" onClick={() => handleAddToBasket()}>Add to basket</button>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                }

                                {!purchasePermission &&
                                    <h5 className="u-component-spacing--2x">You do not have permission to make addition product purchases</h5>
                                }
                            </Card >
                        </>
                    }
                </div>
            </main>
        )
    } else {
        return null;
    }
}

export default Products;