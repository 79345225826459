import React, { useEffect, useState } from 'react';
import { NavLink as Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, UncontrolledDropdown } from 'reactstrap';
import { getContactID, getContactName, getSupplierID } from '../../helpers/general';
import BasketNav from './BasketNav';

const NavMenuPrivate = props => {
    let { navMenu, packagesEnabled } = props;
    const [user, setUser] = useState({ supplierID: -1, contactID: -1 });
    const [contactName, setContactName] = useState('');

    useEffect(() => {
        setUser(prev => ({
            ...prev,
            supplierID: getSupplierID(),
            contactID: getContactID()
        }))
    }, [])

    useEffect(() => {
        if (user.contactID > 0) {
            getContactName(user.contactID)
                .then(name => {
                    setContactName(name);
                })

            return () => setContactName('');
        }

    }, [user.contactID])

    const replace = (value) => {
        let replacedValue = value
            .replace('[[CONTACT_NAME]]', contactName)
            .replace('[[CONTACT_ID]]', user.contactID)
            .replace('[[SUPPLIER_ID]]', user.supplierID);

        return replacedValue;
    }

    return (
        <Nav className="mr-auto nav" navbar>
            {navMenu.map((navItem, i) => {
                if (Object.keys(navItem.childNavItems).length > 0) {
                    return (
                        <UncontrolledDropdown key={i} nav inNavbar>
                            <DropdownToggle nav caret className="text-dark">
                                {navItem.title}
                            </DropdownToggle>
                            <DropdownMenu right>
                                {navItem.childNavItems.map((childNavItem, j) => {
                                    return (
                                        <span key={j}>
                                            {childNavItem.includeDivider &&
                                                <DropdownItem divider />
                                            }
                                            <DropdownItem tag={Link} activeClassName="active" className="text-dark" to={replace(childNavItem.routing)} >{replace(childNavItem.title)}</DropdownItem>
                                        </span>
                                    )
                                })}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    )
                } else {
                    return (
                        <NavItem key={i}>
                            <NavLink tag={Link} activeClassName="active" className={navItem.class} to={navItem.routing}>{navItem.title}</NavLink>
                        </NavItem>
                    )
                }
            })}
            {packagesEnabled && <BasketNav />}
        </Nav>
    );
}

export default NavMenuPrivate;