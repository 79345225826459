import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from "../reducers/index";

let middleware = [];

if (process.env.NODE_ENV === 'development') {
    middleware = [...middleware, thunkMiddleware];
} else {
    middleware = [...middleware, thunkMiddleware];
}

export const store = createStore(
    reducer,
    composeWithDevTools(
        applyMiddleware(
            ...middleware
        )
    )
);