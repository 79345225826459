import { authHeader } from "../helpers/auth-header";

export const settingActions = {
    getWebSettings
}

export const SETTINGS_REQUEST = 'SETTINGS_REQUEST';
export const WEB_SETTINGS_SUCCESS = 'WEB_SETTINGS_SUCCESS';
export const WEB_SETTINGS_FAILURE = 'WEB_SETTINGS_FAILURE';

function request() { return { type: SETTINGS_REQUEST }; }
function webSettingsSuccess(data) { return { type: WEB_SETTINGS_SUCCESS, data }; }
function webSettingsFailure(error) { return { type: WEB_SETTINGS_FAILURE, error }; }

export function getWebSettings() {
    return dispatch => {
        dispatch(request());

        fetch('./Settings/GetWebSettings', {
            method: 'GET',
            headers: authHeader(false, true)
        })
            .then(response => response.json())
            .then(data => {
                dispatch(webSettingsSuccess(data));
            })
            .catch(error => {
                dispatch(webSettingsFailure(error));
            });
    }
}