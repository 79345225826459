import React from 'react';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";

const MerchantAuthorisation = () => {
    let location = useLocation();
    let authForm = atob(location.state.paymentForm);

    useEffect(() => { document.forms[0].submit(); }, [])

    return (
        <div dangerouslySetInnerHTML={{ __html: authForm }}></div>
    )
}

export default MerchantAuthorisation;