import { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { authHeader } from '../../helpers/auth-header';
import { getApplicationType, handleBackground, useQuery } from '../../helpers/general';

const MerchantAuthorisationSuccess = () => {
    const query = useQuery();
    const history = useHistory();

    useEffect(() => {
        handleBackground();

        let paymentConfirmation = {
            'orderID': query.get('orderID'),
            'payID': query.get('PAYID'),
            'status': query.get('STATUS'),
            'ncerror': query.get('NCERROR'),
            'acceptance': query.get('ACCEPTANCE'),
            'currency': query.get('currency'),
            'pm': query.get('PM'),
            'brand': query.get('BRAND'),
            'amount': query.get('amount')
        }

        let data = [];
        Object.entries(paymentConfirmation).forEach(ctrl => {
            let name = ctrl[0];
            let value = ctrl[1];

            if (value) {
                data.push({
                    field: name,
                    value: value.value ? parseInt(value.value) : value
                });
            }
        });

        getApplicationType().then(application => {
            fetch(`./Payment/PaymentFailed?applicationName=${application}`, {
                method: 'POST',
                headers: authHeader(false, true),
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success === true) {
                        history.push({
                            pathname: '/payment-failed', state: { invoiceNo: data.objData.sell2PaymentID }
                        });
                    } else {
                        history.push('/payment-failed');
                    }
                })
                .catch(err => {
                    console.log('error');
                });
        });
    }, [history, query])

    return (null)
}

export default MerchantAuthorisationSuccess;