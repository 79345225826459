import { combineReducers } from 'redux';
import controls from './controls.reducer';
import settings from './settings.reducer';
import tenders from './tenders.reducer';
import portals from './portals.reducer';
import packages from './packages.reducer';

export default combineReducers({
    controls,
    settings,
    tenders,
    portals,
    packages
});