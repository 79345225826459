import { getCookie, getVirtualDirectory } from "./general";

export function authHeader(formData = false, allowAnonymous = false) {
    if (allowAnonymous === false) {
        let token = getCookie(`token-${getVirtualDirectory()}`);
            if (token) {
                if (formData) {
                    return {
                        'Authorization': 'Bearer ' + JSON.parse(token)
                    };
                } else {
                    return {
                        'Authorization': 'Bearer ' + JSON.parse(token),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    };
                }
            } else {
                console.log("Auth Error")
                return {};
            }
    } else {
        return {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    }
}