import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { handleBackground } from '../../helpers/general';
import Footer from '../layout/Footer';
import NavMenu from '../layout/NavMenu';
import Login from './Login';

const PasswordResetSuccess = () => {
    useEffect(() => {
        handleBackground();
    }, []);

    return (
        <>
            <NavMenu />
            <div className="main-content u-container u-container--small container-fluid">
                <div className="u-flex-center u-component-spacing">
                    <h2 className="u-component-spacing u-branded-underline">Password Reset</h2>
                </div>

                <div className="row u-component-spacing">
                    <div className="col-md u-component-spacing--2x">
                        <div className="card main-content__inner" style={{ height: "calc(100% - 1rem)" }} >
                            <div className="card-body">
                                <div className="c-card card u-component-spacing" style={{ height: "calc(100% - 1rem)" }}>
                                    <div className="card-body">
                                        <h3 className="c-broken-underline u-component-spacing">Password Reset Successful</h3>
                                        <div className="c-form u-component-spacing--2x">

                                            <div className="c-form__input-group c-form__input-group--block-display">
                                                <label className='c-form__label' style={{ display: 'contents' }}>
                                                    <p>Your password has now been reset.</p>
                                                    <p>Please try accessing your account again using your new password.</p>
                                                </label>
                                            </div>
                                            <Login />

                                            <div className="u-flex-end u-component-spacing">
                                                <Link to='/home'>Return to home page</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default PasswordResetSuccess;