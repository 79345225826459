import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, FormFeedback, FormGroup, Modal, ModalBody, ModalFooter, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { authHeader } from '../../helpers/auth-header';
import {
    checkEmailIsValid, getApplicationType, getContactID, getRegex, getRegexValidationRules, handleConfirm, handleEmailCheck, handleRequiredCheck,
    handleRequiredCheckAlt, sendNewUserEmail, showCustomError
} from '../../helpers/general';
import Select2 from '../controls/Select2';
import Translation from '../controls/Translation';

const $ = require('jquery');

const NewSupplierUser = (props) => {
    const { id, supplierID, isOpen, toggle, cardClass, panelName, contactTable } = props;
    const [activeTab, setActiveTab] = useState('1');
    const [titles, setTitles] = useState([]);
    const [regex, setRegex] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [supplierUser, setSupplierUser] = useState({
        details: [{
            title: '',
            firstName: '',
            lastName: '',
            email: '',
            mobile: '',
            jobTitle: '',
            department: ''
        }], permissions: []
    });
    const [canChangePermissions, setCanChangePermissions] = useState(false);

    const toggleTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
        fetch(`./SupplierUsers/GetNameTitles`, {
            method: 'GET',
            headers: authHeader(false, true)
        })
            .then(response => response.json())
            .then(data => {
                let vTitles = [];

                vTitles.push({ label: "Select..", value: "-1" });

                data.map(obj => vTitles.push({ label: obj.title, value: obj.title }));
                setTitles(vTitles);
            })
            .catch(error => console.log('Error: ', error));

        fetch(`./Permissions/GetAllContactPermissions`, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setPermissions(data);
                }
            })
            .catch(error => console.log('Error: ', error));

        fetch(`./Permissions/CanUserChangePermissions?contactID=${getContactID()}`, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setCanChangePermissions(data);
                }
            })
            .catch(error => console.log('Error: ', error));

        getRegexValidationRules().then(vData => {
            setRegex(vData);
        });
    }, []);

    function handleChange(e) {
        e.persist();
        let val = e.target.value;

        var userDetails = supplierUser.details;
        userDetails = { ...userDetails, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : val };
        supplierUser.details = userDetails;
    }

    function handlePermissionsChange(e, id) {
        e.persist();

        var tmpPermissions = supplierUser.permissions;
        if (tmpPermissions !== null) {
            let index = tmpPermissions.indexOf(id);
            if (e.target.checked) {
                if (index === -1) {
                    tmpPermissions.push(id);
                }
            } else {
                if (index > -1) {
                    tmpPermissions.splice(index, 1);
                }
            }
        } else {
            tmpPermissions.push(id);
        }

        var userArray = supplierUser;
        userArray.permsisions = tmpPermissions;

        setSupplierUser(userArray);
    }

    const handleSave = () => {
        let bProceedRegistering = true;
        $('.is-invalid').each(function (o, e) {
            bProceedRegistering = false;
        });

        $("input[required]").each(function (i, obj) {
            if ($(this).val() === '' && !$(this).hasClass('is-invalid')) {
                let fieldCheck = false;

                if (this.type === 'email') {
                    checkEmailIsValid($(this).attr('id'), $(this).val(), 'VALIDATION_default').then(vData => fieldCheck = vData);
                } else {
                    fieldCheck = handleRequiredCheckAlt($(this).attr('id'), $(this).val(), 'VALIDATION_default');
                }

                bProceedRegistering = bProceedRegistering && fieldCheck;
            }
        });

        if (bProceedRegistering) {
            if (supplierID) {
                handleConfirm(
                    `<p>Would you like to create the account for</p><p><strong>${supplierUser.details.firstName} ${supplierUser.details.lastName}</strong>?</p>`,
                    function () {
                        createUser();
                    }
                );
            };
        } else {
            showCustomError('There is a problem with the information you are trying to save - please review any errors on screen and try again.', 'Cannot Save Details', 'error');
        }
    }

    const createUser = () => {
        getApplicationType()
            .then(application => {
                fetch(`./SupplierUsers/CreateNewSupplierUser?supplierID=${supplierID}&systemName=${application}&loggedInUser=${getContactID()}`, {
                    method: 'POST',
                    headers: authHeader(false, false),
                    body: JSON.stringify(supplierUser)
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.success === true) {
                            sendNewUserEmail(supplierUser.details.email)
                                .then(response => {
                                    if (response) {
                                        toggle();
                                        showCustomError(`<p>An email has been sent to <strong>${supplierUser.details.email}</strong> to reset the password.</p><p>Please follow instructions to reset the password to log in.</p>`, 'User Created', 'success', 600);

                                        let tableContacts = $(`#${contactTable}Table`).DataTable();
                                        if (tableContacts) {
                                            tableContacts.ajax.reload();
                                        }
                                    }
                                    else {
                                        showCustomError('There has been a problem sending the email - if the account has been created, please ask the user to use FORGOTTEN PASSWORD on the homepage', 'Problem with the email address', 'Error')
                                    }
                                });
                        } else {
                            console.log('Error');
                            showCustomError('You do not have permission to create new users', 'No Permission', 'Error')
                        }
                    })
                    .catch(err => {
                        console.log('Error::', err);
                    });
            });
    }

    const handleEmailOnBlur = (e) => {
        handleEmailCheck(e.target.id, e.target.value, 'VALIDATION_MandatoryEmail', 'VALIDATION_EmailComplexity', 'SupplierUsers', 'Email', 'VALIDATION_PersonalEmailExists');
    }

    return (
        <Modal
            id={id}
            isOpen={isOpen}
            toggle={toggle}
            size="xl"
            centered
            backdrop="static"
            unmountOnClose
        >
            <ModalBody>
                <Card className={cardClass} >
                    <CardHeader className="ic-users">
                        <span>
                            <Translation ID={panelName} />
                        </span>
                    </CardHeader>
                    <CardBody className='padding-5-1 card-module'>
                        <h3 className="c-broken-underline u-component-spacing">
                            Create a new user
                        </h3>
                        <div className='u-component-spacing--2x'>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '1' })}
                                        id='tab-0'
                                        data-toggle='tab'
                                        aria-controls='panel-0'
                                        aria-label='details'
                                        onClick={() => { toggleTab('1'); }}
                                    >
                                        <Translation ID='Details' />
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '2' })}
                                        id='tab-1'
                                        data-toggle='tab'
                                        aria-controls='panel-1'
                                        aria-label='contact permissions'
                                        onClick={() => { toggleTab('2'); }}
                                    >
                                        Permissions
                                    </NavLink>
                                </NavItem>
                            </Nav>

                            <TabContent activeTab={activeTab}>
                                <TabPane id='1' tabId='1'>
                                    <div className='tab-pane__inner'>
                                        <div className='c-form'>
                                            {titles && Object.keys(titles).length !== 0 && Object.keys(supplierUser).length !== 0 &&
                                                <div className='c-form__input-group'>
                                                    <label className='c-form__label' htmlFor='title'>
                                                        <Translation ID='Title' />
                                                    </label>
                                                    <Select2
                                                        id={'title'}
                                                        options={titles}
                                                        defaultValue={supplierUser.details.title}
                                                        change={(e) => handleChange(e)}
                                                    />
                                                </div>
                                            }
                                            <div className='c-form__input-group'>
                                                <Translation ID='FirstName' htmlFor='firstName' required={true} />
                                                <FormGroup>
                                                    <input name="firstName" id="firstName" type="text" className='c-form__input' required
                                                        onChange={(e) => handleChange(e)}
                                                        onBlur={(e) => handleRequiredCheck(e, 'VALIDATION_MandatoryFirstName')}
                                                    />
                                                    <FormFeedback>
                                                        <span id="firstName_LABEL"></span>
                                                    </FormFeedback>
                                                </FormGroup>
                                            </div>

                                            <div className='c-form__input-group'>
                                                <Translation ID='LastName' htmlFor='lastName' required={true} />
                                                <FormGroup>
                                                    <input name="lastName" id="lastName" type="text" className='c-form__input' required
                                                        onChange={(e) => handleChange(e)}
                                                        onBlur={(e) => handleRequiredCheck(e, 'VALIDATION_MandatoryLastName')}
                                                    />
                                                    <FormFeedback>
                                                        <span id="lastName_LABEL"></span>
                                                    </FormFeedback>
                                                </FormGroup>
                                            </div>

                                            <div className='c-form__input-group'>
                                                <Translation ID="EmailAddress" required={true} htmlFor='email' />
                                                <FormGroup>
                                                    <input name="email" type="email" defaultValue='' id="email" className='c-form__input form-control' required
                                                        pattern={getRegex(regex, 'EmailComplexity')}
                                                        onChange={(e) => { handleChange(e); checkEmailIsValid(e.target.id, e.target.value, 'VALIDATION_EmailComplexity') }}
                                                        onBlur={(e) => handleEmailOnBlur(e)} />
                                                    <FormFeedback>
                                                        <span id="email_LABEL"></span>
                                                    </FormFeedback>
                                                </FormGroup>
                                            </div>

                                            <div className='c-form__input-group'>
                                                <label className='c-form__label' htmlFor='jobTitle'>
                                                    <Translation ID='JobTitle' />
                                                </label>
                                                <input name='jobTitle' id='jobTitle' type='text' className='c-form__input' onChange={(e) => handleChange(e)} />
                                            </div>

                                            <div className='c-form__input-group'>
                                                <label className='c-form__label' htmlFor='department'>
                                                    <Translation ID='Department' />
                                                </label>
                                                <input name='department' id='department' type='text' className='c-form__input' onChange={(e) => handleChange(e)} />
                                            </div>
                                            <div className='c-form__input-group'>
                                                <Translation ID='TelephoneNumber' htmlFor='telephone' />
                                                <input name='telephone' id='telephone' type='text' className='c-form__input' onChange={(e) => handleChange(e)} />
                                            </div>

                                            <div className='c-form__input-group'>
                                                <Translation ID='MobileNumber' htmlFor='mobile' />
                                                <input name='mobile' id='mobile' type='text' className='c-form__input' onChange={(e) => handleChange(e)} />
                                            </div>
                                        </div>
                                    </div>
                                </TabPane>

                                <TabPane id='2' tabId='2'>
                                    <div className='tab-pane__inner'>
                                        <div className='c-form'>
                                            {Object.keys(permissions).length > 0 &&
                                                <>
                                                    {permissions.map((permission, i) => {
                                                        if (permission.permissionID !== 1) {
                                                            return (
                                                                <div className='c-form__input-group' key={i}>
                                                                    <input name={`permission${permission.permissionID}`} disabled={!canChangePermissions}
                                                                        type='checkbox'
                                                                        id={`permission${permission.permissionID}`}
                                                                        className='c-form__checkbox'
                                                                        onChange={(e) => handlePermissionsChange(e, permission.permissionID)}
                                                                    />
                                                                    <label htmlFor={`permission${permission.permissionID}`} className='c-form__label' >{permission.description}</label>
                                                                </div>
                                                            );
                                                        } else {
                                                            return null;
                                                        }
                                                    })}
                                                </>
                                            }
                                        </div>
                                    </div>
                                </TabPane>
                            </TabContent>
                        </div>
                    </CardBody>
                </Card>
            </ModalBody>
            <ModalFooter>
                <div className="p-2 text-right col-6">
                    <button id="addNewUser" size="sm" className='c-form__submit' onClick={() => handleSave()}>Add New User</button>
                    <Button id="close" color="danger" size="sm" onClick={toggle}>{'Close'}</Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default NewSupplierUser;