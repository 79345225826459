import React from 'react';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import { NavLink as Link } from 'react-router-dom';

const InfoStrap = () => {
    return (
        <div className="footer-content row u-container">
            <div className="col-md-5 u-flex-center" style={{ justifyContent: "flex-start" }} >
                <ul className="c-column-display">
                    <li><a href="https://www.in-tend.co.uk" target="_blank" rel="noreferrer">Powered by <span className="u-no-wrap">In-tend</span> Ltd</a></li>
                    <li><Link to="./Register">Register</Link></li>
                    <li><a href="https://in-tend.co.uk/contact-us/" target="_blank" rel="noreferrer">Contact Us</a></li>
                    <li><a href="https://www.in-tend.co.uk/legal/" target="_blank" rel="noreferrer">Legal Notice</a></li>
                </ul>
            </div>
            <div className="col">
                <p>
                    <svg xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" clipRule="evenodd" viewBox="0 0 300 300" width="60">
                        <path fill="#fff" fillRule="nonzero" d="m138.244 46.18 4.491-13.819-11.756-8.541h14.531L150 10l4.49 13.82h14.531l-11.756 8.541 4.491 13.819L150 37.639l-11.756 8.541ZM190.979 39.897h14.531l4.49-13.82 4.49 13.82h14.531l-11.756 8.541 4.491 13.819L210 53.716l-11.756 8.541 4.491-13.819-11.756-8.541ZM261.188 92.361l4.491 13.819-11.756-8.541-11.756 8.541 4.491-13.819-11.756-8.541h14.531l4.49-13.82 4.49 13.82h14.531l-11.756 8.541ZM274.49 143.82h14.531l-11.756 8.541 4.491 13.819L270 157.639l-11.756 8.541 4.491-13.819-11.756-8.541h14.531L270 130l4.49 13.82ZM242.167 226.18l4.491-13.819-11.756-8.541h14.531l4.49-13.82 4.49 13.82h14.531l-11.756 8.541 4.491 13.819-11.756-8.541-11.756 8.541ZM154.49 263.82h14.531l-11.756 8.541 4.491 13.819L150 277.639l-11.756 8.541 4.491-13.819-11.756-8.541h14.531L150 250l4.49 13.82ZM214.49 247.743h14.531l-11.756 8.541 4.491 13.819L210 261.562l-11.756 8.541 4.491-13.819-11.756-8.541h14.531l4.49-13.82 4.49 13.82ZM34.49 143.82h14.531l-11.756 8.541 4.491 13.819L30 157.639l-11.756 8.541 4.491-13.819-11.756-8.541H25.51L30 130l4.49 13.82ZM50.567 203.82h14.531l-11.756 8.541 4.491 13.819-11.756-8.541-11.756 8.541 4.491-13.819-11.756-8.541h14.531l4.49-13.82 4.49 13.82ZM94.49 247.743h14.531l-11.756 8.541 4.491 13.819L90 261.562l-11.756 8.541 4.491-13.819-11.756-8.541H85.51l4.49-13.82 4.49 13.82ZM50.567 83.82h14.531l-11.756 8.541 4.491 13.819-11.756-8.541-11.756 8.541 4.491-13.819-11.756-8.541h14.531L46.077 70l4.49 13.82ZM70.979 39.897H85.51L90 26.077l4.49 13.82h14.531l-11.756 8.541 4.491 13.819L90 53.716l-11.756 8.541 4.491-13.819-11.756-8.541Z" />
                    </svg>
                    Approved European Commission Licensee since 2006
                </p>
                <p>
                    <svg xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" clipRule="evenodd" viewBox="0 0 153 78" width="60">
                        <path fill="#fff" fillRule="nonzero" d="M99.61 38.13a19.4 19.4 0 0 0-11.1-8.57l13.93-24V.96H65.43L51.78 47.35 38.13.95h-9.9l4.53 15.16-9.23 31.24L9.89.95H0l22.6 76.24h.94l14.12-47.24 14.12 47.24h.94l19.34-65.27.9-1.54h15.93l-13.2 22.77v4.51h3.78c4.84 0 8.37 1.48 10.64 4.43 1.94 2.5 2.91 6.05 2.91 10.63 0 4.14-.9 7.69-2.73 10.63-1.81 2.96-4.04 4.43-6.68 4.43a9.5 9.5 0 0 1-6.55-2.5c-2-1.89-3.53-4.22-4.47-6.81l-7.72 3.2c1.57 4.76 3.99 8.54 7.25 11.33a17.15 17.15 0 0 0 11.49 4.19c5.83 0 10.58-2.35 14.25-7.06 3.68-4.7 5.52-10.5 5.52-17.41 0-5.58-1.26-10.45-3.77-14.58v-.01ZM141.68.53l1.6 9.73-5.66 10.83s-2.18-4.6-5.78-7.15c-3.05-2.14-5.04-2.6-8.13-1.97-4 .82-8.51 5.58-10.48 11.46-2.36 7.02-2.38 10.42-2.46 13.55-.14 5 .65 7.96.65 7.96s-3.44-6.36-3.4-15.68c.02-6.65 1.06-12.69 4.14-18.64 2.7-5.23 6.74-8.38 10.31-8.75 3.7-.38 6.62 1.4 8.88 3.33 2.36 2.02 4.75 6.44 4.75 6.44L141.7.52l-.02.01Zm.7 55.06s-2.51 4.47-4.07 6.2c-1.56 1.72-4.35 4.76-7.8 6.28-3.45 1.52-5.25 1.8-8.67 1.48-3.4-.33-6.57-2.3-7.68-3.12a30.08 30.08 0 0 1-5.54-5.5c-1.6-2.27-4.1-6.78-4.1-6.78s1.39 4.53 2.27 6.45c.5 1.1 2.04 4.48 4.23 7.42 2.04 2.76 6.02 7.48 12.06 8.55 6.04 1.06 10.18-1.65 11.2-2.3a30.97 30.97 0 0 0 4.57-3.94c1.3-1.45 2.48-3 3.53-4.64.55-.86 1.44-2.6 1.44-2.6l-1.44-7.5Z" />
                        <path fill="#fff" d="M149.13 1.81c.65 0 .95.19.95.64 0 .44-.3.6-.94.6h-.59V1.81h.58Zm.12-.45h-1.4v3.72h.7V3.49h.7l.75 1.59h.78l-.83-1.7c.54-.1.85-.47.85-1 0-.68-.5-1.02-1.55-1.02Zm2.76 1.95a2.88 2.88 0 0 1-2.87 2.82 2.8 2.8 0 0 1-1.98-.85 2.84 2.84 0 0 1-.84-2 2.89 2.89 0 0 1 2.85-2.85h.02a2.83 2.83 0 0 1 2.82 2.82v.06Zm-2.85-3.3c-.88 0-1.67.3-2.27.92a3.23 3.23 0 0 0-.04 4.65c.6.63 1.44.98 2.31.98a3.28 3.28 0 0 0 0-6.56v.01Z" />
                    </svg>
                    We comply with the Web Accesibility Initiative’s Web Content Accessibility Guidelines (WAI/WCAG)
                </p>
                <p>
                    <svg xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" clipRule="evenodd" viewBox="0 0 259 247" width="60">
                        <path fill="#fff" d="M129.49 237.07c-4.47.18-9.02.28-13.64.28-44.16 0-82.47-8.78-101.5-21.32C4.93 209.82 0 202.48 0 195.01V42.16c.08-7.41 5-14.68 14.35-20.84C33.38 8.78 71.69 0 115.85 0s82.47 8.78 101.5 21.32c9.42 6.21 14.35 13.54 14.35 21.01v49.1a50.82 50.82 0 0 0-9.52-6.26 10.17 10.17 0 0 0 1.18-4.67V58.69a47.76 47.76 0 0 1-6.01 4.66c-19.03 12.54-57.34 21.32-101.5 21.32s-82.47-8.78-101.5-21.32a47.76 47.76 0 0 1-6.01-4.66V80.5c0 3.23 1.68 6.25 4.51 9.15 3.41 3.5 8.4 6.7 14.64 9.61 19.93 9.29 52.11 15.25 88.36 15.25 12.51 0 24.54-.71 35.75-2.02a49.66 49.66 0 0 0-2.44 8.63 316.44 316.44 0 0 1-33.31 1.72c-44.16 0-82.47-8.78-101.5-21.32a46.88 46.88 0 0 1-6.01-4.67v21.82c0 3.23 1.68 6.25 4.51 9.15 3.41 3.5 8.4 6.7 14.64 9.61 19.93 9.29 52.11 15.25 88.36 15.25 4.62 0 9.17-.1 13.64-.29v8.34c-4.47.18-9.02.28-13.64.28-44.16 0-82.47-8.78-101.5-21.32a46.88 46.88 0 0 1-6.01-4.67v21.82c0 3.23 1.68 6.25 4.51 9.15 3.41 3.49 8.4 6.7 14.64 9.61 19.93 9.29 52.11 15.24 88.36 15.24 4.62 0 9.17-.09 13.64-.28v8.34c-4.47.18-9.02.28-13.64.28-44.16 0-82.47-8.78-101.5-21.32a46.88 46.88 0 0 1-6.01-4.67v21.86c.01 3.21 1.69 6.22 4.51 9.11 3.41 3.49 8.4 6.7 14.64 9.61 19.93 9.29 52.11 15.24 88.36 15.24 4.62 0 9.17-.09 13.64-.28v8.34ZM115.85 8.33c36.25 0 68.43 5.95 88.36 15.24 6.24 2.91 11.23 6.12 14.64 9.61 2.83 2.9 4.51 5.93 4.51 9.15 0 3.23-1.68 6.25-4.51 9.15-3.41 3.5-8.4 6.7-14.64 9.61-19.93 9.29-52.11 15.25-88.36 15.25s-68.43-5.96-88.36-15.25c-6.24-2.91-11.23-6.11-14.64-9.61-2.83-2.9-4.51-5.92-4.51-9.15 0-3.22 1.68-6.25 4.51-9.15 3.41-3.49 8.4-6.7 14.64-9.61C47.42 14.28 79.6 8.33 115.85 8.33Z" />
                        <path fill="#fff" d="M158.68 146.79v-16.31a40.52 40.52 0 0 1 40.51-40.38 40.52 40.52 0 0 1 40.5 40.51v16.18h14.62c2.3 0 4.17 1.86 4.17 4.16v91.43c0 2.3-1.87 4.17-4.17 4.17H144.06a4.17 4.17 0 0 1-4.16-4.17v-91.43c0-2.3 1.86-4.16 4.16-4.16h14.62Zm91.46 8.33v83.09H148.23v-83.09h101.91Zm-18.78-8.33h-64.35v-16.28a32.19 32.19 0 0 1 64.35.1v16.18Z" />
                        <path fill="#fff" d="M195.02 203.31v18.52a4.17 4.17 0 0 0 8.33 0v-18.52A19.76 19.76 0 1 0 179.44 184c0 9.47 6.68 17.39 15.58 19.31Zm4.17-30.73a11.42 11.42 0 1 1-.02 22.84 11.42 11.42 0 0 1 .02-22.84Z" />
                    </svg>
                    Encrypted transactions and data at rest
                </p>
            </div>
        </div>
    );
}

export default InfoStrap;