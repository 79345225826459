import { TENDER_FAILURE, TENDER_REQUEST, TENDER_SUCCESS } from "../actions/tenders.actions"

const initialState = {
    isLoading: true,
    tenders: []
}

export default function tenders(state = initialState, action) {
    switch (action.type) {
        case TENDER_REQUEST:
            return Object.assign({}, state, {
                isLoading: true
            })
        case TENDER_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                tenders: action.tenders
            })
        case TENDER_FAILURE:
            return Object.assign({}, state)
        default:
            return state
    }
}