import React from 'react';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
import { useHistory } from 'react-router-dom';
import { CardHeader } from 'reactstrap';

require('bootstrap-datepicker');

const BackToOrganisation = (props) => {
    let { prevLocation } = props;
    const history = useHistory();

    return (
        <CardHeader className="card-header card-header--no-icon">
            <div>
                <button
                    className="c-back-button"
                    onClick={
                        () => history.push(prevLocation)
                    }
                >
                    {'Back to Organisation'}
                </button>
            </div>
        </CardHeader>
    )
}

export default BackToOrganisation;