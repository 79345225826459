import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getContactID, getSupplierID, handleTenderStatus, logIntoPortal, updateTenderStatus } from '../../helpers/general';
import ScrollArrow from '../controls/ScrollArrow';
import Translation from '../controls/Translation';

const moment = require('moment');

const TenderList = props => {
    let { tenders = {}, searchParams = [], loggedIn = false, showOrganisationTenders = false, hideFeed = false } = props;

    const [tenderURL, setTenderURL] = useState('');


    useEffect(() => {
        if (loggedIn) {
            setTenderURL('/tender-details');
        } else {
            setTenderURL('/publictender-details');
        }
    }, [loggedIn])

    // Create our number formatter.
    let formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const handleGoToPortal = (registered, url, tenderid, portalID, portalName) => {
        if (loggedIn) {
            if (tenderid > 0) {
                updateTenderStatus(1, getSupplierID(), tenderid, false);
            }
            logIntoPortal(registered, url, getSupplierID(), getContactID(), tenderid, portalID, portalName);
        }
    }

    return (
        <>
            <div data-viewmode="list" className="c-results js-results-list">
                {tenders.tenders && Object.keys(tenders.tenders).length === 0 &&
                    <div style={{ padding: "1rem 1rem 2rem" }}>
                        <strong style={{ textAlign: "center", display: "block", fontSize: "1.4rem" }}>
                            {showOrganisationTenders && hideFeed === false &&
                                <>
                                    <p>There are no active opportunities for this organisation</p>
                                </>
                            }

                            {!showOrganisationTenders && hideFeed === false &&
                                <>
                                    <p>There are no tenders that match your search criteria.</p>
                                    <p>Please try again.</p>
                                </>
                            }
                            {hideFeed === true &&
                                <>
                                    <p>Organisations feed is unavailable</p>
                                </>
                            }
                        </strong>
                    </div>
                }
                {tenders.tenders && Object.keys(tenders.tenders).length > 0 &&                
                <div className="c-view-changer">
                    <p>Display mode:</p>
                        <button className="c-view-changer__button js-view-changer" data-view-mode="list">
                        <svg className="c-view-changer__button-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="14" height="14"><path className="c-view-changer__button-icon-path" d="M18 7.999a.998.998 0 0 0-.998-.998H.998A.998.998 0 0 0 0 7.999v2.002c0 .552.447.998.998.998h16.004a.998.998 0 0 0 .998-.998V7.999ZM18 .998A.998.998 0 0 0 17.002 0H.998A.998.998 0 0 0 0 .998V3c0 .552.447.998.998.998h16.004A.998.998 0 0 0 18 3V.998ZM18 15a.998.998 0 0 0-.998-.998H.998A.998.998 0 0 0 0 15v2.002c0 .552.447.998.998.998h16.004a.998.998 0 0 0 .998-.998V15Z" /></svg>
                        <span className="c-view-changer__button-text">List</span>
                    </button>
                    <button className="c-view-changer__button js-view-changer" data-view-mode="grid">
                        <svg className="c-view-changer__button-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="14" height="14"><path className="c-view-changer__button-icon-path" d="M9.435 1.794C9.435.804 8.63 0 7.64 0H1.794C.804 0 0 .804 0 1.794V7.64c0 .991.804 1.795 1.794 1.795H7.64c.991 0 1.795-.804 1.795-1.795V1.794ZM22 1.794C22 .804 21.197 0 20.206 0h-5.847c-.99 0-1.794.804-1.794 1.794V7.64c0 .991.804 1.795 1.794 1.795h5.847c.99 0 1.794-.804 1.794-1.795V1.794ZM9.435 14.36c0-.991-.804-1.795-1.795-1.795H1.794C.804 12.565 0 13.37 0 14.36v5.847C0 21.196.804 22 1.794 22H7.64c.991 0 1.795-.803 1.795-1.794v-5.847ZM22 14.36c0-.991-.803-1.795-1.794-1.795h-5.847c-.99 0-1.794.804-1.794 1.794v5.847c0 .99.804 1.794 1.794 1.794h5.847c.99 0 1.794-.803 1.794-1.794v-5.847Z" /></svg>
                        <span className="c-view-changer__button-text">Grid</span>
                    </button>
                </div>
                }
                {tenders.tenders && Object.keys(tenders.tenders).length > 0 &&
                    tenders.tenders.map((collection, i) => {
                        return (
                            <section className="c-results-list" key={i}>
                                <h2 className="c-results-list__heading">{moment(collection.month, 'MM').format('MMMM')} {collection.year}</h2>
                                <ol className="c-results-list__list u-component-spacing">
                                    {
                                        collection.tenders.map((tender, j) => {
                                            return (
                                                <li className="c-results-list__list-item" key={j}>
                                                    <div className="c-result">
                                                        <Link to={{ pathname: `${tenderURL}`, search: `?tid=${tender.tenderID}`, state: searchParams }} className="c-result__content">
                                                            <div className="c-result__content">
                                                                <h3 className="c-result__heading" dangerouslySetInnerHTML={{ __html: tender.title }} />
                                                                <p className="c-result__sub-heading" dangerouslySetInnerHTML={{ __html: tender.entityName }} />

                                                                {tender.inTendFeed === true && <p className="c-result__tender-source c-result__tender-source--in-tend" style={{ marginRight: "1rem" }} >In-tend</p>}
                                                                {tender.ojeuFeed === true && <p className="c-result__tender-source c-result__tender-source--eu" style={{ marginRight: "1rem" }}>OJEU</p>}
                                                                {tender.contractsFinder === true && <p className="c-result__tender-source c-result__tender-source--uk--cf" style={{ marginRight: "1rem" }}>UK Contracts Finder</p>}
                                                                {tender.fts === true && <p className="c-result__tender-source c-result__tender-source--uk--fts" style={{ marginRight: "1rem" }}>UK Find a Tender</p>}

                                                                <dl className="c-result__summary u-component-spacing">
                                                                    <div className="c-result__summary-item c-result__summary-item--published">
                                                                        <dt className="c-result__summary-term">Published:</dt>
                                                                        <dd className="c-result__summary-definition">{
                                                                            moment(tender.dateAdded).isValid()
                                                                                ? (moment(tender.dateAdded).format('DD/MM/YYYY') === '01/01/1899' ? 'Not Provided' : moment(tender.dateAdded).format('DD/MM/YYYY'))
                                                                                : ''}
                                                                        </dd>
                                                                    </div>
                                                                    <div className="c-result__summary-item c-result__summary-item--deadline">
                                                                        <dt className="c-result__summary-term">Deadline:</dt>
                                                                        <dd className="c-result__summary-definition">{
                                                                            tender.displayEOIDate
                                                                                ? (moment(tender.docsAvailableUntil).isValid()
                                                                                    ? (moment(tender.docsAvailableUntil).format('DD/MM/YYYY') === '01/01/1899' ? 'No Deadline Provided' : moment(tender.docsAvailableUntil).format('DD/MM/YYYY'))
                                                                                    : '')
                                                                                : "Deadline Passed"}
                                                                        </dd>
                                                                    </div>
                                                                    <div className="c-result__summary-item c-result__summary-item--location">
                                                                        <dt className="c-result__summary-term">Location:</dt>
                                                                        <dd className="c-result__summary-definition">{tender.country ? tender.country : 'Not Specified'}</dd>
                                                                    </div>
                                                                    <div className="c-result__summary-item c-result__summary-item--value">
                                                                        <dt className="c-result__summary-term">Value:</dt>
                                                                        <dd className="c-result__summary-definition">
                                                                            {
                                                                                tender.valueTo > 0 ?
                                                                                    `${formatter.format(tender.valueFrom)} to ${formatter.format(tender.valueTo)}`
                                                                                    : 'Not Specified'
                                                                            }
                                                                        </dd>
                                                                    </div>
                                                                </dl>
                                                                {tender.expressedInterest &&
                                                                    <strong className="c-result__in-progress u-component-spacing"><span className="pulsing-ring"></span>in progress</strong>
                                                                }
                                                            </div>
                                                        </Link>

                                                        <div className="c-result__footer">
                                                            <Link to={{ pathname: `${tenderURL}`, search: `?tid=${tender.tenderID}`, state: searchParams }} className="c-result__prompt">
                                                                Find out more
                                                            </Link>
                                                            {loggedIn &&
                                                                <div className="c-tender-actions">
                                                                    {tender.inTendFeed &&
                                                                        <>
                                                                            {(tender.displayEOIDate || tender.expressedInterest) && tender.registeredWithPortal &&
                                                                                <button className="c-tender-actions__action-button c-tender-actions__action-button--100 c-tender-actions__action-button--express-interest" data-action-enabled={false} onClick={(e) => handleGoToPortal(tender.registeredWithPortal, '', tender.tenderID, tender.organiserID, tender.entityName)}>
                                                                                    View in Portal
                                                                                </button>
                                                                            }
                                                                            {!tender.registeredWithPortal &&
                                                                                <span><Translation ID='NotRegisteredWithOrgansation' /></span>
                                                                            }
                                                                        </>
                                                                    }
                                                                    {!tender.expressedInterest &&
                                                                        <div className="c-tender-actions">
                                                                            <div style={{ flexBasis: "60.5%" }} >
                                                                                <button id={`watch_${tender.tenderID}`} className="c-tender-actions__action-button c-tender-actions__action-button--watch js-action-button" data-action-enabled={tender.watching} onClick={(e) => handleTenderStatus(e, 2, tender.supplierID, tender.tenderID)}>
                                                                                    {tender.watching === true ? 'Stop Watching' : "Watch"}
                                                                                </button>
                                                                            </div>
                                                                            <div style={{ flexBasis: "39.5%" }} >
                                                                                <button id={`hide_${tender.tenderID}`} className="c-tender-actions__action-button c-tender-actions__action-button--hide-tender js-action-button" data-action-enabled={tender.hide} onClick={(e) => handleTenderStatus(e, 4, tender.supplierID, tender.tenderID)}>
                                                                                    {tender.hide === true ? 'Unhide' : "Hide"}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ol>
                            </section>
                        )
                    })
                }
            </div>
            <ScrollArrow />
        </>
    );
}

export default TenderList;
