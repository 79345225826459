import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';

const NavMenuPublic = (props) => {
    let { navMenu } = props;

    return (
        <Nav className="mr-auto nav" navbar>
            {navMenu.map((navItem, i) => {
                return (
                    <NavItem key={i}>
                        <NavLink tag={Link} activeClassName="active" className={navItem.class} to={navItem.routing}>{navItem.title}</NavLink>
                    </NavItem>)
            })}
        </Nav>
    );
}

export default NavMenuPublic;