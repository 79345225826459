import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import useMatamo from './hooks/useMatamo';
import ForgottenPassword from './components/Auth/ForgottenPassword';
import Logout from './components/Auth/Logout';
import PasswordReset from './components/Auth/PasswordReset';
import PasswordResetFailure from './components/Auth/PasswordResetFailure';
import PasswordResetSuccess from './components/Auth/PasswordResetSuccess';
import ContractDetails from './components/Contracts/ContractDetails';
import error404 from './components/Errors/error404';
import Home from './components/Home';
import { Layout, LayoutRoute, Loader } from './components/layout';
import Contracts from './components/pages/Contracts';
import Dashboard from './components/pages/Dashboard';
import EmailVerification from './components/pages/EmailVerification';
import Packages from './components/pages/Packages';
import Organisations from './components/pages/PortalList';
import Organisation from './components/pages/PortalProfile';
import Products from './components/pages/Products';
import PublicTenders from './components/pages/PublicTenders';
import Register from './components/pages/Register';
import TenderDetails from './components/pages/TenderDetails';
import Tenders from './components/pages/Tenders';
import Basket from './components/Payment/Basket';
import MerchantAuthorisation from './components/Payment/MerchantAuthorisation';
import MerchantAuthorisationFailed from './components/Payment/MerchantAuthorisationFailed';
import MerchantAuthorisationSuccess from './components/Payment/MerchantAuthorisationSuccess';
import PaymentError from './components/Payment/PaymentError';
import PaymentFailed from './components/Payment/PaymentFailed';
import PaymentSuccessful from './components/Payment/PaymentSuccessful';
import Upgrade from './components/Payment/Upgrade';
import Invoices from './components/Suppliers/Invoices';
import CompanyProfile from './components/Suppliers/SupplierProfile';
import User from './components/Suppliers/SupplierUser';

function App() {
    useMatamo();

    return (
        <Suspense fallback={<Loader Visible />}>
            <Switch>
                <Redirect exact path='/' to={{ pathname: '/home' }} />
                <Route exact path='/home' component={Home} />
                <Route exact path='/merchant-authorisation' component={MerchantAuthorisation} />
                <Route exact path='/merchant-authorisation-success' component={MerchantAuthorisationSuccess} />
                <Route exact path='/merchant-authorisation-failed' component={MerchantAuthorisationFailed} />
                <Route exact path='/logout' component={Logout} />
                <Route exact path='/priceplans' component={Packages} />
                <Route exact path='/register' component={Register} />
                <Route exact path='/verification' component={EmailVerification} />
                <Route exact path='/forgotten-password' component={ForgottenPassword} />
                <Route exact path='/password-reset' component={PasswordReset} />
                <Route exact path='/password-failure' component={PasswordResetFailure} />
                <Route exact path='/password-success' component={PasswordResetSuccess} />
                <Route exact path='/contracts' component={Contracts} />
                <Route exact path='/contract' component={ContractDetails} />
                <Route exact path='/publictenders' component={PublicTenders} />
                <Route exact path='/publictender-details' component={TenderDetails} />
                <Route exact path='/payment-successful' component={PaymentSuccessful} />
                <Route exact path='/payment-error' component={PaymentError} />
                <Route exact path='/payment-failed' component={PaymentFailed} />
                <Route exact path='/404' component={error404} />

                <Switch>
                    <LayoutRoute exact path='/dashboard' layout={Layout} component={Dashboard} />
                    <LayoutRoute exact path='/overview' layout={Layout} component={Dashboard} /> {/*This is for the redirect for training due to url length - IntendMaster DB has to have its Portal table extended from 50 characters*/}
                    <LayoutRoute exact path='/user' layout={Layout} component={User} />
                    <LayoutRoute exact path='/tenders' layout={Layout} component={Tenders} />
                    <LayoutRoute exact path='/tender-details' layout={Layout} component={TenderDetails} />
                    <LayoutRoute exact path='/organisations' layout={Layout} component={Organisations} />
                    <LayoutRoute exact path='/organisation' layout={Layout} component={Organisation} />
                    <LayoutRoute exact path='/my-company' layout={Layout} component={CompanyProfile} />
                    <LayoutRoute exact path='/products' layout={Layout} component={Products} />
                    <LayoutRoute exact path='/invoices' layout={Layout} component={Invoices} />
                    <LayoutRoute exact path='/basket' layout={Layout} component={Basket} />
                    <LayoutRoute exact path='/upgrade' layout={Layout} component={Upgrade} />
                </Switch>

                <Route path='*' to={{ pathname: '/404' }} />
            </Switch>
        </Suspense>
    );

}

export default App;