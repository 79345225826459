import React, { useEffect, useState } from 'react';
import { FormGroup, FormFeedback, Row, Col } from "reactstrap";
import Select2 from '../controls/Select2';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import { handleRequiredCheck, checkEmailIsValid, handleRequiredCheckAlt, StartOgoneProcess, handleBackground, getResourceString } from '../../helpers/general';
import Translation from '../controls/Translation';

const $ = require('jquery');

const PaymentDetails = props => {
    let { countries, address1, address2, city, country, postcode, companyEmail, priceExcVat, registrationID } = props;

    const [paymentDetails, setPaymentDetails] = useState({
        billAddrCity: city,
        billAddrCountry: country,
        billAddrLine1: address1,
        billAddrLine2: address2,
        billAddrPostCode: postcode,
        companyEmail: companyEmail,
        cardholderName: '',
        cardNumber: '',
        cardExpiryDateMM: '',
        cardExpiryDateYY: '',
        cardExpiryDate: '',
        cvc: '',
        browserColorDepth: window.screen.colorDepth,
        browserJavaEnabled: navigator.javaEnabled(),
        browserLanguage: navigator.language,
        browserScreenHeight: window.screen.height,
        browserScreenWidth: window.screen.width,
        browserTimeZone: new Date().getTimezoneOffset(),
        browserUserAgent: navigator.userAgent,
        priceExcVat: priceExcVat
    });

    useEffect(() => {
        handleBackground();
    }, []);

    function handleChange(e) {
        e.persist();
        setPaymentDetails(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }

    const handleSelect2Change = e => {
        e.persist();
        setPaymentDetails(prevState => ({
            ...prevState,
            [e.target.id]: e.target.value
        }));

        if (e.target.id === 'billAddrCountry') {
            var selectedValue = e.target.value === '-1' ? '' : e.target.value;
            handleRequiredCheckAlt(e.target.id, selectedValue, 'VALIDATION_BillAddrCountry');
        }
    }

    const handleMaxSizeLimit = (e, i) => {
        if (e.target.value.length > i) {
            e.target.value = e.target.value.substring(0, i);
            return false;
        }
        return true;
    }

    const handleBetweenSizeLimit = (e, min, max) => {
        // handle Min check
        if (e.target.value.length < min) {
            return false;
        }

        // Handle Max check
        if (e.target.value.length > max) {
            e.target.value = e.target.value.substring(0, max);
            return false;
        }
        return true;
    }

    const handleCardholderNameSize = (e, min, max) => {
        if (handleBetweenSizeLimit(e, min, max)) {
            handleChange(e);
            document.getElementById(e.target.id).classList.remove("is-invalid");
            return true;
        } else {
            document.getElementById(e.target.id).classList.add("is-invalid");
            getResourceString('VALIDATION_CardHolderMinMax').then(data => {
                document.getElementById(`${e.target.id}_LABEL`).innerText = data;
            })
            return false;
        }
    }

    const handleCardNumberValidation = e => {
        if (isNaN(e.target.value)) {
            e.target.value = e.target.value.substring(0, e.target.value.length - 1)
            return false;
        }

        if (handleMaxSizeLimit(e, 16)) {
            handleChange(e);
        }
    }

    const handleMonthValidation = e => {
        if (isNaN(e.target.value)) {
            e.target.value = e.target.value.substring(0, e.target.value.length - 1)
            return false;
        }

        handleMaxSizeLimit(e, 2)

        if (e.target.value > 0 && e.target.value < 13) {
            if (handleRequiredCheck(e)) {
                handleChange(e);
            }
        } else if (e.target.value > 12) {
            document.getElementById(`${e.target.id}`).value = 12;
        } else if (e.target.value < 0 || e.target.value === '00') {
            document.getElementById(`${e.target.id}`).value = '01';
        }
    }

    const handleYearValidation = e => {
        if (isNaN(e.target.value)) {
            e.target.value = e.target.value.substring(0, e.target.value.length - 1)
            return false;
        }

        if (e.target.value.length > 2) {
            e.target.value = e.target.value.substring(0, 2);
            return false;
        }
        else {
            if (handleRequiredCheck(e)) {
                handleChange(e);
            }
        }
    }

    const handleCVC = e => {
        if (isNaN(e.target.value)) {
            e.target.value = e.target.value.substring(0, e.target.value.length - 1)
            return false;
        }

        handleMaxSizeLimit(e, 3);
        handleChange(e);
    }

    const handleMakePayment = () => {
        let bProceedPayment = true;

        // Check if there are any in-valid style errors
        $('.is-invalid').each(function (o, e) {
            bProceedPayment = false;
        });

        // Check all required fields
        $("input[required]").each(function (i, obj) {
            if ($(this).val() === '' && !$(this).hasClass('is-invalid')) {
                let fieldCheck = false;

                if (this.type === 'email') {
                    checkEmailIsValid($(this).attr('id'), $(this).val(), 'VALIDATION_default').then(vData => fieldCheck = vData);
                } else {
                    fieldCheck = handleRequiredCheckAlt($(this).attr('id'), $(this).val(), 'VALIDATION_default');
                }

                bProceedPayment = bProceedPayment && fieldCheck;
            }
        });

        // If all mandatory fields have been filled in
        if (bProceedPayment) {
            let data = [];
            Object.entries(paymentDetails).forEach(ctrl => {
                let name = ctrl[0];
                let value = ctrl[1];

                if (value !== undefined && value !== null) {
                    data.push({
                        field: name,
                        value: value.value ? parseInt(value.value) : value
                    });
                }
            });

            data.push({ field: 'priceExcVat', value: priceExcVat });

            StartOgoneProcess(data, -1, -1, registrationID, 0);
        }
    }

    return (
        <>
            <div className="u-flex-center u-component-spacing" style={{ color: "rgb(255, 255, 255)" }} >
                <h2 className="u-component-spacing u-branded-underline u-inverted-text">Payment Details</h2>
            </div>
            <div className="card main-content__inner u-component-spacing--3x">
                <div className="card-body">
                    <div className="c-card card u-component-spacing">
                        <div className="card-body">
                            <div className="c-form u-component-spacing--2x">
                                <Row>
                                    <Col md={6}>
                                        <h2>Billing Address Details</h2>

                                        <div className='c-form__input-group u-component-spacing--2x'>
                                            <Translation ID="BillAddrLine1" required htmlFor='billAddrLine1' />
                                            <FormGroup className="position-relative">
                                                <input name="billAddrLine1" id="billAddrLine1" type="text" defaultValue={address1} className='c-form__input' required
                                                    onChange={(e) => handleChange(e)}
                                                    onBlur={(e) => handleRequiredCheck(e, 'VALIDATION_MandatoryBillAddrLine1')}
                                                />
                                                <FormFeedback>
                                                    <span id="billAddrLine1_LABEL"></span>
                                                </FormFeedback>
                                            </FormGroup>
                                        </div>

                                        <div className='c-form__input-group u-component-spacing'>
                                            <Translation ID="BillAddrLine2" htmlFor='billAddrLine2' />
                                            <FormGroup className="position-relative">
                                                <input name="billAddrLine2" id="billAddrLine2" type="text" defaultValue={address2} className='c-form__input'
                                                    onChange={(e) => handleChange(e)}
                                                />
                                                <FormFeedback>
                                                    <span id="billAddrLine2_LABEL"></span>
                                                </FormFeedback>
                                            </FormGroup>
                                        </div>

                                        <div className="c-form__input-group u-component-spacing">
                                            <Translation ID="BillAddrCity" required htmlFor='billAddrCity' />
                                            <FormGroup className="position-relative">
                                                <input name="billAddrCity" id="billAddrCity" type="text" defaultValue={city} className='c-form__input' required
                                                    onChange={(e) => handleChange(e)}
                                                    onBlur={(e) => handleRequiredCheck(e, 'VALIDATION_MandatoryBillAddrCity')}
                                                />
                                                <FormFeedback>
                                                    <span id="billAddrCity_LABEL"></span>
                                                </FormFeedback>
                                            </FormGroup>
                                        </div>

                                        {countries && Object.keys(countries).length !== 0 &&
                                            <div className="c-form__input-group u-component-spacing">
                                                <Translation ID="BillAddrCountry" required htmlFor='billAddrCountry' />
                                                <FormGroup className="position-relative">
                                                    <Select2
                                                        id='billAddrCountry'
                                                        options={countries}
                                                        defaultValue={country}
                                                        required
                                                        change={(e) => handleSelect2Change(e)}
                                                    />
                                                    <FormFeedback>
                                                        <span id="billAddrCountry_LABEL"></span>
                                                    </FormFeedback>
                                                </FormGroup>
                                            </div>
                                        }

                                        <div className="c-form__input-group u-component-spacing">
                                            <Translation ID="BillAddrPostCode" required htmlFor='billAddrPostCode' />
                                            <FormGroup className="position-relative">
                                                <input name="billAddrPostCode" id="billAddrPostCode" type="text" defaultValue={postcode} className='c-form__input' required
                                                    onChange={(e) => handleChange(e)}
                                                    onBlur={(e) => handleRequiredCheck(e, 'VALIDATION_MandatoryBillAddrPostCode')}
                                                />
                                                <FormFeedback>
                                                    <span id="billAddrPostCode_LABEL"></span>
                                                </FormFeedback>
                                            </FormGroup>
                                        </div>

                                        <div className="c-form__input-group u-component-spacing">
                                            <Translation ID="CompanyEmail" required htmlFor='email' />
                                            <FormGroup className="position-relative">
                                                <input name="email" id="email" type="text" defaultValue={companyEmail} className='c-form__input form-control' required
                                                    onChange={(e) => { handleChange(e); checkEmailIsValid(e.target.id, e.target.value, 'VALIDATION_EmailComplexity') }}
                                                    onBlur={(e) => checkEmailIsValid(e.target.id, e.target.value, 'VALIDATION_MandatoryEmail')} />
                                                <FormFeedback>
                                                    <span id="email_LABEL"></span>
                                                </FormFeedback>
                                            </FormGroup>
                                        </div></Col>
                                    <Col md={6}>
                                        <h2>Card Details</h2>
                                        <div className="c-form__input-group u-component-spacing">
                                            <Translation ID="CardholderName" htmlFor='cardholderName' />
                                            <FormGroup className="position-relative">
                                                <input name="cardholderName" id="cardholderName" type="text" defaultValue='' className='c-form__input' autoComplete="off" required
                                                    onChange={(e) => handleCardholderNameSize(e, 2, 45)}
                                                    onBlur={(e) => handleRequiredCheck(e, 'VALIDATION_MandatoryCardholderName')} />
                                                <FormFeedback>
                                                    <span id="cardholderName_LABEL"></span>
                                                </FormFeedback>
                                            </FormGroup>
                                        </div>

                                        <div className="c-form__input-group u-component-spacing">
                                            <Translation ID="CardNumber" htmlFor='cardNumber' />
                                            <FormGroup className="position-relative">
                                                <input name="cardNumber" id="cardNumber" type="tel" defaultValue='' className='c-form__input' required
                                                    onChange={(e) => handleCardNumberValidation(e)}
                                                    onBlur={(e) => handleRequiredCheck(e, 'VALIDATION_MandatoryCardNumber')}
                                                />
                                                <FormFeedback>
                                                    <span id="cardNumber_LABEL"></span>
                                                </FormFeedback>
                                            </FormGroup>
                                        </div>

                                        <div className="c-form__input-group u-component-spacing">
                                            <Translation ID="CardExpiryDate" htmlFor='cardExpiryDateMM' />
                                            <Row className="position-relative form-group">
                                                <Col md={6} style={{ padding: "0px" }}>
                                                    <FormGroup >
                                                        <label htmlFor="cardExpiryDateMM" className="c-form__label">MM</label>
                                                        <input name="cardExpiryDateMM" id="cardExpiryDateMM" type="tel" defaultValue='' className='c-form__input' style={{ width: "75%" }} required
                                                            onChange={(e) => handleMonthValidation(e)}
                                                            onBlur={(e) => handleRequiredCheck(e, 'VALIDATION_MandatoryCardExpiryDateMM')}
                                                        />
                                                        <FormFeedback>
                                                            <span id="cardExpiryDateMM_LABEL"></span>
                                                        </FormFeedback>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup >
                                                        <label htmlFor="cardExpiryDateYY" className="c-form__label">YY</label>
                                                        <input name="cardExpiryDateYY" id="cardExpiryDateYY" type="tel" defaultValue='' className='c-form__input' pattern="[0-9]{2}" style={{ width: "75%" }} required
                                                            onChange={(e) => handleYearValidation(e)}
                                                            onBlur={(e) => handleRequiredCheck(e, 'VALIDATION_MandatoryCardExpiryDateYY')}
                                                        />
                                                        <FormFeedback>
                                                            <span id="cardExpiryDateYY_LABEL"></span>
                                                        </FormFeedback>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="c-form__input-group u-component-spacing">
                                            <Translation ID="CardCVC" htmlFor='cvc' />
                                            <FormGroup className="position-relative">
                                                <input name="cvc" id="cvc" type="tel" defaultValue='' className='c-form__input' required
                                                    onChange={(e) => handleCVC(e)}
                                                    onBlur={(e) => handleRequiredCheck(e, 'VALIDATION_MandatoryCardCVC')}
                                                />
                                                <FormFeedback>
                                                    <span id="cvc_LABEL"></span>
                                                </FormFeedback>
                                            </FormGroup>
                                        </div>

                                        <hr />

                                        <div className="c-form__input-group u-component-spacing">
                                            <label className="c-form__label">Sub-Total</label>
                                            <label style={{ flexBasis: "75%", position: "relative !important" }}>&pound;{priceExcVat.toFixed(2)}</label>
                                        </div>
                                        <div className="c-form__input-group u-component-spacing">
                                            <label className="c-form__label">VAT (20%)</label>
                                            <label style={{ flexBasis: "75%", position: "relative !important" }}>&pound;{(priceExcVat * 0.2).toFixed(2)}</label>
                                        </div>
                                        <div className="c-form__input-group u-component-spacing">
                                            <label className="c-form__label">TOTAL</label>
                                            <label style={{ flexBasis: "75%", position: "relative !important" }}>&pound;{(priceExcVat + (priceExcVat * 0.2)).toFixed(2)}</label>
                                        </div>
                                </Col>
                            </Row>
                        </div>

                        <div className="u-flex-end u-component-spacing--2x">
                            <button className="js-change-panel c-cta" onClick={() => handleMakePayment()}>
                                Make Payment
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default PaymentDetails;