import React from 'react';
import { Redirect, Route } from 'react-router';
import { getSupplierID } from '../../helpers/general';

const LayoutRoute = (props) => {
    let { layout, component, ...rest } = props;

    return (<Route {...rest} render={(props) =>
        getSupplierID() > 0
            ? React.createElement(layout, props, React.createElement(component, props))
            : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    } />
    );
}

export default LayoutRoute;