import { authHeader } from "../helpers/auth-header";

export const packageActions = {
    arePackagesEnabled
}

export const PACKAGES_REQUEST = 'PACKAGES_REQUEST';
export const PACKAGES_ENABLED_SUCCESS = 'PACKAGES_CHECK_SUCCESS';
export const PACKAGES_ENABLED_FAILURE = 'PACKAGES_CHECK_FAILURE';

function request() { return { type: PACKAGES_REQUEST }; }
function success(data) { return { type: PACKAGES_ENABLED_SUCCESS, data }; }
function failure(error) { return { type: PACKAGES_ENABLED_FAILURE, error }; }

export function arePackagesEnabled() {
    return dispatch => {
        dispatch(request());

        fetch(`./Settings/GetWebSetting?settingname=show_packages`, {
            method: 'GET',
            headers: authHeader(false, true)
        })
            .then(response => response.json())
            .then(data => {
                dispatch(success(data === '1'));
            })
            .catch(err => {
                console.log(err);
                dispatch(failure(err));
            });
    }
}