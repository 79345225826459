import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useState } from "react";
import { authHeader } from "../../helpers/auth-header";
import { getApplicationType, getContactID, getSupplierID, handleConfirm, pageEnabled } from "../../helpers/general";
import { history } from '../../helpers/history';
import UpgradeButton from "../controls/UpgradeButton";

const Upgrade = () => {
    const [services, setServices] = useState([]);
    const [packages, setPackages] = useState({ isEnabled: null, packageList: [] });
    const [user, setUser] = useState({ supplierID: -1, contactID: -1 });
    const [upgradePermission, setUpgradePermissions] = useState(false);
    const [isPageEnabled, setIsPageEnabled] = useState(false);

    useEffect(() => {
        pageEnabled('Nav_PricePlans', '/404').then(enabled => { setIsPageEnabled(enabled); });

        setUser(prev => ({
            ...prev,
            supplierID: getSupplierID(),
            contactID: getContactID()
        }))
    }, []);

    useEffect(() => {
        if (user.supplierID > 0) {
            fetch(`./Permissions/CanPurchaseUpgrades?contactID=${user.contactID}`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        setUpgradePermissions(data);
                    }
                })
                .catch(error => console.log('Error: ', error));

            fetch(`./Packages/GetPackageDetails`, {
                method: 'GET',
                headers: authHeader(false, true)
            })
                .then(response => response.json())
                .then(data => {
                    setServices(data);
                })
                .catch(err => {
                    console.log(err);
                });

            fetch(`./Settings/ArePackagesEnabled`, {
                method: 'GET',
                headers: authHeader(false, true)
            })
                .then(response => response.json())
                .then(packagesEnabled => {
                    setPackages(prevState => ({
                        ...prevState, isEnabled: packagesEnabled
                    }));

                    fetch(`./Packages/GetActivePackages?supplierID=${user.supplierID}`, {
                        method: 'GET',
                        headers: authHeader(false, true)
                    })
                        .then(response => response.json())
                        .then(packages => {
                            setPackages(prevState => ({
                                ...prevState, packageList: packages
                            }));
                        })
                        .catch(err => {
                            console.log(err);
                        });
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }, [user.contactID, user.supplierID])

    const handleUpgradePurchase = (packageID, packageValue) => {
        let data = [{ field: 'packageID', value: packageID }, { field: 'price', value: packageValue } ]

        fetch(`./Payment/StartPurchaseUpgrade?supplierID=${user.supplierID}&contactID=${user.contactID}&totalPrice=${packageValue}`, {
            method: 'POST',
            headers: authHeader(false, false),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                history.push(`/basket`);
                return true;
            })
            .catch(err => {
                console.log("Error");
                return false;
            });
    }

    const handleAddToBasket = (packageID, packageValue) => {
        handleConfirm('<p>Adding the Upgrade to your basket will remove all other basket items.</p><p style="margin-bottom:1em"><strong>Do you wish to continue to upgrade your account?</strong></p>',
            function () { return handleUpgradePurchase(packageID, packageValue); },
            function () { return false;},
            'Add Upgrade to Basket?',
            '40em',
            'YES - Continue to upgrade',
            'NO - Cancel the upgrade'
        )
    }

    if (isPageEnabled === true) {
        return (
            <>
                <div className="c-price-plans">
                    <section className="c-price-plans__column">
                        {services &&
                            <>
                                <div className=""></div>
                                {services.map((service, i) => {
                                    return (

                                        <div className="c-price-plans__cell" key={i}>
                                            <div>
                                                <strong className="c-price-plans__row-heading">{service.name}
                                                    {service.canPurchaseAddon && service.limitPackageIDCanPurchase === null && <sup style={{ color: "#f00" }}> *1</sup>}
                                                    {service.limitPackageIDCanPurchase !== null &&
                                                        <sup style={{ color: "#f00" }}> *2</sup>
                                                    }
                                                </strong>
                                                <p className="c-price-plans__row-description">{service.description}</p>
                                            </div>
                                        </div>);
                                }
                                )}
                                <div className="">
                                    <p className="c-price-plans__row-description">Price per Annum</p>
                                </div>
                                <div className="">
                                    <p style={{ fontSize: "0.9rem", verticalAlign: "center", paddingLeft: "1rem" }}><span style={{ color: "#f00" }}>*1</span> - additional services can be purchased with a paid subscription.</p>
                                    <p style={{ fontSize: "0.9rem", verticalAlign: "center", paddingLeft: "1rem" }}><span style={{ color: "#f00" }}>*2</span> - additional service only available to purchase with the selected package.</p>
                                </div>
                            </>
                        }
                    </section>

                    {Object.keys(services).length > 0 && Object.keys(packages.packageList).length > 0 && packages.packageList.map((pkg, i) => {
                        return (
                            <section className={
                                pkg.currentPackage === true ? "c-price-plans__column c-price-plans__column--current" :
                                    pkg.favourite === true
                                        ? "c-price-plans__column c-price-plans__column--favourite"
                                        : "c-price-plans__column"} key={i}
                            >
                                <div className="c-price-plans__cell">
                                    <h3 className="c-price-plans__column-heading">{pkg.packageTitle}</h3>
                                </div>

                                {pkg.sell2PackageServices.map((allowance, j) => {
                                    return (
                                        <div className="c-price-plans__cell" key={j}>
                                            <div>
                                                <strong className="c-price-plans__row-heading">{services.find(x => x.uniqueID === allowance.serviceID).name}</strong>
                                                <p className="c-price-plans__row-description">{services.find(x => x.uniqueID === allowance.serviceID).description}</p>
                                            </div>
                                            {allowance.serviceAllowance.toLowerCase() === "yes"
                                                ?
                                                <span className="c-price-plans__cell-value">
                                                    <span aria-hidden="true" role="presentation">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" clipRule="evenodd" viewBox="0 0 54 54" width="20">
                                                            <path fill="#34cb00" d="m1.87 32.88 13.46 19.06a4.21 4.21 0 0 0 6.92-.09L52.21 6.52a4.2 4.2 0 0 0-7-4.62L18.62 42.12 8.72 28.1a4.18 4.18 0 0 0-6.84 4.8l-.01-.02Z" />
                                                        </svg>
                                                    </span>
                                                    <span className="u-visually-hidden">Yes</span>
                                                </span>

                                                : allowance.serviceAllowance.toLowerCase() === "no"
                                                    ?
                                                    <span className="c-price-plans__cell-value">
                                                        <span aria-hidden="true" role="presentation">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" clipRule="evenodd" viewBox="0 0 54 54" width="20">
                                                                <path fill="#cb0000" d="M27 21.1 11.68 5.8a4.17 4.17 0 0 0-5.9 5.89L21.12 27 5.79 42.32a4.17 4.17 0 0 0 5.89 5.9L27 32.88l15.32 15.32a4.17 4.17 0 0 0 5.9-5.89L32.88 27l15.32-15.32a4.17 4.17 0 0 0-5.89-5.9L27 21.12Z" />
                                                            </svg>
                                                        </span>
                                                        <span className="u-visually-hidden">No</span>
                                                    </span>
                                                    :
                                                    <span className="c-price-plans__cell-value">
                                                        {allowance.serviceAllowance}
                                                    </span>
                                            }
                                        </div>
                                    );
                                })
                                }

                                <div className="c-price-plans__cell">
                                    <div>
                                        <strong className="c-price-plans__row-heading">Price</strong>
                                    </div>
                                    <span className="c-price-plans__cell-value">
                                        {!pkg.currentPackage &&
                                            <>
                                                &pound;&nbsp;{pkg.price}
                                                {pkg.price > 0 && <span style={{ fontSize: "0.85rem" }}> (exc. VAT)</span>}
                                            </>
                                        }
                                    </span>
                                </div>

                                <div className="c-price-plans__cell">
                                    <span></span>
                                    {!pkg.currentPackage &&
                                        <UpgradeButton packageID={pkg.packageID} price={pkg.price} upgradePermission={upgradePermission} handleProceedToPay={handleAddToBasket} />
                                    }
                                </div>
                            </section>);
                    })}
                </div>
            </>
        )
    } else {
        return null;
    }
}

export default Upgrade;