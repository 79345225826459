import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { PropTypes } from 'prop-types';
import { authHeader } from '../../helpers/auth-header';
import { CustomInput, Card, CardBody, Table, PopoverBody, Spinner, UncontrolledPopover } from 'reactstrap';
import { baseUrl } from '../../helpers/history';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import classnames from 'classnames';

const $ = require('jquery');
require('datatables.net-bs4');

const DataTable = props => {
    let { id, columns, defaultOrder, url, parameters, rowSelected, params, searchString, isSearching, sorting, paging, info, allowAnonymous = false,
        scrollY = '', pagingType = '', scrollCollapse = false, checkbox = false, singleSelect = false, onClick = null } = props;

    url = `${url}`;
    const [tmpUrl, setUrl] = useState(url);
    const [isLoading, setIsLoading] = useState(isSearching);
    const [modified, setModified] = useState(false);

    const table = useRef();

    useEffect(() => {
        if (params && params.length) {
            let obURL = params ? `${url}?${params}` : url;
            setUrl(obURL);
        }
    }, [params, url, isSearching]);

    useEffect(() => {
        if (table.current !== undefined) {
            table.current.search(searchString).draw();
        }
    }, [searchString, isSearching]);

    async function getToolTip(dataField, toolTip) {
        let response = await fetch(`${baseUrl}/Data/GetToolTip?dataField=${dataField}&toolTip=${toolTip}`, {
            method: 'GET',
            headers: authHeader()
        });

        let data = await response.json();

        return data;
    }

    async function modifyColumns() {
        for (var i = 0; i < columns.length; i++) {
            let col = columns[i];

            if (col.repeat === true) {
                var colName = col.name;
                var colData = col.data;
                var colCount = col.count;
                var colHeader = col.header;
                var colWidth = col.width;
                var colOrderable = col.orderable || false;

                for (var j = 1; j <= colCount; j++) {
                    let newColName = colName.replace('$repeat$', j);

                    let newCol = {
                        data: `${colData.replace('$repeat$', j)}`,
                        header: colHeader,
                        orderable: colOrderable,
                        name: newColName,
                        width: colWidth,
                        visible: true,
                        createdCell: (td, cellData) => {
                            (col.options.icons || []).map(opt => {
                                if (opt.id === cellData) {
                                    getToolTip(newColName, opt.tooltip).then(tooltip => {
                                        ReactDOM.render(<CustomTooltip image={opt.image} tooltip={tooltip} />, td);
                                    });
                                } else {
                                    ReactDOM.render(<span></span>, td);
                                }
                                return true;
                            });
                        }
                    };

                    if (j === 1) {
                        columns.splice(i, 1, newCol);
                    } else {
                        columns.splice(j + 1, 0, newCol);
                    }
                }
            } else {
                if (col.options) {
                    columns[i].createdCell = (td, cellData) => {
                        (col.options.icons || []).map(opt => {
                            if (opt.id === cellData) {
                                getToolTip(col.name, opt.tooltip).then(tooltip => {
                                    ReactDOM.render(<CustomTooltip image={opt.image} tooltip={tooltip} />, td);
                                });
                            } else {
                                ReactDOM.render(<span></span>, td);
                            }
                            return true;
                        });
                    }
                }
            }
        }
    }

    useEffect(() => {
            modifyColumns().then(() => {
                setModified(true);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        for (let i = 0; i < parameters.length; i++) {
            sessionStorage.setItem(parameters[i].title, -1);
        }

        if (searchString && searchString.length && !tmpUrl.includes(searchString)) {
            return
        }

        if (modified) {
            table.current = $(`#${id}Table`).DataTable({
                destroy: true,
                paging: paging,
                info: info,
                pageLength: 20,
                pagingType: pagingType,
                scrollY: scrollY,
                scrollCollapse: scrollCollapse,
                ordering: sorting,
                order: [
                    defaultOrder
                ],
                dom: "<'row table-scroll'<'col-sm-12'tr>>" +
                    "<'row mt-2'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
                serverSide: true,
                processing: true,
                ajax: {
                    type: 'POST',
                    url: tmpUrl,
                    async: false,
                    headers: authHeader(false, allowAnonymous),
                    data: function (data) {
                        return JSON.stringify(data);
                    }
                },
                columns: columns,
                language: {
                    processing: '<i className="fa fa-spinner fa-5x fa-pulse"></i>'
                },
                initComplete: function (settings, json) {
                    setIsLoading(false)
                },
                createdRow: (row, data) => {
                    $(row).on('click', function () {
                        $(row).parent().find("tr").each(function () {
                            if ($(this).hasClass('SelectedStyle')) {
                                $(this).removeClass('SelectedStyle')
                            }
                        })

                        for (let i = 0; i < parameters.length; i++) {
                            sessionStorage.setItem(parameters[i].title, data[parameters[i].data]);
                        }

                        rowSelected(row); // Handles the Row Selection on the Parent Component (forces component update)

                        $(row).addClass('SelectedStyle')
                    });

                    if (onClick != null) {
                        $(row).off().on('click', function () { onClick(row, data) })
                    }

                    // Add a label htmlFor attribute for the checkbox to link the label to the checkbox
                    if (checkbox) {
                        $(row).find('td:nth-child(2)')
                            .html(`<label style='margin-top: 0.3rem;' for='chkRegLinkedPortals${data.UniqueID}'>${$(row).find('td:nth-child(2)').html()}</label>`)
                    }

                    if (parameters && parameters.length) {
                        if (parseInt(sessionStorage.getItem(parameters[0].title)) === data[parameters[0].data]) {
                            $(row).addClass('SelectedStyle');
                        }
                    }
                }
            });
        }

        return function cleanup() {
            for (let i = 0; i < parameters.length; i++) {
                sessionStorage.removeItem(parameters[i].title);
            }
        }
    }, [allowAnonymous, checkbox, columns, defaultOrder, id, info, modified, onClick, paging, pagingType, parameters, rowSelected, scrollCollapse, scrollY, searchString, sorting, tmpUrl]);

    const updateCheckedItems = (e) => {
        let items = [];

        let curItems = JSON.parse(sessionStorage.getItem(id));

        if (curItems) {
            curItems.map(i => {
                return items.push(i)
            });
        }

        if (singleSelect === true) {
            items = [];
            $(`[id^="chk${id}"]`).each(function () {
                let checkboxID = $(this).attr('id');

                if (checkboxID !== e.target.id) {
                    $(this).prop('checked', false);
                }
            })
        }

        if (e.target.checked) {
            items.push(e.target.value);
        } else {
            let index = items.indexOf(e.target.value);
            if (index > -1) {
                items.splice(index, 1);
            }
        }

        items = [...new Set([...items])];

        sessionStorage.setItem(id, JSON.stringify(items));
    }

    const isChecked = (value) => {
        let checked = false;

        let curItems = JSON.parse(sessionStorage.getItem(id));
        if (curItems) {
            checked = curItems.some(function (val) {
                return parseInt(val) === value;
            });
        }

        return checked;
    }

    if (checkbox === true && columns[0] && columns[0].visible === false) {
        columns.unshift({
            data: columns[0].data,
            header: ' ',
            orderable: false,
            createdCell: (td, cellData) => {
                $(td).addClass("text-center w-5pc");
                ReactDOM.render(<CustomInput type="checkbox" id={`chk${id}${cellData}`} value={cellData} defaultChecked={isChecked(cellData)} onClick={(e) => updateCheckedItems(e)} />, td);
            },
            width: '5%'
        });
    }

    return (
        <React.Fragment>
            <Card className="card-success">
                <CardBody className={scrollCollapse ? 'padding-0-10' : ''}>
                    <Table id={`${id}Table`} size="sm" width="100%">
                        <thead>
                            <tr>
                                {columns.map((col, i) => {
                                    if (col.header !== undefined) {
                                        if (col.header[0] === '$') {
                                            return (
                                                <th key={i}>
                                                    <span className="p-0" id={`sp${i}`}>{col.header}</span>
                                                    {col.orderable !== false && sorting &&
                                                        <UncontrolledPopover placement="top" target={`sp${i}`} trigger="hover">
                                                            <PopoverBody>
                                                                {`Sort by ${col.header}`}
                                                            </PopoverBody>
                                                        </UncontrolledPopover>
                                                    }
                                                </th>
                                            )
                                        }
                                        else {
                                            return (
                                                <th key={i}>
                                                    <span className="p-0" id={`sp${i}`}>{col.header}</span>
                                                    {col.orderable !== false && sorting &&
                                                        <UncontrolledPopover placement="top" defaultOpen={false} target={`sp${i}`} trigger="hover">
                                                            <PopoverBody>
                                                                {`Sort by ${col.header}`}
                                                            </PopoverBody>
                                                        </UncontrolledPopover>
                                                    }
                                                </th>
                                            )
                                        }
                                    } else {
                                        return (
                                            <th key={i} style={(col.visible === false ? { display: "none" } : {})}>
                                                <span className="p-0" id={`sp${i}`}>{col.name}</span>
                                                {col.orderable !== false && sorting &&
                                                    <UncontrolledPopover placement="top" target={`sp${i}`} trigger="hover">
                                                        <PopoverBody>
                                                            {`Sort by ${col.name}`}
                                                        </PopoverBody>
                                                    </UncontrolledPopover>
                                                }
                                            </th>
                                        )
                                    }
                                })}
                            </tr>
                        </thead>
                    </Table>
                </CardBody>
            </Card>
            {isLoading &&
                <div className="spinner"><Spinner />{' '}</div>
            }
        </React.Fragment>
    );
}

const CustomTooltip = props => {

    let { image, tooltip } = props;

    const ref = useRef();

    return (
        <>
            <span className={classnames(image, 'p-0')} ref={ref}></span>
            <UncontrolledPopover placement="right" defaultOpen={false} target={ref} trigger="hover">
                <PopoverBody>
                    {tooltip}
                </PopoverBody>
            </UncontrolledPopover>
        </>
    )
}

DataTable.propTypes = {
    id: PropTypes.string,
    supplierID: PropTypes.number,
    columns: PropTypes.array,
    defaultOrder: PropTypes.array,
    url: PropTypes.string,
    parameters: PropTypes.array,
    rowSelected: PropTypes.func,
    searchString: PropTypes.string,
    isSearching: PropTypes.bool,
    sorting: PropTypes.bool,
    paging: PropTypes.bool,
    info: PropTypes.bool
}

DataTable.defaultProps = {
    id: '',
    columns: [],
    defaultOrder: [],
    url: '',
    parameters: [],
    rowSelected: () => { },
    searchString: '',
    isSearching: false,
    sorting: true,
    paging: true,
    info: true
}

export default DataTable;