import React, { useEffect } from 'react';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';

require('datatables.net-bs4');

const PackageSelection = props => {
    let { regDetails, setRegDetails, services, availablePackages, setPriceExcVat, handleNextClick } = props;

    useEffect(() => {
        if (regDetails.tempPlan == null) {
            document.getElementById('btn-summary').style.visibility = 'hidden'
        } else {
            if (Object.keys(availablePackages).length > 0) {
                document.getElementById('btn-summary').style.visibility = 'visible'
                setPriceExcVat(availablePackages.find(x => x.packageID === parseInt(regDetails.tempPlan)).price)
            }
        }
    }, [availablePackages, regDetails.tempPlan, setPriceExcVat])

    function handleChange(e) {
        e.persist();
        setRegDetails(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));

        setPriceExcVat(availablePackages.find(x => x.packageID === parseInt(e.target.value)).price)
    }

    return (
        <section className="c-multi-step-form__panel" inert={"true".toString()}>
            <div className="c-multi-step-form__panel-content card-body">
                <h3 className="c-broken-underline u-component-spacing">Packages</h3>
                <div className="c-price-plans">
                    <section className="c-price-plans__column">
                        {services &&
                            <>
                                <div className=""></div>
                                {services.map((service, i) => {
                                    return (

                                        <div className="c-price-plans__cell" key={i}>
                                            <div>
                                                <strong className="c-price-plans__row-heading">{service.name}</strong>
                                                <p className="c-price-plans__row-description">{service.description}</p>
                                            </div>
                                        </div>);
                                }
                                )}
                                <div className=""></div>
                                <div className=""></div>
                            </>
                        }
                    </section>

                    {Object.keys(services).length > 0 && Object.keys(availablePackages).length > 0 && availablePackages.map((pkg, i) => {
                        return (
                            <section className={pkg.favourite === true ? "c-price-plans__column c-price-plans__column--favourite" : "c-price-plans__column"} key={i}>
                                <div className="c-price-plans__cell">
                                    <h3 className="c-price-plans__column-heading">{pkg.packageTitle}</h3>
                                </div>

                                {pkg.sell2PackageServices.map((allowance, j) => {
                                    return (
                                        <div className="c-price-plans__cell" key={j}>
                                            <div>
                                                <strong className="c-price-plans__row-heading">{services.find(x => x.uniqueID === allowance.serviceID).name}</strong>
                                                <p className="c-price-plans__row-description">{services.find(x => x.uniqueID === allowance.serviceID).description}</p>
                                            </div>
                                            <span className="c-price-plans__cell-value">{allowance.serviceAllowance}</span>
                                        </div>
                                    );
                                })
                                }

                                <div className="c-price-plans__cell">
                                    <div>
                                        <strong className="c-price-plans__row-heading">Price</strong>
                                    </div>
                                    <span className="c-price-plans__cell-value">&pound;&nbsp;{pkg.price}</span>
                                </div>


                                <div className="c-price-plans__cell">
                                    <div className="c-form__input-group">
                                        <input
                                            className="c-radio-input__radio u-visually-hidden"
                                            type="radio"
                                            id={`tempPlan${pkg.packageID}`}
                                            name='tempPlan'
                                            value={pkg.packageID}
                                            defaultChecked={regDetails.tempPlan ? pkg.packageID === regDetails.tempPlan : false}
                                            onClick={(e) => handleChange(e)}
                                        />
                                        <label className="c-checkbox-label" htmlFor={`tempPlan${pkg.packageID}`}>Select</label>
                                    </div>
                                </div>
                            </section>);
                    })}
                </div>

                <div className="u-flex-end u-component-spacing--2x">
                    <button id='btn-summary' className="js-change-panel c-cta" type='button' onClick={(e) => handleNextClick(e)}>Next step: Summary</button>
                </div>
            </div>
        </section>

    )
}

export default PackageSelection;