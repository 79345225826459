import React, { useCallback, useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Translation from '../controls/Translation';
import { useEffect } from 'react';

const Certificates = prop => {
    let { supplierDetails, handleChange, formatDateDefault, updateDetailsPermission } = prop;
    const [activeTab, setActiveTab] = useState('certificate-tab-0');

    const toggle = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);

            var certificateTab = document.getElementById('certificate-tab-select');

            if (certificateTab !== null) {
                certificateTab.value = tab;
            }
        }
    }

    const handleTabMenu = useCallback((tabMenu, event) => {
        if (tabMenu && event.target.value) {
            tabMenu.querySelector('#' + event.target.value).click();
        }
    }, []);

    useEffect(() => {
        const tabCertificateSelect = document.querySelector('.js-certificate-tab-select');
        const tabCertificateMenu = document.querySelector('.' + tabCertificateSelect.dataset.tabTarget);

        tabCertificateSelect.addEventListener('change', event => {
            handleTabMenu(tabCertificateMenu, event);
        });

        return function cleanup() {
            window.removeEventListener('change', handleTabMenu());
        }
    }, [handleTabMenu]);

    return (
        <>
            <div className='tabs'>
                <div className="c-tab-select">
                    <label className="c-tab-select__label" htmlFor="tab-select">Certificate View:</label>
                    <select id="certificate-tab-select" className="c-tab-select__select js-certificate-tab-select" data-tab-target="js-certificate-tabs" defaultValue={activeTab}>
                        <option value='certificate-tab-0'>Construction Industry Scheme (CIS)</option>
                        <option value='certificate-tab-1'>Gas Safe</option>
                    </select>
                </div>

                <div className='tabs'>
                    <Nav tabs className="js-certificate-tabs">
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'certificate-tab-0' })}
                                id='certificate-tab-0'
                                data-toggle='tab'
                                aria-controls='certificate-tab-0'
                                aria-label='cis'
                                onClick={() => { toggle('certificate-tab-0'); }}
                            >
                                <Translation ID="CIS" />
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'certificate-tab-1' })}
                                id='certificate-tab-1'
                                data-toggle='tab'
                                aria-controls='certificate-tab-1'
                                aria-label='gas safe'
                                onClick={() => { toggle('certificate-tab-1'); }}
                            >
                                <Translation ID="GasSafe" />
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
            </div>

            <TabContent activeTab={activeTab}>
                <TabPane id="certificate-tab-0" tabId='certificate-tab-0'>
                    <div className='tab-pane__inner top'>
                        <div className='c-form'>                            
                            <h4><Translation ID="CIS" /></h4>
                            <div className='c-form__input-group'>
                                <Translation ID="CISCertificate" htmlFor='cisCertType' />
                                <input name='cisCertType' id='cisCertType' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.cisCertType} onChange={(e) => handleChange(e)} />
                            </div>
                            <div className='c-form__input-group'>
                                <Translation ID="CISCardNo" htmlFor='cisCardNo' />
                                <input name='cisCardNo' id='cisCardNo' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.cisCardNo} onChange={(e) => handleChange(e)} />
                            </div>
                            <div className='c-form__input-group'>
                                <Translation ID="CISStartDate" htmlFor='cisStart' />
                                <input id="cisStart" name="cisStart" className="c-form__input" disabled={!updateDetailsPermission} type="date" autoComplete="false" defaultValue={formatDateDefault(supplierDetails.cisStart)} onChange={(e) => handleChange(e)} />
                            </div>
                            <div className='c-form__input-group'>
                                <Translation ID="CISDate" htmlFor='cisEnd' />
                                <input id="cisEnd" name="cisEnd" className="c-form__input" disabled={!updateDetailsPermission} type="date" autoComplete="false" defaultValue={formatDateDefault(supplierDetails.cisEnd)} onChange={(e) => handleChange(e)} />
                            </div>
                        </div>
                    </div>
                </TabPane>
                <TabPane id="certificate-tab-1" tabId='certificate-tab-1'>
                    <div className='tab-pane__inner'>
                        <div className='c-form'>
                            <h4><Translation ID="GasSafe" /></h4>                            
                            <div className='c-form__input-group'>
                                <Translation ID="GasSageRegNo" htmlFor='corgiRegNo' />
                                <input name='corgiRegNo' id='corgiRegNo' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.corgiRegNo} onChange={(e) => handleChange(e)} />
                            </div>
                            <div className='c-form__input-group'>
                                <Translation ID="GasSageStartDate" htmlFor='corgiStart' />
                                <input id="corgiStart" name="corgiStart" className="c-form__input" disabled={!updateDetailsPermission} type="date" autoComplete="false" defaultValue={formatDateDefault(supplierDetails.corgiStart)} onChange={(e) => handleChange(e)} />
                            </div>
                            <div className='c-form__input-group'>
                                <Translation ID="GasSageEndDate" htmlFor='corgiExpiry' />
                                <input id="corgiExpiry" name="corgiExpiry" className="c-form__input" disabled={!updateDetailsPermission} type="date" autoComplete="false" defaultValue={formatDateDefault(supplierDetails.corgiExpiry)} onChange={(e) => handleChange(e)} />
                            </div>
                        </div>
                    </div>
                </TabPane>
            </TabContent>
        </>
    );
}

export default Certificates;