import React, { useEffect } from "react";
import { handleBackground } from "../../helpers/general";
import Footer from "../layout/Footer";
import NavMenu from "../layout/NavMenu";

require('datatables.net-bs4');

const Error404 = () => {

    useEffect(() => {
        handleBackground();
    }, []);

    return (
        <>
            <NavMenu />
            <main className="main-content u-container">
            {/*<div className="main-content u-container u-container--small container-fluid">*/}
                <div className="row u-component-spacing">
                    <div className="col-md u-component-spacing--2x">
                        <div className="card main-content__inner" style={{ height: "calc(100% - 1rem)" }} >
                            <div className="card-body">
                                <div className="c-card card u-component-spacing" style={{ height: "calc(100% - 1rem)", textAlign: "center" }}>
                                    <div className="card-body">
                                        <div className="u-component-spacing u-branded-underline" style={{ fontSize: "10rem", fontWeight: "bold" }}>
                                            404
                                        </div>
                                        <div className="c-form u-component-spacing--2x">

                                            <div className="u-component-spacing--4x">
                                                <label className='c-form__label' style={{ fontSize: "3rem" }}>
                                                    Page Not Found
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    )
}

export default Error404;