import moment from 'moment';
import { authHeader } from './auth-header';

const $ = require('jquery');
require('datatables.net-bs4');

export const vDateFormats = ['DD/MM/YYYY hh:mm:ss A', 'DD/MM/YYYY hh:mm:ss', 'DD/MM/YYYY HH:mm:ss', 'DD/MM/YYYY hh:mm', 'DD/MM/YYYY HH:mm', 'DD/MM/YYYY', 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD'];

export function updateControlValue(vID, value) {
    // Update the control with the new value
    $(document.getElementById(vID)).val(value).trigger('change');
}

// Update DateTime control
export function updateDateTimeControlValue(vID, value) {

    let vFullDate = moment(value, vDateFormats, true);

    if (!vID.startsWith("ctrl_")) {
        updateControlValue(vID, vFullDate.format('DD/MM/YYYY HH:mm').toString());
    }

    // Only update the date if it's not a 'blank' date
    if (vFullDate.isAfter('01/01/1899 00:00')) {

        // Split into _Date, _Hour, _Minute formats
        let vDate = vFullDate.format('DD/MM/YYYY').toString();
        let cDate = document.getElementById(`${vID}_Date`);
        if (cDate) {
            cDate.value = vDate;

            let e = new CustomEvent('changeDate',
                {
                    bubbles: true,
                    detail: { date: () => vDate }
                });
            cDate.dispatchEvent(e);
        }

        let vHours = vFullDate.format('HH').toString();
        let cHours = document.getElementById(`${vID}_Hours`);
        if (cHours) {
            cHours.value = vHours;

            let e = new Event('change', { bubbles: true });
            cHours.dispatchEvent(e);
        }

        let vMins = vFullDate.format('mm').toString();
        let cMins = document.getElementById(`${vID}_Minutes`);
        if (cMins) {
            cMins.value = vMins;

            let e = new Event('change', { bubbles: true });
            cMins.dispatchEvent(e);
        }
    }
}

export async function getFileFromServer(filePath) {
    const response = await fetch(`./Data/GetFileFromServer?filePath=${filePath}`, {
        method: 'GET',
        headers: authHeader(false, true)
    })
    const data = await response.blob();
    return data;
}

function daysInFebruary(iYear) {
    return (((iYear % 4 === 0) && ((!(iYear % 100 === 0)) || (iYear % 400 === 0))) ? 29 : 28);
}

function DaysArray(n) {
    let arr = [];

    for (let i = 1; i <= n; i++) {
        arr[i] = 31;
        if (i === 4 || i === 6 || i === 9 || i === 11) {
            arr[i] = 30;
        }
        if (i === 2) {
            arr[i] = 29;
        }
    }

    return arr;
}

function UpdateLinkFields(vID) {
    // Check to see if we should be matching another field.
    if ($(document.getElementById(vID)).attr('mf') !== undefined && $(document.getElementById(vID)).attr('mf').length > 0) {
        // Split the attribute as we may need to loop around
        let vFields = $(document.getElementById(vID)).attr('mf').split('|');
        for (let i = 0; i < vFields.length; i++) {
            let vDateValue = $(document.getElementById(vID)).val();

            let vDate1Hour, vDate1Mins;

            vDate1Hour = $(document.getElementById(vID.replace('_Date', '') + "_Hours")).val();
            vDate1Mins = $(document.getElementById(vID.replace('_Date', '') + "_Minutes")).val();

            let vNewDate = vDateValue + ' ' + vDate1Hour + ':' + vDate1Mins;

            // We should set the value of the fields we are matching against.
            updateDateTimeControlValue(vFields[i].replace('_Date', ''), vNewDate);
        }
    }
}

export function checkDateVal(strID) {

    let objDate = document.getElementById(strID + "_Date");
    if (objDate) {
        let strDate = objDate.value;

        let dtDelimter = "/";
        let daysInMonth = DaysArray(12)
        let maxYear = 9999;

        let pos1 = strDate.indexOf(dtDelimter);
        let pos2 = strDate.indexOf(dtDelimter, pos1 + 1);
        let strDay = strDate.substring(0, pos1);
        let strMonth = strDate.substring(pos1 + 1, pos2);
        let strYear = strDate.substring(pos2 + 1);

        let bFail = false;

        let iDay = parseFloat(strDay);
        let iMonth = parseFloat(strMonth) - 1;
        let iYear = parseFloat(strYear);

        if (iDay < 1 || iDay > 31 || (iMonth === 1 && iDay > daysInFebruary(iYear)) || iDay > daysInMonth[iMonth + 1]) {
            bFail = true;
        }
        if ((iMonth < 0) || (iMonth > 11)) {
            bFail = true;
        }
        if (iYear === 0 || iYear > maxYear) {
            bFail = true;
        }

        if (bFail) {
            window.alert(strDate + " is an invalid date, please try again.");
            objDate.value = "";
        }
        else {
        }
    }

    UpdateLinkFields(strID + "_Date");
}