import React, { useEffect, useState } from 'react';
import ThemeSwitcher from '../controls/ThemeSwitcher';
import InfoStrap from '../Home/InfoStrap';
const moment = require('moment');

const Footer = (props) => {
    let { infoStrap } = props;
    const [buildDetails, setBuildDetails] = useState({});
    const [environment, setEnvironment] = useState('');
    const [application, setApplication] = useState('');
    const [version, setVersion] = useState('');

    useEffect(() => {
        fetch('./System/GetSystemEnvironment', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => { setEnvironment(data) })
            .catch(err => console.log(err));

        fetch('./System/GetBuildDetails', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => setBuildDetails({ version: data.Version, date: data.Date }))
            .catch(err => console.log(err));

        fetch('./System/GetApplicationType', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => setApplication(data))
            .catch(err => console.log(err));

        if (process.env.NODE_ENV === 'production') {
            fetch('./version.json')
                .then(response => response.json())
                .then(data => {
                    setVersion(data.version);
                })
                .catch(err => console.error('Error fetching version:', err));
        } else {
            setVersion(process.env.REACT_APP_VERSION);
        }

        return () => {
            setEnvironment('');
            setBuildDetails({});
            setApplication('');
            setVersion('');
        }
    }, [])


    return (
        <footer className="c-footer">
            {infoStrap === true &&
                <InfoStrap />
            }
            <div className="c-footer-strip">
                <div className="c-footer-strip__inner u-container">
                    <div className="c-footer-strip__report-bug-container">
                        {/*Still need to develop*/}
                        {/*<a className="c-footer-strip__report-bug" href="#"><i className="fa fa-bug" aria-hidden="true"></i> Report a bug</a>*/}
                        <a className="" href="https://www.in-tend.co.uk/legal" target="_blank" rel="noreferrer"><u>Terms of Use and Legal</u></a>
                    </div>
                    {application === "Sell2UK" &&
                        <ThemeSwitcher />
                    }
                    <small className="c-footer-strip__copyright">&copy; In-tend Ltd {new moment().format('YYYY')}</small>
                    <dl className="c-footer-strip__service-info">
                        <div>
                            <dt>Server:</dt>
                            <dd style={{whiteSpace:"nowrap"}}>{environment}</dd>
                        </div>
                        <div>
                            <dt style={{ flexBasis: "fit-content" }}>Version:</dt>
                            <dd style={{ flexBasis: "fit-content" }}>v{buildDetails.version}-{version}</dd>
                        </div>
                    </dl>
                </div>
            </div>
        </footer>
    );
}

export default Footer;