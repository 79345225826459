import { createBrowserHistory } from 'history';


let base = '';

//THIS IS FOR PRODUCTION AND BETA

if (process.env.NODE_ENV === 'development') {
    base = '';
} else {
    base = '/' + window.location.pathname.substring(1).split('/')[0];
}

// TO HERE

export const history = createBrowserHistory({
    basename: base
});

export const baseUrl = base;