import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table, Tooltip } from 'reactstrap';
import { authHeader } from "../../helpers/auth-header";
import { getApplicationType, getContactID, getSupplierID } from "../../helpers/general";
import Translation from "../controls/Translation";
import PaymentDetailsModal from "../Payment/PaymentDetails_Modal";

const Basket = () => {
    const [user, setUser] = useState({ supplierID: -1, contactID: -1 });
    const [basket, setBasket] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [purchasePermission, setPurchasePermission] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setShowModal(!showModal);

    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    useEffect(() => {
        setUser((prev) => ({
            ...prev,
            supplierID: getSupplierID(),
            contactID: getContactID(),
        }));
    }, []);

    useEffect(() => {
        if (user.supplierID > 0) {
            fetch(`./Permissions/CanPurchaseProducts?contactID=${user.contactID}`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        setPurchasePermission(data);
                    }
                })
                .catch(error => console.log('Error: ', error));

            getApplicationType()
                .then(application => {
                    fetch(`./Payment/GetBasketInfo?supplierID=${user.supplierID}&contactID=${user.contactID}&systemName=${application}`, {
                        method: "GET",
                        headers: authHeader(false, false),
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            setBasket(data);
                        })
                        .catch((err) => {
                            console.log("error");
                        });
                });
        }
    }, [user.contactID, user.supplierID]);

    const handleProceedToPay = () => {
        setIsLoading(true)
        toggle();
    }

    const handleDeleteBasketItem = (basketItemID) => {
        if (basketItemID && basketItemID > 0) {
            let removeData = {
                "BasketItemID": basketItemID,
                "SupplierID": user.supplierID,
                "ContactID": user.contactID
            };

            fetch(`./Payment/RemoveBasketItem`, {
                method: 'POST',
                headers: authHeader(false, false),
                body: JSON.stringify(removeData)
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        let updateBasketItems = [...basket.basketItems];
                        var baketItemIndex = updateBasketItems.findIndex(x => x.basketItemID === basketItemID);
                        let selectedBasketItem = updateBasketItems[baketItemIndex];

                        let updateSubTotal = basket.subTotal - selectedBasketItem.priceExcVat;
                        let updateTax = basket.totalTax - selectedBasketItem.vat;
                        let updateTotal = basket.priceToCharge - selectedBasketItem.totalPrice;

                        updateBasketItems.splice([baketItemIndex], 1);

                        setBasket(prevState => ({
                            ...prevState,
                            basketItems: updateBasketItems,
                            subTotal: updateSubTotal,
                            totalTax: updateTax,
                            priceToCharge: updateTotal
                        }));
                    }
                })
                .catch(err => {
                    console.log("ERROR", err);
                })
        }
    }

    const handleBasketItemChange = (e) => {
        e.persist();
        let checked = e.target.checked;

        let selectedID = Number(e.target.id.replace('bi_', ''));
        let updateBasketItems = [...basket.basketItems];
        var index = updateBasketItems.findIndex(x => x.basketItemID === selectedID);

        let basketItemDetails = updateBasketItems[index];

        let updateSubTotal = checked ? basket.subTotal + basketItemDetails.priceExcVat : basket.subTotal - basketItemDetails.priceExcVat;
        let updateTax = checked ? basket.totalTax + basketItemDetails.vat : basket.totalTax - basketItemDetails.vat;
        let updateTotal = checked ? basket.priceToCharge + basketItemDetails.totalPrice : basket.priceToCharge - basketItemDetails.totalPrice;
        
        setBasket(value => ({
            ...value,
            basketItems: updateBasketItems,
            subTotal: updateSubTotal,
            totalTax: updateTax,
            priceToCharge: updateTotal
        }));

        updateBasketItems[index]["selected"] = e.target.checked;
    }

    if (Object.keys(basket).length > 0) {
        return (
            <>
                <main>
                    <div className="padding-l-25 padding-r-20 card main-content__inner">
                        <Card body>
                            <h3 className="c-broken-underline u-component-spacing">
                                Basket Items
                            </h3>
                            <Table id={`BasketItemsTable`} size="sm" width="100%" className="c-invoice-modal__invoice-table">
                                <thead>
                                    <tr>
                                        <th className="c-invoice-modal__table-heading">Description</th>
                                        <th className="c-invoice-modal__table-heading"></th>
                                        <th className="c-invoice-modal__table-heading c-invoice-modal__center-text">Quantity</th>
                                        <th className="c-invoice-modal__table-heading">Unit Price</th>
                                        <th className="c-invoice-modal__table-heading">Price (exc vat)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {basket.basketItems.map((basketItem, i) => {
                                        return (
                                            <tr key={i} className="c-invoice-modal__table-row">
                                                <td>
                                                    <input id={`bi_${basketItem.basketItemID}`} name={`bi_${basketItem.basketItemID}`} type="checkbox" className="custom-control-input" checked={basketItem.selected} onChange={(e) => handleBasketItemChange(e)} />
                                                    <label htmlFor={`bi_${basketItem.basketItemID}`} className='c-checkbox-label'>{basketItem.packageServiceDescription}</label>
                                                </td>
                                                <td>
                                                    <button id={`delete-${basket.basketItemID}`} className="c-tender-actions__action-button c-tender-actions__action-button--bin" onClick={() => { handleDeleteBasketItem(basketItem.basketItemID) }}>Remove</button>
                                                </td>
                                                <td className="c-invoice-modal__center-text">{basketItem.quantity}</td>
                                                <td>&pound;{basketItem.unitPriceExcVat.toFixed(2)}</td>
                                                <td>&pound;{basketItem.priceExcVat.toFixed(2)}</td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td className="c-invoice-modal__table-summary-term" style={{ paddingTop: "2rem" }}>Subtotal</td>
                                        <td className="c-invoice-modal__table-summary-value" style={{ paddingTop: "2rem" }}>&pound;{basket.subTotal.toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "0px" }}></td>
                                        <td style={{ border: "0px" }}></td>
                                        <td style={{ border: "0px" }}></td>
                                        <td className="c-invoice-modal__table-summary-term" style={{ border: "0" }}>VAT ({basket.vatRate}%)</td>
                                        <td className="c-invoice-modal__table-summary-value" style={{ border: "0" }}>&pound;{basket.totalTax.toFixed(2)}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: "0px" }}></td>
                                        <td style={{ border: "0px" }}></td>
                                        <td style={{ border: "0px" }}></td>
                                        <td className="c-invoice-modal__table-summary-term" style={{ paddingTop: "1.5rem", paddingBottom: "1rem", border: "0", fontWeight: "bold" }}>Total ({basket.vatRate}%)</td>
                                        <td className="c-invoice-modal__table-summary-value" style={{ paddingTop: "1.5rem", paddingBottom: "1rem", border: "0", fontWeight: "bold" }}>&pound;{basket.priceToCharge.toFixed(2)}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <hr />
                            {basket.priceToCharge > 0 &&
                                <Row style={{ marginTop: '20px' }}>
                                    <Col md={12} className='c-proceed-to-pay'>
                                        <button id="purchaseButton" className="c-cta" disabled={!purchasePermission} onClick={() => handleProceedToPay()}><Translation ID="ProceedToPay" /></button>
                                        {!purchasePermission &&
                                            <Tooltip
                                                placement="top"
                                                isOpen={tooltipOpen}
                                                target={`purchaseButton`}
                                                toggle={toggleTooltip}
                                            >
                                                You do not have the right permissions to purchase products and services.
                                            </Tooltip>
                                        }
                                    </Col>
                                </Row>
                            }

                        </Card>
                    </div>
                </main>

                <PaymentDetailsModal
                    isOpen={showModal}
                    toggle={toggle}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    priceExcVat={basket.subTotal}
                    basketItems={basket.basketItems }
                />

            </>
        );
    } else {
        return null;
    }
};

export default Basket;
