import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Alert, Button, Card } from 'reactstrap';
import { authHeader } from "../../helpers/auth-header";
import { getApplicationType, getContactID, getSupplierID, goTo, handleBackground, handleConfirm, handleTenderStatus, handleTimedSuccess, logIntoPortal, pageEnabled, registerOnPortal, setTenderButtonState, showCustomError, updateTenderStatus, useQuery } from '../../helpers/general';
import { history } from '../../helpers/history';
import LoginModal from '../Auth/Login_Modal';
import BackToDashboard from '../controls/BackToDashboard';
import BackToOrganisation from '../controls/BackToOrganisation';
import BackToTenderSearch from '../controls/BackToTenderSearch';
import Translation from '../controls/Translation';
import Footer from "../layout/Footer";
import NavBar from '../layout/NavMenu';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const moment = require('moment');

const TenderDetails = () => {
    const [user, setUser] = useState({ supplierID: -1, contactID: -1 });
    const location = useLocation();
    const [tenderDetails, setTenderDetails] = useState({});
    const [feedStatus, setFeedStatus] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthorised, setIsAuthorised] = useState(null);
    const [tenderPermissions, setTenderPermissions] = useState({ canRegisterPortal: false, canPurchaseProducts: false });
    const [packagesEnabled, setPackagesEnabled] = useState(false);
    const [packageService, setPackageService] = useState({ id: 0, price: 0 });
    const [isPageEnabled, setIsPageEnabled] = useState(false);
    const [showPublicFeedLink, setShowPublicFeedLink] = useState(false);
    const [application, setApplication] = useState('');


    let query = useQuery();
    let tenderid = query.get('tid');
    let contactID = user.contactID;
    let supplierID = user.supplierID;
    const [loggedIn, setLoggedIn] = useState(null);

    const toggle = () => setShowModal(!showModal);

    useEffect(() => {
        pageEnabled('Nav_Tenders', '/404').then(enabled => { setIsPageEnabled(enabled); });

        setUser(prev => ({
            ...prev,
            supplierID: getSupplierID(),
            contactID: getContactID()
        }))
        getApplicationType()
            .then(applicationType => {
                setApplication(applicationType);
            });
    }, []);

    useEffect(() => {
        setLoggedIn(user.supplierID > 0);

        if (isAuthorised === null && supplierID > -1 && application !== '') {
            if (contactID > 0) {
                fetch(`./Permissions/PermissionsTenderDetails?contactID=${contactID}`, {
                    method: 'GET',
                    headers: authHeader()
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data) {
                            setTenderPermissions({
                                canRegisterPortal: data.canRegisterPortal,
                                canPurchaseProducts: data.canPurchaseProducts
                            });
                        }
                    })
                    .catch(error => console.log('Error: ', error));
            }

            fetch(`./Tenders/IsTenderVisibleOnSystem?tenderID=${tenderid}&application=${application}`, {
                method: 'GET',
                headers: authHeader(false, true) // MADE PUBLIC, NEED TO REVIEW AFTER BLPD
            })
                .then(response => response.json())
                .then(authorised => {
                    if (authorised) {
                        fetch(`./Tenders/GetTenderDetails?tenderID=${tenderid}&supplierID=${supplierID}&contactID=${contactID}`, {
                            method: 'GET',
                            headers: authHeader(false, true) // MADE PUBLIC, NEED TO REVIEW AFTER BLPD
                        })
                            .then(response => response.json())
                            .then(tenderData => {
                                setTenderDetails(tenderData);

                                if (user && supplierID > 0) {
                                    fetch(`./Suppliers/GetSupplierAllowance?supplierID=${supplierID}&packageService=pservice_publicfeedlink`, {
                                        method: 'GET',
                                        headers: authHeader()
                                    })
                                        .then(response => response.json())
                                        .then(feedLinkVisible => {
                                            setShowPublicFeedLink(feedLinkVisible);
                                        });

                                    fetch(`./Suppliers/GetSupplierTenderStatus?supplierID=${supplierID}&contactID=${contactID}&tenderID=${tenderid}&systemName=${application}`, {
                                        method: 'GET',
                                        headers: authHeader()
                                    })
                                        .then(response => response.json())
                                        .then(supplierTenderStatusData => {
                                            if (supplierTenderStatusData) {
                                                fetch(`./Settings/ArePackagesEnabled`, {
                                                    method: 'GET',
                                                    headers: authHeader(false, true)
                                                })
                                                    .then(response => response.json())
                                                    .then(data => {
                                                        setPackagesEnabled(data)
                                                    })
                                                    .catch(err => {
                                                        console.log(err);
                                                    })

                                                setFeedStatus({
                                                    registeredWithPortal: supplierTenderStatusData.registeredWithPortal,
                                                    watch: supplierTenderStatusData.watch,
                                                    expressed: supplierTenderStatusData.expressedInterest,
                                                    hide: supplierTenderStatusData.hide,
                                                    registerControlStatus: supplierTenderStatusData.registerControlStatus,
                                                    status: supplierTenderStatusData.status,
                                                    basketItemID: supplierTenderStatusData.basketItemID
                                                });

                                                if (supplierTenderStatusData.organiserID > 0) {
                                                    if (supplierTenderStatusData.expressedinterest) {
                                                        document.getElementById(`watch_${tenderid}`).style.display = 'none';
                                                        document.getElementById(`hide_${tenderid}`).style.display = 'none';
                                                    } else {
                                                        setTenderButtonState(document.getElementById(`watch_${tenderid}`), supplierTenderStatusData.watch ? 'Stop Watching' : 'Watch', supplierTenderStatusData.watch);
                                                        setTenderButtonState(document.getElementById(`hide_${tenderid}`), supplierTenderStatusData.hide ? 'Unhide' : 'Hide', supplierTenderStatusData.hide);
                                                    }
                                                }

                                                // Get details of the portal product (ID and price)
                                                fetch(`./Packages/GetPackageServiceDetails?packageService=pservice_portals`, {
                                                    method: 'GET',
                                                    headers: authHeader(false, false)
                                                })
                                                    .then(response => response.json())
                                                    .then(data => {
                                                        if (data) {
                                                            setPackageService({ id: data.uniqueID, price: data.priceExc })
                                                        }
                                                    })
                                                    .catch(err => {
                                                        console.log("ERROR", err);
                                                    });
                                            }
                                        })
                                        .catch(error => {
                                            console.log('Error: ', error);
                                        })
                                }
                                setIsAuthorised(true);
                            })
                            .catch(error => {
                                console.log('Error: ', error);
                            })

                        if (user && supplierID) {
                            updateTenderStatus(1, supplierID, tenderid, false);
                        } else {
                            // handle the background setup if not logged in (public tender)
                            handleBackground();
                        }
                    } else {
                        handleBackground();
                        handleTimedSuccess('error', 'Tender Unavailable', 'The tender is unavailable to view on this system.', 3000,
                            () => {
                                history.goBack()
                            })
                    }
                });
        }
    }, [application, contactID, isAuthorised, supplierID, tenderid, user]);


    function getDuration(openDate, closeDate) {
        let vStart = moment(openDate);
        let vFinish = moment(closeDate);

        // Start by using Years
        let vDiff = '';

        vDiff = vFinish.diff(vStart, 'years', true);
        if (parseInt(vDiff) < 1) {
            vDiff = vFinish.diff(vStart, 'months') + ' Months';
        } else {
            vDiff = Math.round(vDiff * 100) / 100 + ' Years';
        }

        return vDiff;
    }

    // Create our number formatter.
    let formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const handleGoToPortal = (registered, url, tenderid, portalID, portalName) => {
        // NOTE: (Not the best...)
        // portalID here is the organisation you are viewing
        // The portalID needed for the function is actually the PortalID from the IntendMaster.Portal table where it lists the portals ie Sell2, Sell2BLPD, etc.
        // IT IS NOT THE ORGANISATION/PORTAL YOU ARE VIEWING
        if (user && supplierID && supplierID > 0) {
            if (tenderid > 0) {
                updateTenderStatus(1, supplierID, tenderid, false);
            }
            logIntoPortal(registered, url, supplierID, contactID, tenderid, portalID, portalName);
        }
    }

    const registeredOnPortal = useCallback(() => {
        if (sessionStorage.getItem('registeredOnPortal')) {
            setFeedStatus({ registeredWithPortal: feedStatus.registeredWithPortal, watch: feedStatus.watch, expressed: feedStatus.expressedInterest, hide: feedStatus.hide });
            sessionStorage.removeItem('registeredOnPortal');
        }
    }, [feedStatus.expressedInterest, feedStatus.hide, feedStatus.registeredWithPortal, feedStatus.watch])

    useEffect(() => {
        window.addEventListener('registeredOnPortal', registeredOnPortal());

        return function cleanup() {
            window.removeEventListener('registeredOnPortal', registeredOnPortal());
        }
    }, [registeredOnPortal])

    const handleLogInModal = () => {
        setIsLoading(true)
        toggle();
    }

    const handleRegisterOnPortal = (portalID, portalName, supplierID, contactID) => {
        registerOnPortal(portalID, portalName, supplierID, contactID, application);
    }

    const changePortalStatus = (status) => {
        setFeedStatus(prev => ({
            ...prev,
            'status': status
        }))
    }

    const linkPortalToAccount = (tendererPackageServiceID) => {
        let basketData = {
            "SupplierID": user.supplierID,
            "ContactID": user.contactID,
            "PackageServiceID": packageService.id,
            "TendererPackageServiceID": tendererPackageServiceID
        };

        fetch(`./Suppliers/LinkPortalToAccount?systemName=${application}`, {
            method: 'POST',
            headers: authHeader(false, false),
            body: JSON.stringify(basketData)
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    changePortalStatus(0)

                    handleTimedSuccess('success', 'Account Linked', `Your account has been linked - portal allowance has been used.`);
                } else {
                    showCustomError("A problem occurred while retrieving your portal link, please try again.", "Cannot link portal", "error")
                }
            })
            .catch(err => {
                console.log("ERROR", err);
            })
    }


    const addPortalToBasket = (portalID, portalName) => {
        let basketData = {
            "PackageServiceID": packageService.id,
            "TendererPackageServiceID": portalID,
            "Description": portalName,
            "PriceExcVat": packageService.price
        };

        fetch(`./Payment/AddBasketItem?supplierID=${user.supplierID}&contactID=${user.contactID}`, {
            method: 'POST',
            headers: authHeader(false, false),
            body: JSON.stringify(basketData)
        })
            .then(response => response.json())
            .then(data => {
                if (data > 0) {
                    changePortalStatus(2);
                }
            })
            .catch(err => {
                console.log("ERROR", err);
            })
    }

    const handleRemoveFromBasket = uniqueID => {
        if (uniqueID) {
            let removeData = {
                "SupplierID": user.supplierID,
                "ContactID": user.contactID,
                "PackageServiceID": packageService.id,
                "TendererPackageServiceID": uniqueID
            };

            fetch(`./Payment/RemoveBasketItem`, {
                method: 'POST',
                headers: authHeader(false, false),
                body: JSON.stringify(removeData)
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        changePortalStatus(1);
                    }
                })
                .catch(err => {
                    console.log("ERROR", err);
                })

        }
    }

    const handleSelectPortal = (portalID, portalName, isRegistered) => {
        fetch(`./Suppliers/SupplierHasAvailableAllowance?supplierID=${user.supplierID}&packageService=pservice_portals&systemName=${application}`, {
            method: 'GET',
            headers: authHeader(false, false)
        })
            .then(allowanceResponse => allowanceResponse.json())
            .then(allowanceData => {
                if (allowanceData && allowanceData > 0) {
                    handleConfirm(
                        `You currently have ${allowanceData} portal allowance - would you like to use your portal allowance and continue?`,
                        function () {
                            if (isRegistered) {
                                linkPortalToAccount(portalID, portalName, 0)
                            } else {
                                registerOnPortal(portalID, portalName, user.supplierID, user.contactID, application);
                            }
                        }
                    );

                } else {
                    addPortalToBasket(portalID, portalName)
                }
            })
    }

    if (isPageEnabled === true && isAuthorised) {
        return (
            <>
                {loggedIn === false &&
                    <NavBar />
                }
                <main className={loggedIn === true ? '' : 'main-content u-container'}>
                    <div className="padding-l-25 padding-r-20 card main-content__inner">
                        {location.state === undefined &&
                            <BackToDashboard />
                        }
                        {location.state !== undefined && location.state.prevLocation && (location.state.prevLocation === '/tenders' || location.state.prevLocation.startsWith('/publictenders')) &&
                            <BackToTenderSearch
                                searchParams={location.state.searchObject}
                                prevLocation={location.state.prevLocation}
                            />
                        }
                        {location.state !== undefined && location.state.prevLocation && location.state.prevLocation.startsWith('/organisation') &&
                            <BackToOrganisation
                                searchParams={location.state.searchObject}
                                prevLocation={location.state.prevLocation}
                            />
                        }
                        {tenderDetails.archived &&
                            <Card body style={{ marginBottom: "0px", textAlign: "center" }}>
                                <div style={{ alignSelf: "stretch", flexGrow: 1 }}>
                                    <div className="c-card card" style={{ height: "calc(100% - 2rem)" }}>
                                        <div style={{ color: "red" }} className="card-body">
                                            <h1 style={{ fontWeight: "bolder" }}><Translation ID="ArchviedOpportunityHeader" /></h1>
                                            <h4 className="u-component-spacing">
                                                {moment(tenderDetails.archivedDate).isValid() && moment(tenderDetails.archivedDate).isAfter('01-01-1970')
                                                    ?
                                                    <>
                                                        <Translation ID="ArchviedOpportunityBody1" /> {`${moment(tenderDetails.archivedDate).format('Do MMMM YYYY')}, `}
                                                    </>
                                                    : ''}
                                                <Translation ID="ArchviedOpportunityBody2" />
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        }
                        <Card body>
                            <div className="u-flex--xl u-flex--gap-2x">
                                <div style={{ alignSelf: "stretch", flexGrow: 1 }}>
                                    <div className="c-card card u-component-spacing" style={{ height: "calc(100% - 2rem)" }}>
                                        <div className="card-body">
                                            <h2 className="c-page-title c-broken-underline" dangerouslySetInnerHTML={{ __html: tenderDetails.title }} />
                                            <Button color='link' className='c-text-link c-page-intro u-component-spacing' onClick={(() => history.push(`/organisation?oid=${tenderDetails.organiserID}`))}>
                                                <u>{tenderDetails.entityName}</u>
                                            </Button>
                                        </div>
                                    </div>
                                </div>


                                {!tenderDetails.archived &&
                                    <div style={{ alignSelf: "stretch", flexBasis: "19%" }} >
                                        <div className="c-card card u-component-spacing" style={{ height: "calc(100% - 2rem)" }}>
                                            <div className="card-body" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }} >

                                                {tenderDetails.apiUserID === 1 && (feedStatus.status === 1 || feedStatus.status === 2) && tenderPermissions.canPurchaseProducts &&
                                                    <Alert color="danger" style={{ textAlign: "center", textWrap: "balance" }}>
                                                        Purchase this portal for Single Sign On to the portal
                                                    </Alert>
                                                }

                                                <div className="c-tender-actions c-tender-actions--detail-page" style={{ flexWrap: "wrap" }}>
                                                    {feedStatus && Object.keys(feedStatus).length > 0 && tenderid && tenderDetails.organiserID > 0 &&
                                                        <>
                                                            {tenderDetails.organiserID > 0 &&
                                                                <>
                                                                    {tenderDetails.apiUserID === 1 && tenderDetails.organiserFeedCode > 0 &&
                                                                        <>
                                                                            {/* STATUS = 0: Purchase not required */}
                                                                            {feedStatus.status === 0 &&
                                                                                <>
                                                                                    {feedStatus.registerControlStatus > 0 && !feedStatus.registeredWithPortal && (feedStatus.registerControlStatus === 1 || !packagesEnabled) && tenderPermissions.canRegisterPortal &&
                                                                                        <button id='register' className="c-tender-actions__action-button c-tender-actions__action-button--register" style={{ width: "100%" }} onClick={() => { handleRegisterOnPortal(tenderDetails.organiserID, tenderDetails.entityName, user.supplierID, user.contactID) }}>
                                                                                            Register with Organisation
                                                                                        </button>
                                                                                    }
                                                                                    {(tenderDetails.displayEOIDate || feedStatus.expressed) && feedStatus.registeredWithPortal &&
                                                                                        <button className="c-tender-actions__action-button c-tender-actions__action-button--100 c-tender-actions__action-button--express-interest" style={{ width: "100%" }} data-action-enabled={false} onClick={(e) => handleGoToPortal(feedStatus.registeredWithPortal, '', tenderid, tenderDetails.organiserID, tenderDetails.entityName)}>
                                                                                            View in Portal
                                                                                        </button>
                                                                                    }
                                                                                    {!feedStatus.registeredWithPortal && feedStatus.registerControlStatus === 2 &&
                                                                                        <button id='register' className="c-tender-actions__action-button c-tender-actions__action-button--purchase u-component-spacing" disabled={!tenderPermissions.canPurchaseProducts} onClick={(() => goTo('./products'))} >
                                                                                            Purchase More Portals
                                                                                        </button>
                                                                                    }
                                                                                </>
                                                                            }

                                                                            {/* STATUS = 1: Supplier has no allowance, requires portal purchase, BASKET icon */}
                                                                            {feedStatus.status === 1 && tenderPermissions.canPurchaseProducts &&
                                                                                <button className="c-tender-actions__action-button c-tender-actions__action-button--100 c-tender-actions__action-button--add-to-basket" onClick={(() => handleSelectPortal(tenderDetails.organiserID, tenderDetails.entityName, feedStatus.registeredWithPortal))}>
                                                                                    Add to basket
                                                                                </button>
                                                                            }

                                                                            {/* STATUS = 2: Portal already in the basket, BASKET REMOVE icon*/}
                                                                            {feedStatus.status === 2 && tenderPermissions.canPurchaseProducts &&
                                                                                <>
                                                                                    <Button size="sm" color='link' className='c-text-link' onClick={(() => handleRemoveFromBasket(tenderDetails.organiserID))}>
                                                                                        Remove from basket
                                                                                    </Button>

                                                                                    <button id={`removeBasketPortal-${tenderDetails.organiserID}`} className="c-tender-actions__action-button c-tender-actions__action-button--view-basket" style={{ width: "100%" }} onClick={() => history.push(`/basket`)}>
                                                                                        View basket
                                                                                    </button>
                                                                                </>
                                                                            }

                                                                            {/* STATUS = 3: Free account, requires account upgrade */}
                                                                            {feedStatus.status === 3 &&
                                                                                <button className="c-tender-actions__action-button c-tender-actions__action-button--100 c-tender-actions__action-button--express-interest" style={{ width: "100%" }} data-action-enabled={false} onClick={() => history.push(`/upgrade`)}>
                                                                                    View in Portal - Upgrade Required
                                                                                </button>
                                                                            }

                                                                            {/* STATUS = 4: Supplier has available portal allowance, portal allowance to be used - already registered on another Sell2, Link account.  LINK button
                                                                        STATUS = 5: Unlimited balance */}
                                                                            {(feedStatus.status === 4 || feedStatus.status === 5) && feedStatus.registeredWithPortal && tenderPermissions.canRegisterPortal &&
                                                                                <button className="c-tender-actions__action-button c-tender-actions__action-button--link u-component-spacing" onClick={() => { handleSelectPortal(tenderDetails.organiserID, tenderDetails.entityName, feedStatus.registeredWithPortal) }}>
                                                                                    Link Existing Sell2 Registration
                                                                                </button>
                                                                            }

                                                                            {/* STATUS = 4: Supplier has available portal allowance, portal allowance to be used - needs to register.  LINK button
                                                                        STATUS = 5: Unlimited balance */}
                                                                            {(feedStatus.status === 4 || feedStatus.status === 5) && !feedStatus.registeredWithPortal && tenderPermissions.canRegisterPortal &&
                                                                                <button id='register' className="c-tender-actions__action-button c-tender-actions__action-button--100 c-tender-actions__action-button--register u-component-spacing" onClick={() => { handleRegisterOnPortal(tenderDetails.organiserID, tenderDetails.entityName, user.supplierID, user.contactID) }}>
                                                                                    Register with Organisation
                                                                                </button>
                                                                            }

                                                                            {!feedStatus.registeredWithPortal && feedStatus.status !== 3 &&
                                                                                <button className="c-tender-actions__action-button c-tender-actions__action-button--portal-link" style={{ width: "100%" }} onClick={() => { handleGoToPortal(feedStatus.registeredWithPortal, tenderDetails.buyerURL, tenderDetails.organiserID, tenderDetails.entityName) }}>
                                                                                    Go to supplier portal
                                                                                </button>
                                                                            }
                                                                        </>
                                                                    }

                                                                    {tenderDetails.organiserFeedCode < 0 &&
                                                                        <button className="c-tender-actions__action-button c-tender-actions__action-button--100 c-tender-actions__action-button--express-interest"
                                                                            style={{ width: "100%" }} data-action-enabled={false}
                                                                            onClick={(() => showPublicFeedLink === true ? window.open(`${tenderDetails.publicFeedURL}`) : history.push('/upgrade'))}>
                                                                            {showPublicFeedLink === false
                                                                                ? 'View External Notice - Upgrade required'
                                                                                : 'View External Notice'
                                                                            }</button>
                                                                    }

                                                                    {/* Additional Action buttons */}
                                                                    {!feedStatus.expressed &&
                                                                        <div className="c-tender-actions__button-group">
                                                                            <div style={{ flexBasis: "60.5%" }}>
                                                                                <button id={`watch_${tenderid}`} className="c-tender-actions__action-button c-tender-actions__action-button--watch js-action-button" style={{ width: "100%" }} data-action-enabled={feedStatus.watch} onClick={(e) => handleTenderStatus(e, 2, supplierID, tenderid)}>{feedStatus.watch ? 'Stop Watching' : 'Watch'}</button>
                                                                            </div>
                                                                            <div style={{ flexBasis: "39.5%" }} >
                                                                                <button id={`hide_${tenderid}`} className="c-tender-actions__action-button c-tender-actions__action-button--hide-tender js-action-button" style={{ width: "100%" }} data-action-enabled={feedStatus.hide} onClick={(e) => handleTenderStatus(e, 4, supplierID, tenderid)}>{feedStatus.hide ? 'Unhide' : 'Hide'}</button>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }

                                                    {!user.supplierID &&
                                                        <>
                                                            <button className="c-cta" onClick={handleLogInModal}>
                                                                Log in / Register
                                                            </button>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>


                            <div className="l-contract-detail-page u-component-spacing">
                                <section className="c-card card card-opportunity l-contract-detail-page__section u-component-spacing">
                                    <div className="card-header ic-description">
                                        <h3>Description</h3>
                                    </div>
                                    <div className="card-body">
                                        {tenderDetails.isPublicNotice === true && showPublicFeedLink !== null && tenderDetails.publicFeedURL !== "" &&
                                            <p style={{ marginBottom: "2rem" }}>
                                                <Button color='link' className='c-text-link c-page-intro u-component-spacing' onClick={(() => showPublicFeedLink === true ? window.open(`${tenderDetails.publicFeedURL}`) : history.push('/upgrade'))}>
                                                    {showPublicFeedLink === false
                                                        ? <u>View the Public Notice on the relevant external website - <i>(Upgrade required)</i></u>
                                                        : <><u>View the Public Notice on the relevant external website</u> <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginRight: '20px' }} /></>}
                                                </Button>
                                            </p>
                                        }
                                        {tenderDetails.isPublicNotice === false && feedStatus.status === 0 && (tenderDetails.displayEOIDate || feedStatus.expressed) && feedStatus.registeredWithPortal &&
                                            <p style={{ marginBottom: "2rem" }}>
                                                <Button color='link' className='c-text-link c-page-intro u-component-spacing'
                                                    onClick={((e) => handleGoToPortal(feedStatus.registeredWithPortal, '', tenderid, tenderDetails.organiserID, tenderDetails.entityName))}>
                                                    <u>View the tender in the In-tend Portal</u> <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginRight: '20px' }} />
                                                </Button>
                                            </p>
                                        }
                                        <p className="u-container--no-padding" dangerouslySetInnerHTML={{ __html: tenderDetails.tenderDescription }} />
                                    </div>
                                </section>

                                <section className="c-card card card-opportunity l-contract-detail-page__section l-contract-detail-page__section--summary u-component-spacing">
                                    <div className="card-header ic-summary">
                                        <h3>Contract Summary</h3>
                                    </div>
                                    <Card body>
                                        <dl className="c-contract-details">
                                            {tenderDetails.entityName &&
                                                <>
                                                    <dt className="c-contract-details__term">Contracting Authority:</dt>
                                                    <dd className="c-contract-details__description" dangerouslySetInnerHTML={{ __html: tenderDetails.entityName }} />
                                                </>
                                            }
                                            {tenderDetails.title &&
                                                <>
                                                    <dt className="c-contract-details__term">Title:</dt>
                                                    <dd className="c-contract-details__description">{tenderDetails.title}</dd>
                                                </>
                                            }
                                            <dt className="c-contract-details__term">Value:</dt>
                                            <dd className="c-contract-details__description">
                                                {
                                                    tenderDetails.valueTo > 0 ?
                                                        `${formatter.format(tenderDetails.valueFrom)} to ${formatter.format(tenderDetails.valueTo)}`
                                                        : 'Not Specified'
                                                }
                                            </dd>
                                            {tenderDetails.referenceNumber &&
                                                <>
                                                    <dt className="c-contract-details__term">Reference:</dt>
                                                    <dd className="c-contract-details__description">{tenderDetails.referenceNumber}</dd>
                                                </>
                                            }
                                            {tenderDetails.contractLink &&
                                                <>
                                                    <dt className="c-contract-details__term">Link to Contract:</dt>
                                                    <dd className="c-contract-details__description">
                                                        <a href={tenderDetails.contractLink} target="_blank" rel='noreferrer'>{tenderDetails.contractLink}</a>
                                                    </dd>
                                                </>
                                            }
                                            {tenderDetails.portalLink &&
                                                <>
                                                    <dt className="c-contract-details__term">To express interest:</dt>
                                                    <dd className="c-contract-details__description">
                                                        <a href={tenderDetails.portalLink} target="_blank" rel='noreferrer'>{tenderDetails.portalLink}</a>
                                                    </dd>
                                                </>
                                            }
                                        </dl>


                                        <div className="u-flex-center u-component-spacing--2x">
                                            <h4 className="u-branded-underline">Key Dates</h4>
                                        </div>

                                        <dl className="c-contract-details u-component-spacing">
                                            {moment(tenderDetails.dateAdded).isValid() && moment(tenderDetails.dateAdded).isAfter('01-01-1970') &&
                                                <>
                                                    <dt className="c-contract-details__term">Date Added:</dt>
                                                    <dd className="c-contract-details__description">{moment(tenderDetails.dateAdded).isValid() && moment(tenderDetails.dateAdded).isAfter('01-01-1970') ? moment(tenderDetails.dateAdded).format('Do MMMM YYYY') : ''}</dd>
                                                </>
                                            }
                                            {moment(tenderDetails.docsAvailableUntil).isValid() && moment(tenderDetails.docsAvailableUntil).isAfter('01-01-1970') &&
                                                <>
                                                    <dt className="c-contract-details__term">Application Deadline:</dt>
                                                    <dd className="c-contract-details__description">{moment(tenderDetails.docsAvailableUntil).isValid() && moment(tenderDetails.docsAvailableUntil).isAfter('01-01-1970') ? moment(tenderDetails.docsAvailableUntil).format('Do MMMM YYYY') : ''}</dd>
                                                </>
                                            }
                                            {moment(tenderDetails.openDate).isValid() && moment(tenderDetails.openDate).isAfter('01-01-1970') &&
                                                <>
                                                    <dt className="c-contract-details__term">Start Date:</dt>
                                                    <dd className="c-contract-details__description">{moment(tenderDetails.openDate).isValid() && moment(tenderDetails.openDate).isAfter('01-01-1970') ? moment(tenderDetails.openDate).format('Do MMMM YYYY') : ''}</dd>
                                                </>
                                            }
                                            {moment(tenderDetails.closeDate).isValid() && moment(tenderDetails.closeDate).isAfter('01-01-1970') &&
                                                <>
                                                    <dt className="c-contract-details__term">End Date:</dt>
                                                    <dd className="c-contract-details__description">{moment(tenderDetails.closeDate).isValid() && moment(tenderDetails.closeDate).isAfter('01-01-1970') ? moment(tenderDetails.closeDate).format('Do MMMM YYYY') : ''}</dd>
                                                </>
                                            }
                                            {moment(tenderDetails.openDate).isAfter('01-01-1970') &&
                                                <>
                                                    <dt className="c-contract-details__term">Duration:</dt>
                                                    <dd className="c-contract-details__description">{moment(tenderDetails.openDate).isAfter('01-01-1970') ? getDuration(tenderDetails.openDate, tenderDetails.closeDate) : ''}</dd>
                                                </>
                                            }
                                        </dl>

                                        {(tenderDetails.deliveryLocation || tenderDetails.contractLocation) &&
                                            <>
                                                <div className="u-flex-center u-component-spacing--2x">
                                                    <h4 className="u-branded-underline">Contract Location</h4>
                                                </div>

                                                <dl className="c-contract-details u-component-spacing">
                                                    {tenderDetails.deliveryLocation &&
                                                        <>
                                                            <dt className="c-contract-details__term">Delivery Location:</dt>
                                                            <dd className="c-contract-details__description">{tenderDetails.deliveryLocation}</dd>
                                                        </>
                                                    }
                                                    {tenderDetails.contractLocation &&
                                                        <>
                                                            <dt className="c-contract-details__term">Location of Contract:</dt>
                                                            <dd className="c-contract-details__description">{tenderDetails.contractLocation}</dd>
                                                        </>
                                                    }
                                                </dl>
                                            </>
                                        }

                                        {tenderDetails.tenderContactName &&
                                            <>
                                                <div className="u-flex-center u-component-spacing--2x">
                                                    <h4 className="u-branded-underline">Buyer Information</h4>
                                                </div>
                                                <dl className="c-contract-details u-component-spacing">
                                                    {tenderDetails.tenderContactName &&
                                                        <>
                                                            <dt className="c-contract-details__term">Contact name:</dt>
                                                            <dd className="c-contract-details__description">{tenderDetails.tenderContactName}</dd>
                                                        </>
                                                    }
                                                    {(tenderDetails.address1 || tenderDetails.address2 || tenderDetails.address3 || tenderDetails.address4 || tenderDetails.postCode || tenderDetails.country) &&
                                                        <>
                                                            <dt className="c-contract-details__term">Address:</dt>
                                                            <dd className="c-contract-details__description">
                                                                <div className="c-address">
                                                                    {tenderDetails.address1 &&
                                                                        <span className="c-address__address-line">{tenderDetails.address1}</span>
                                                                    }
                                                                    {tenderDetails.address2 &&
                                                                        <span className="c-address__address-line">{tenderDetails.address2}</span>
                                                                    }
                                                                    {tenderDetails.address3 &&
                                                                        <span className="c-address__address-line">{tenderDetails.address3}</span>
                                                                    }
                                                                    {tenderDetails.address4 &&
                                                                        <span className="c-address__address-line">{tenderDetails.address4}</span>
                                                                    }
                                                                    {tenderDetails.postCode &&
                                                                        <span className="c-address__address-line">{tenderDetails.postCode}</span>
                                                                    }
                                                                    {tenderDetails.country &&
                                                                        <span className="c-address__address-line">{tenderDetails.country}</span>
                                                                    }
                                                                </div>
                                                            </dd>
                                                        </>
                                                    }
                                                    {tenderDetails.tenderContactTelephone &&
                                                        <>
                                                            <dt className="c-contract-details__term c-contract-details__term--full-width">Telephone:</dt>
                                                            <dd className="c-contract-details__description c-contract-details__description--full-width">{tenderDetails.tenderContactTelephone}</dd>
                                                        </>
                                                    }
                                                    {tenderDetails.tenderContactEmail &&
                                                        <>
                                                            <dt className="c-contract-details__term c-contract-details__term--full-width">Email:</dt>
                                                            <dd className="c-contract-details__description c-contract-details__description--full-width">{tenderDetails.tenderContactEmail}</dd>
                                                        </>
                                                    }
                                                </dl>
                                            </>
                                        }
                                        {tenderDetails.sell2BusinessClassifications.length > 0 &&
                                            <>
                                                <div className="u-flex-center u-component-spacing--2x">
                                                    <h4 className="u-branded-underline">Business Classifications</h4>
                                            </div>
                                            <table id='CPVTable' className='table u-component-spacing--2x' style={{ border: ".0625rem solid #ccc" }}>
                                                    <thead style={{ position: "sticky", top: "0", background: "var(--card-bg-color)" }}>
                                                        <tr>
                                                            <th style={{ borderBottom: "0", borderTop: "0", fontWeight: "700", padding: "0", width: "15%" }}><span style={{ display: "block", padding: "0.5rem 0.75rem", borderBottom: "0.0625rem solid var(--border-color)" }} >Code</span></th>
                                                            <th style={{ borderBottom: "0", borderTop: "0", fontWeight: "700", padding: "0", width: "85%" }}><span style={{ display: "block", padding: "0.5rem 0.75rem", borderBottom: "0.0625rem solid var(--border-color)" }} >Description</span></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tenderDetails.sell2BusinessClassifications.map((classification, i) => {
                                                            return (
                                                                <tr key={i} className={i % 2 === 0 ? "odd" : "even"}>
                                                                    <td style={{ padding: "0.75rem 0.75rem" }}>
                                                                        {classification.code}
                                                                    </td>
                                                                    <td style={{ padding: "0.75rem 0.75rem" }}>
                                                                        <Translation ID={classification.description} />
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </>
                                        }
                                        {tenderDetails.cpvCodes.length > 0 &&
                                            <>
                                                <div className="u-flex-center u-component-spacing--2x">
                                                    <h4 className="u-branded-underline">CPV Codes</h4>
                                                </div>
                                                <table id='BusinessClassificationsTable' className='table u-component-spacing--2x' style={{ border: ".0625rem solid #ccc" }}>
                                                    <thead style={{ position: "sticky", top: "0", background: "var(--card-bg-color)" }}>
                                                        <tr>
                                                            <th style={{ borderBottom: "0", borderTop: "0", fontWeight: "700", padding: "0", width: "15%" }}><span style={{ display: "block", padding: "0.5rem 0.75rem", borderBottom: "0.0625rem solid var(--border-color)" }} >Code</span></th>
                                                            <th style={{ borderBottom: "0", borderTop: "0", fontWeight: "700", padding: "0", width: "85%" }}><span style={{ display: "block", padding: "0.5rem 0.75rem", borderBottom: "0.0625rem solid var(--border-color)" }} >Description</span></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {tenderDetails.cpvCodes.map((cpv, i) => {
                                                            return (
                                                                <tr key={i} className={i % 2 === 0 ? "odd" : "even"}>
                                                                    <td style={{ padding: "0.75rem 0.75rem" }}>
                                                                        {cpv.code}
                                                                    </td>
                                                                    <td style={{ padding: "0.75rem 0.75rem" }}>
                                                                        <Translation ID={cpv.description} />
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </>
                                        }
                                    </Card>
                                </section>

                                {tenderDetails.additionalText &&
                                    <section className="l-contract-detail-page__section">
                                        <h2 className="c-broken-underline u-component-spacing--3x">Additional Information</h2>
                                        <p className="u-container--no-padding u-container--small u-component-spacing--2x">{tenderDetails.additionalText}</p>
                                    </section>
                                }
                            </div>
                        </Card>
                    </div>
                </main>

                <LoginModal
                    id={'contractDetails_modal'}
                    isOpen={showModal}
                    toggle={toggle}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    tenderID={tenderid}
                />
                {loggedIn === false &&
                    <Footer />
                }
            </>
        );
    } else {
        return null;
    }
}

export default TenderDetails;
