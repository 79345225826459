import React, { useEffect, useRef, useState } from 'react';
import { getLookupData, getResourceString, getTableLookupData, getUseAsDefault } from '../../helpers/general';
import { authHeader } from '../../helpers/auth-header';
import 'select2/dist/css/select2.min.css';
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css';
import { useCallback } from 'react';

const $ = require('jquery');
require('select2');

const Select2 = (props) => {
    let { id, table, fields, where, order, dataList, change, showEmpty, disabled, defaultValue, emptyText, tableLookup,
        allowAnonymous = false, required = false } = props;
    let supplierID = -1;
    const select2 = useRef();

    const [opt, setOptions] = useState([]);

    useEffect(() => {
        let lookup = '';
        if (tableLookup) {
            lookup = tableLookup.split('|')[0];
        }

        if (tableLookup) { // Should we be loading values based on a DataTable?
            let keyfield = tableLookup.split('|')[1];
            let primaryKey = tableLookup.split('|')[2];

            getTableLookupData(table, lookup, keyfield, primaryKey, fields, supplierID, showEmpty, emptyText).then(vData => {
                setOptions(vData);
            });
        } else if (table) { // Are we getting the data from a lookup table?
            getLookupData(table, fields, where, order, supplierID, showEmpty, emptyText).then(vData => {
                setOptions(vData);
            });
        } else if (dataList) { // Have we manually specified the data to use?
            Promise.all(
                dataList.map(async (opt) => {
                    return {
                        label: await getResourceString(opt.label),
                        value: opt.value
                    }
                })
            ).then(vData => {
                setOptions(vData);
            });
        } else if (props.options) {
            setOptions(props.options);
        } else { // Check the MasterDB for any lookup data
            let vTable = id.split('.')[0];
            let vField = id.split('.')[1];

            fetch(`./Data/GetLookupOptions?tableName=${vTable}&fieldName=${vField}`, {
                method: 'GET',
                headers: authHeader(false, allowAnonymous)
            })
                .then(response => response.json())
                .then(data => {
                    if (data && data.length > 0) {
                        let vData = data.map(opt => {
                            return { label: opt.key, value: opt.value }
                        });

                        setOptions(vData);
                    }
                })
                .catch(err => console.log(err));
        }

        return () => {
            setOptions([]);
        }
    }, [allowAnonymous, dataList, emptyText, fields, id, order, props.options, showEmpty, supplierID, table, tableLookup, where]);

    const getSelected = useCallback(async () => {
        let val = '';

        if (defaultValue && defaultValue.value) {
            val = defaultValue.value;
        } else if (defaultValue) {
            val = defaultValue;
        } else {
            if (table) {
                val = await getUseAsDefault(table, where);
            }
        }

        // If we still equal NOTHING then set the data to the first value in the list
        if (val === '') {
            val = opt[0].value;
        }

        let vExists = false;

        // Is the value actually in the list of options?
        opt.forEach(option => {
            if (val === option.value) {
                vExists = true;
                return;
            }
        });

        // If not, assign the first option value as the default
        if (vExists === false) {
            val = opt[0].value;
        }

        // If we have a value, trigger the change
        if (val !== '' && val !== '-1') {
            $(select2.current).val(val).trigger('change');
        }
    },[defaultValue, opt, table, where])

    useEffect(() => {
        if (opt && opt.length > 0) {
            getSelected();
        }
    }, [getSelected, opt]);

        return (
            <select
                id={id}
                name={id}
                ref={select2}
                className="c-form__dropdown form-control"
                disabled={disabled}
                onChange={change}
                required={required}
            >
                {
                    opt.map((option, i) => {
                        return <option key={i} value={option.value}>{option.label}</option>
                    })
                }
            </select>
        );
}

export default Select2;