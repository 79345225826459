import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tenderActions } from '../../actions/tenders.actions';
import { getSessionVal, setSessionVal } from '../../helpers/general';
import Translation from '../controls/Translation';
import SpinnerOverlay from '../layout/SpinnerOverlay';
import TenderList from './TenderList';


const TenderSearchResults = props => {
    let { searchParams, isSearching, loggedIn, setIsSearching, firstLoad, showOrganisationTenders, layoutViewMode, pageNumber, setPageNumber, hideFeed = false } = props;

    const dispatch = useDispatch();
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        setPageNumber(parseInt(getSessionVal('TendersPageNumber') ?? 1));
    }, [setPageNumber]);

    useEffect(() => {
        if (isSearching && searchParams && searchParams.searchObject && Object.keys(searchParams.searchObject).length !== 0) {
            setSessionVal('TendersPageNumber', pageNumber); // Store the new pageNumber
            dispatch(tenderActions.searchTenders(searchParams.searchObject, false, pageNumber, loggedIn)).then(data => {
                if (data.length) {
                    let vTotal = data[0].totalCount;
                    let vPages = vTotal / 20;
                    vPages = Math.ceil(vPages);
                    setTotalPages(vPages);
                }
            });
            setIsSearching(false);
        }
    }, [dispatch, loggedIn, pageNumber, setIsSearching, isSearching, firstLoad, searchParams]);

    const { tenders } = useSelector(state => state);


    const handleViewChange = (activeClass, button, resultsList) => {
        // Removes the Active class from who ever has it
        const activeButton = document.querySelector('.' + activeClass);
        if (activeButton) {
            activeButton.classList.remove(activeClass);
        }

        button.classList.add(activeClass);

        // Results list, not the buttons
        resultsList.dataset.viewMode = button.dataset.viewMode;
        setSessionVal(layoutViewMode, button.dataset.viewMode);
    }

    useEffect(() => {
        const resultsList = document.querySelector('.js-results-list');
        const viewChangerButtons = document.querySelectorAll('.js-view-changer');
        const activeClass = 'c-view-changer__button--active';
        const viewMode = getSessionVal(layoutViewMode) ?? 'list';

        Array.from(viewChangerButtons).forEach(button => {
            if (button.dataset.viewMode === viewMode) {
                button.classList.add(activeClass);
                resultsList.dataset.viewMode = viewMode;
            }

            button.addEventListener('click', () => { handleViewChange(activeClass, button, resultsList) });

            return function cleanup() {
                button.removeEventListener('click', handleViewChange(activeClass, button, resultsList));
            }
        });
    });

    const disableNextPrevious = (vIsNext) => {
        if (vIsNext) {
            return pageNumber >= totalPages;
        } else {
            return pageNumber <= 1; // Are we on the first page - if so, disabled the previous button
        }
    }

    const handleNextPrevious = (vIsNext) => {
        if (vIsNext) {
            setPageNumber(prevPage => prevPage + 1);
        } else {
            setPageNumber(prevPage => prevPage - 1);
        }

        setIsSearching(true);
        window.scrollTo(0, 0);
    }

    if (searchParams && searchParams.searchObject && Object.keys(searchParams.searchObject).length !== 0) {
        return (
            <main>
                {!tenders.isLoading &&
                    <>
                        <div className="main-content u-container">
                        {tenders.tenders.length > 0 && !showOrganisationTenders &&
                                <div className="c-list-header">
                                    <div className="c-list-header__content">
                                        <h2 className="c-page-title u-component-spacing--2x">Search results'</h2>
                                        <p>
                                            {
                                                tenders.tenders.length > 0 ? `${tenders.tenders[0].totalCount} results found` : ''
                                            }
                                            {
                                                tenders.tenders.length > 0 ? ` - Showing ${(pageNumber - 1) * 20 + 1} to ${(pageNumber * 20) >= tenders.tenders[0].totalCount ? tenders.tenders[0].totalCount : pageNumber * 20}` : ''
                                            }
                                        </p>
                                    </div>
                                </div>
                            }

                            <div className="u-component-spacing">
                                <div className="c-list-pagination">
                                    <button className="c-list-pagination__button c-list-pagination__button--previous" onClick={() => handleNextPrevious(false)} hidden={disableNextPrevious(false)}>
                                        <Translation ID="Previous" />
                                    </button>
                                    <button className="c-list-pagination__button c-list-pagination__button--next" onClick={() => handleNextPrevious(true)} hidden={disableNextPrevious(true)}>
                                        <Translation ID="Next" />
                                    </button>
                                </div>

                                <TenderList
                                    tenders={tenders}
                                    searchParams={searchParams}
                                    loggedIn={loggedIn}
                                    showOrganisationTenders={showOrganisationTenders}
                                    hideFeed={hideFeed }
                                />

                                <div className="c-list-pagination">
                                    <button className="c-list-pagination__button c-list-pagination__button--previous" onClick={() => handleNextPrevious(false)} hidden={disableNextPrevious(false)}>Previous</button>
                                    <button className="c-list-pagination__button c-list-pagination__button--next" onClick={() => handleNextPrevious(true)} hidden={disableNextPrevious(true)}>Next</button>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {tenders.isLoading &&
                    <SpinnerOverlay />
                }
            </main>
        );
    } else {
        return null;
    }
}

export default TenderSearchResults;