import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useState } from "react";
import { authHeader } from "../../helpers/auth-header";
import { getApplicationType, getContactID, getSupplierID, handleBackground, pageEnabled, showCustomError } from "../../helpers/general";
import ContractFilters from "../Contracts/ContracFilters";
import ContractSearchResults from "../Contracts/ContractSearchResults";
import Footer from "../layout/Footer";
import NavMenu from "../layout/NavMenu";
import SpinnerOverlay from '../layout/SpinnerOverlay';

require('datatables.net-bs4');

const moment = require('moment');

const Contracts = () => {
    const [user, setUser] = useState({ supplierID: -1, contactID: -1 });
    const [isSearching, setIsSearching] = useState(false);
    const [finalSearchParams, setFinalSearchParams] = useState({ searchObject: { systemName: '', includeArchived: 0 } });
    const [isPageEnabled, setIsPageEnabled] = useState(false);
    const [systemName, setSystemName] = useState('');
    const [isExporting, setIsExporting] = useState(false);

    useEffect(() => {
        if (user.supplierID !== -1) {
            pageEnabled(user.supplierID > 0 ? 'Nav_Contracts' : 'Nav_PublicContracts', '/404').then(enabled => { setIsPageEnabled(enabled); });
        }
    }, [user.supplierID]);

    useEffect(() => {
        setUser(prev => ({
            ...prev,
            supplierID: getSupplierID(),
            contactID: getContactID()
        }))

        getApplicationType()
            .then(application => {
                setSystemName(application);
            });
    }, []);

    useEffect(() => {
        setFinalSearchParams({
            searchObject: {
                includeArchived: 0,
                title: '',
                reference: '',
                organisation: 0,
                customer: '',
                dateExpiryFrom: '',
                dateExpiryTo: '',
                systemName: systemName
            }
        })
    }, [systemName]);


    useEffect(() => {
        handleBackground();
    }, [user.supplierID])


    const exportData = () => {
        setIsExporting(true);

        fetch(`./Contracts/ExportContracts?filter=${JSON.stringify(finalSearchParams.searchObject)}`, {
            method: 'GET',
            headers: authHeader(false, true)
        })
            .then(response => response.blob())
            .then(data => {
                const a = document.createElement('a');
                a.download = `ContractExport_${new moment().format('DDMMYYYY_hhmm').toString()}.xlsx`;
                a.href = URL.createObjectURL(data);
                a.addEventListener('click', (e) => {
                    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
                });
                a.click();

                setIsExporting(false);
            })
            .catch(err => {
                console.log(err);
                setIsExporting(false);
                showCustomError('Something went wrong...', 'Oops', 'error');
            });
    }

    if (isPageEnabled === true) {
        return (
            <>
                <NavMenu />

                <ContractFilters
                    setFinalSearchParams={setFinalSearchParams}
                    exportData={exportData}
                    systemName={systemName}
                    user={user}
                    setIsSearching={setIsSearching}
                />

                <ContractSearchResults
                    finalSearchParams={finalSearchParams}
                    isPageEnabled={isPageEnabled}
                    systemName={systemName}
                    isSearching={isSearching }
                />

                <Footer />

                {isExporting &&
                    <SpinnerOverlay />
                }
            </>
        );
    } else {
        return null;
    }
}

export default Contracts;