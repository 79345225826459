import { faPlus, faShareSquare, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, Card, CardBody, CardHeader, UncontrolledTooltip } from 'reactstrap';
import { handleConfirm, sendNewUserEmail, showCustomError } from '../../helpers/general';
import { history } from '../../helpers/history';
import DataTable from '../controls/DataTable';
import Translation from '../controls/Translation';
import NewSupplierUser from '../Suppliers/NewSupplierUser';

require('datatables.net-bs4');

const CompanyUsers = props => {
    const { supplierID, PanelName, TableName, CardClass, userPermissions } = props;
    const [finalSearchString, setFinalSearchString] = useState('');
    const [showModal, setShowModal] = useState(false);
    const toggle = () => setShowModal(!showModal);

    const handleExpandClick = () => {
        toggle();
    }
    useEffect(() => {
        setFinalSearchString(`supplierID=${supplierID}`)
    }, [supplierID]);

    const handleResendEmail = (userID, email) => {
        if (userID && userID !== "") {
            handleConfirm(
                `Resend the new account email to '${email}'?`,
                function () {
                    sendNewUserEmail(email)
                        .then(response => {
                            if (response) {
                                showCustomError(`<p>The New User email has been resent to <strong>${email}</strong> to reset the password.</p><p>Please follow instructions to reset the password to log in.</p>`, 'New User Email Sent', 'success', 600);
                            }
                            else {
                                showCustomError('There has been a problem sending the email - if the account has been created, please ask the user to use FORGOTTEN PASSWORD on the homepage', 'Problem with the email address', 'Error')
                            }
                        });
                }
            );
        };
    }

    const IsAdminTooltip = React.forwardRef((props, ref) => {
        return (
            <>
                <FontAwesomeIcon forwardedRef={ref} icon={faStar} />
                <UncontrolledTooltip placement="right" defaultOpen={false} target={ref}>
                    Administrator Account
                </UncontrolledTooltip>

            </>
        )
    });

    const columns = [
        {
            name: "AdminUser",
            header: " ",
            data: (data) => { return data.IsAdmin },
            createdCell: (td, data) => {
                let ref = React.createRef();
                ReactDOM.render(
                    data === true ? <>
                        <IsAdminTooltip ref={ref} />
                    </> : null, td)
            },
            width: "2%"
        }, {
            name: "FullName",
            header: "Name",
            data: (data) => {
                return data.ContactID + '|' + data.FullName + '|' + data.HasLoggedIn
            },
            createdCell: (td, data) => {
                ReactDOM.render(
                    <a
                        href={`/${data.split('|')[1]}`}
                        onClick={(e) => { e.preventDefault(); history.push(`/user?uid=${data.split('|')[0]}`) }}
                        style={{ color: '#00A65A' }}>
                        {data.split('|')[1]} {data.split('|')[2] === '0' && <i> [pending]</i>}
                    </a>, td
                )
            },
            width: "65%"
        },
        {
            name: "ResendEmail",
            header: " ",
            data: (data) => {
                return data.HasLoggedIn + '|' + data.Email
            },
            createdCell: (td, data) => {
                ReactDOM.render(
                    data.split('|')[0] === '0' ? <Button size="sm" color="link" className="text-red" onClick={() => handleResendEmail(data.split('|')[0], data.split('|')[1])}>
                        <span>Resend Email</span><FontAwesomeIcon icon={faShareSquare} />
                    </Button > : null, td)
            },
            width: "30%"
        }
    ];

    if (finalSearchString.length) {
    return (
        <>
            <Card className={CardClass} >
                <CardHeader className="ic-users">
                    <span>
                        <Translation ID={PanelName} />
                    </span>
                    {userPermissions.canCreateUsers &&
                        <span style={{ justifyContent: 'right' }}>
                            <Button id='addUser' className="c-btn" onClick={(() => handleExpandClick())}>
                                <FontAwesomeIcon icon={faPlus} />
                                Add User
                            </Button>
                        </span>
                    }
                </CardHeader>
                <CardBody className='padding-5-1 card-module'>
                    <DataTable
                        id={TableName}
                        columns={columns}
                        sorting={false}
                        paging={false}
                        info={false}
                        defaultOrder={[1, "asc"]}
                        url="./Suppliers/GetSupplierContacts"
                        params={finalSearchString}
                        isSearching={true}
                        scrollY='550px'
                        pagingType='full'
                        scrollCollapse={true}
                    />
                </CardBody>
            </Card>

            <NewSupplierUser
                id='newSupplierUser_Modal'
                supplierID={supplierID}
                isOpen={showModal}
                toggle={toggle}
                cardClass={CardClass}
                panelName={PanelName}
                contactTable={TableName}
            />
        </>
    )

    } else {
        return null;
    }
}

export default CompanyUsers;