import React from 'react';


const EmptyTableRow = props => {
    const { colspan = "3", emptyDescription = "No data available in table" } = props;
    return (
        <tr className="odd">
            <td valign="top" colSpan={colspan} style={{ textAlign: "center" }}>
                {emptyDescription}
            </td>
        </tr>
    )
}

export default EmptyTableRow;
