import { authHeader } from '../helpers/auth-header';

export const TENDER_REQUEST = 'TENDER_REQUEST'
export const TENDER_SUCCESS = 'TENDER_SUCCESS'
export const TENDER_FAILURE = 'TENDER_FAILURE'

export const tenderActions = {
    searchTenders
};

function request() { return { type: TENDER_REQUEST }; }
function success(tenders) { return { type: TENDER_SUCCESS, tenders }; }
function failure(error) { return { type: TENDER_FAILURE, error }; }

function searchTenders(search, showAll, page, loggedIn) {
    return dispatch => {
        dispatch(request());
        if (!loggedIn) {
            return new Promise((resolve, reject) => {
                fetch(`./Tenders/PublicSearchTenders?strSearch=${JSON.stringify(search)}&iPage=${page}`, {
                    method: 'GET',
                    headers: authHeader(false, true)
                })
                    .then(response => response.json())
                    .then(data => {
                        setTimeout(() => {
                            dispatch(success(data));
                            resolve(data);
                        }, 1000);
                    }).catch(err => {
                        console.log("Error: ", err);
                        dispatch(failure(err));
                        reject(err);
                    })
            });
        } else {
            return new Promise((resolve, reject) => {
                fetch(`./Tenders/SearchTenders?strSearch=${JSON.stringify(search)}&bShowAll=${showAll}&iPage=${page}`, {
                    method: 'GET',
                    headers: authHeader()
                })
                    .then(response => response.json())
                    .then(data => {
                        setTimeout(() => {
                            dispatch(success(data));
                            resolve(data);
                        }, 1000);
                    }).catch(err => {
                        console.log("Error: ", err);
                        dispatch(failure(err));
                        reject(err);
                    })
            });
        }
    }
}