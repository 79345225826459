import React from 'react';
import { FormGroup, FormFeedback, FormText } from "reactstrap";
import Select2 from '../controls/Select2';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import { handleMultipleDuplicateCheck, handleRequiredCheck, checkEmailIsValid, handleEmailCheck, handleRequiredCheckAlt } from '../../helpers/general';
import Translation from '../controls/Translation';

require('datatables.net-bs4');

const CompanyDetails = props => {
    let { regDetails, setRegDetails, packages, countries, handleNextClick } = props

    function handleChange(e) {
        e.persist();
        setRegDetails(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }

    const handleSelect2Change = e => {
        e.persist();
        setRegDetails(prevState => ({
            ...prevState,
            [e.target.id]: e.target.value
        }));

        if (e.target.id === 'addressCountry') {
            var selectedValue = e.target.value === '-1' ? '' : e.target.value;
            handleRequiredCheckAlt(e.target.id, selectedValue, 'VALIDATION_MandatoryCountry');
        }
    }

    const handleEmailOnBlur = e => {
        handleEmailCheck(e.target.id, e.target.value, 'VALIDATION_MandatoryEmail', 'VALIDATION_EmailComplexity', 'Registration', 'PublishEmail', 'REG_VALIDATION_PublishEmailExists', 'Suppliers', 'VALIDATION_PublishEmailExists', -1, regDetails.tempSupplierID);
    }

    const handleCompanyNameOnBlur = e => {
        if (handleRequiredCheck(e, 'VALIDATION_MandatoryCompanyName')) {
            handleMultipleDuplicateCheck(e, 'Registration', 'Name', 'Suppliers', 'VALIDATION_CompanyNameExists', regDetails.tempSupplierID)
        }
    }

    return (
        <section className="c-multi-step-form__panel" inert={"true".toString()}>
            <div className="c-multi-step-form__panel-content card-body">
                <h3 className="c-broken-underline u-component-spacing">Company Details</h3>

                <div className="c-form c-form--two-column u-component-spacing--2x">
                    <div className="c-form__input-group">
                        <Translation ID="CompanyName" required htmlFor='companyName' />
                        <FormGroup className="position-relative">
                            <input name="companyName" id="companyName" type="text" className='c-form__input' placehold="e.g. In-tend Ltd"
                                defaultValue={regDetails.companyName} required
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => handleCompanyNameOnBlur(e)}
                            />
                            <FormFeedback>
                                <span id="companyName_LABEL"></span>
                            </FormFeedback>
                        </FormGroup>
                    </div>

                    <div className="c-form__input-group">
                        <Translation ID="BuildingName" required htmlFor='addressLine1' />
                        <FormGroup className="position-relative">
                            <input name="addressLine1" id="addressLine1" type="text" className='c-form__input' placehold="e.g. In-tend House"
                                defaultValue={regDetails.addressLine1} required
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => handleRequiredCheck(e, 'VALIDATION_MandatoryBuilding')}
                            />
                            <FormFeedback>
                                <span id="addressLine1_LABEL"></span>
                            </FormFeedback>
                        </FormGroup>
                    </div>

                    <div className='c-form__input-group'>
                        <Translation ID="Street" required htmlFor='addressLine2' />
                        <FormGroup className="position-relative">
                            <input name="addressLine2" id="addressLine2" type="text" className='c-form__input' placehold="e.g. 74 High Street"
                                defaultValue={regDetails.addressLine2} required
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => handleRequiredCheck(e, 'VALIDATION_MandatoryStreet')}
                            />
                            <FormFeedback>
                                <span id="addressLine2_LABEL"></span>
                            </FormFeedback>
                        </FormGroup>
                    </div>

                    <div className='c-form__input-group'>
                        <Translation ID="TownCity" required htmlFor='addressCity' />
                        <FormGroup className="position-relative">
                            <input name="addressCity" id="addressCity" type="text" className='c-form__input' placehold="e.g. Rotherham"
                                defaultValue={regDetails.addressCity} required
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => handleRequiredCheck(e, 'VALIDATION_MandatoryTown')}
                            />
                            <FormFeedback>
                                <span id="addressCity_LABEL"></span>
                            </FormFeedback>
                        </FormGroup>
                    </div>

                    <div className="c-form__input-group">
                        <Translation ID="CountyState" htmlFor='addressCounty' />
                        <input name="addressCounty" id="addressCounty" type="text" className='c-form__input' placehold="e.g. South Yorkshire"
                            defaultValue={regDetails.addressCounty}
                            onChange={(e) => handleChange(e)} />
                    </div>

                    {countries && Object.keys(countries).length !== 0 &&
                        <div className="c-form__input-group">
                            <Translation ID="Country" required htmlFor='addressCountry' />
                            <FormGroup className="position-relative">
                                <Select2
                                    id='addressCountry'
                                    options={countries}
                                    change={(e) => handleSelect2Change(e)}
                                    defaultValue={regDetails.addressCountry}
                                    required
                                />
                                <FormFeedback>
                                    <span id="addressCountry_LABEL"></span>
                                </FormFeedback>
                            </FormGroup>
                        </div>
                    }

                    <div className="c-form__input-group">
                        <Translation ID="Postcode" required htmlFor='addressPostCode' />
                        <FormGroup className="position-relative">
                            <input name="addressPostCode" id="addressPostCode" type="text" className='c-form__input' placehold="e.g. S66 7BN"
                                defaultValue={regDetails.addressPostCode} required
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => handleRequiredCheck(e, 'VALIDATION_MandatoryPostcode')}
                            />
                            <FormFeedback>
                                <span id="addressPostCode_LABEL"></span>
                            </FormFeedback>
                        </FormGroup>
                    </div>

                    <div className="c-form__input-group">
                        <Translation ID="CompanyRefType" htmlFor='companyReferenceType' />
                        <Select2
                            id='companyReferenceType'
                            table={'CompanyReferenceType'}
                            fields={'UniqueID,ReferenceType'}
                            allowAnonymous={true}
                            change={(e) => handleSelect2Change(e)}
                            defaultValue={regDetails.companyReferenceType ? regDetails.companyReferenceType : '2'}
                        />
                    </div>

                    <div className="c-form__input-group">
                        <Translation ID="CompanyRegNo" htmlFor='companyRegNo' />
                        <FormGroup className="position-relative">
                            <input name="companyRegNo" id="companyRegNo" type="text" className='c-form__input' placehold="e.g. 12345678"
                                autoComplete="off" defaultValue={regDetails.companyRegNo}
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => handleMultipleDuplicateCheck(e, 'Registration', 'CompanyRegNo', 'Suppliers', 'VALIDATION_CompanyRegExists', regDetails.tempSupplierID)} />
                            <FormFeedback>
                                <span id="companyRegNo_LABEL"></span>
                            </FormFeedback>
                        </FormGroup>
                    </div>

                    <div className="c-form__input-group">
                        <Translation ID="VATRegNo" htmlFor='vatRegNo' />
                        <FormGroup className="position-relative">
                            <input name="vatRegNo" id="vatRegNo" type="text" className='c-form__input' placehold="e.g. GB12345678"
                                defaultValue={regDetails.vatRegNo}
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => handleMultipleDuplicateCheck(e, 'Registration', 'VatRegNo', 'Suppliers', 'VALIDATION_VATNoExists', regDetails.tempSupplierID)}
                            />
                            <FormText>
                                <Translation ID="VATRegNoExample" />
                            </FormText>
                            <FormFeedback>
                                <span id="vatRegNo_LABEL"></span>
                            </FormFeedback>
                        </FormGroup>
                    </div>

                    <div className="c-form__input-group">
                        <Translation ID="CompanyEmail" required htmlFor='companyEmail' />
                        <FormGroup className="position-relative">
                            <input name="companyEmail" id="companyEmail" type="text" className='c-form__input form-control' placehold="e.g. john@smith.com"
                                defaultValue={regDetails.companyEmail} required
                                onChange={(e) => { handleChange(e); checkEmailIsValid(e.target.id, e.target.value, 'VALIDATION_EmailComplexity') }}
                                onBlur={(e) => handleEmailOnBlur(e)} />
                            <FormFeedback>
                                <span id="companyEmail_LABEL"></span>
                            </FormFeedback>
                        </FormGroup>
                    </div>

                    <div className='c-form__input-group'>
                        <Translation ID="CompanyPhone" required htmlFor='companyTelephone' />
                        <FormGroup className="position-relative">
                            <input name="companyTelephone" id="companyTelephone" className='c-form__input' placehold="e.g. +44 0123 456 789"
                                defaultValue={regDetails.companyTelephone} required
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => handleRequiredCheck(e, 'VALIDATION_MandatoryTelephone')}
                            />
                            <FormFeedback>
                                <span id="companyTelephone_LABEL"></span>
                            </FormFeedback>
                        </FormGroup>
                    </div>

                    <div className="c-form__input-group">
                        <Translation ID="Website" htmlFor='companyWebsite' />
                        <input name="companyWebsite" id="companyWebsite" type="text" onChange={(e) => handleChange(e)} className='c-form__input' placehold="e.g. https://www.test.com"
                            autoComplete="off" defaultValue={regDetails.companyWebsite} />
                    </div>
                </div>

                <div className="u-flex-end u-component-spacing--2x">
                    <button id="buttonCompanyDetails" className="js-change-panel c-cta" type='button' onClick={(e) => handleNextClick(e, 'company')}>
                        Next step: {packages.isEnabled === true ? "Packages" : "Summary" }
                        </button>
                </div>
            </div>
        </section>
    )
}

export default CompanyDetails;