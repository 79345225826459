import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';

const ScrollArrow = () => {
    const [showScroll, setShowScroll] = useState(false);

    const checkScrollTop = useCallback(() => {
        if (!showScroll && window.pageYOffset > 400) {
            setShowScroll(true);
        } else if (showScroll && window.pageYOffset <= 400) {
            setShowScroll(false);
        }
    }, [showScroll])

    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop);

        return function cleanup() {
            window.removeEventListener('scroll', checkScrollTop);
        }
    })

    return (
        <span id="moveup" title="Back to the Top" onClick={scrollTop} style={{ display: showScroll ? 'inline' : 'none' }}>
            <FontAwesomeIcon icon={faChevronUp} />
            Top
        </span>
    );
}

export default ScrollArrow;