import { useEffect } from 'react';
import { authHeader } from '../helpers/auth-header';

const useMatamo = () => {
    useEffect(() => {
        fetch(`./Settings/GetAnalytics?name=In-tend`, {
            method: 'GET',
            headers: authHeader(false, true)
        })
            .then(response => response.json())
            .then(analyticsData => {
                if (analyticsData.active === true) {
                    window._mtm = window._mtm || [];
                    window._mtm.push({ 'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start' });

                    // Create the script element
                    const script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.async = true;
                    script.defer = true;
                    script.src = `${analyticsData.url}`; // Update the URL and container ID

                    // Append the script to the document head
                    document.head.appendChild(script);

                    // Cleanup function to remove the script when the component unmounts
                    return () => {
                        document.head.removeChild(script);
                    };
                }
            })
            .catch(err => {
                console.log(err);
            });
    }, []);
};

export default useMatamo;

