import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BasketNav = props => {

    return (
        <NavItem>
            <NavLink tag={Link} activeClassName="active" className='text-dark nav-link' to='/basket'><FontAwesomeIcon icon={faShoppingCart} /></NavLink>
        </NavItem>
    );
}

export default BasketNav;