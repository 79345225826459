import { SETTINGS_REQUEST, WEB_SETTINGS_SUCCESS, WEB_SETTINGS_FAILURE } from "../actions/setting.actions"

const initialState = {
    webSettings: []
}

const SettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SETTINGS_REQUEST:
            return Object.assign({}, state, {
                webSettings: state.webSettings,
            })
        case WEB_SETTINGS_SUCCESS:
            return Object.assign({}, state, {
                webSettings: action.data
            })
        case WEB_SETTINGS_FAILURE:
            return state
        default:
            return state
    }
}

export default SettingsReducer;