import React, { useEffect } from "react";
import Footer from "./Footer";
import NavMenu from './NavMenu';


const Layout = ({ ...props }) => {

    useEffect(() => { document.body.classList.add('brand-overrides', 'u-alt-bg-color'); }, [])

    return (
        <>
            <NavMenu />
            <main className="main-content u-container" fluid='true'>
                {props.children}
            </main>
            <Footer />
        </>
    );
}

export default Layout;