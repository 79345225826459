import "bootstrap/dist/css/bootstrap.css";
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import { useEffect, useState } from 'react';
import { FormFeedback, FormGroup, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import { authHeader } from "../../helpers/auth-header";
import { getRegex, getRegexValidationRules, handlePatternValidation, handleRequiredCheck, handleTimedSuccess, showCustomError } from "../../helpers/general";
import Translation from "../controls/Translation";

require('datatables.net-bs4');


require('datatables.net-bs4');

const ChangePassword_Modal = (props) => {
    let { isOpen, toggle, isCurrentUserAdmin, contactID } = props;
    const [regex, setRegex] = useState([]);
    const [value, setValue] = useState({});
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const toggleOldPassword = () => setShowOldPassword(!showOldPassword);
    const toggleNewPassword = () => setShowNewPassword(!showNewPassword);

    useEffect(() => {
        getRegexValidationRules().then(vData => {
            setRegex(vData);
        });
    }, []);


    const handleChange = e => {
        e.persist();

        setValue(value => ({
            ...value,
            [e.target.name]: e.target.value
        }))
    }

    const handleResetPassword = e => {
        let bodyData = { 'IsCurrentUserAdmin': isCurrentUserAdmin, 'ContactID': contactID, 'OldPassword': value.oldPassword, 'NewPassword': value.newPassword };
        fetch(`./SupplierUsers/ChangePassword`, {
            method: 'POST',
            headers: authHeader(false, false),
            body: JSON.stringify(bodyData)
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    toggle();
                    handleTimedSuccess('success', 'Password Changed', 'Your password has been successfully changed.');
                } else {
                    showCustomError('Password could not be reset - this may have been due to an incorrect old password being entered.<br /><br />Please try again.', 'Something went wrong', 'error')
                }
            })
            .catch(err => console.log("Error: ", err));
    }

    return (
        <Modal
            id='resetPasswordModal'
            isOpen={isOpen}
            toggle={toggle}
            size="md"
            centered
            unmountOnClose
        >
            <ModalHeader toggle={toggle} />
            <ModalBody>
                <div className="card main-content__inner" style={{ height: "calc(100% - 1rem)" }} >
                    <div className="card-body">
                        <div className="c-card card u-component-spacing" style={{ height: "calc(100% - 1rem)" }}>
                            <div className="card-body">
                                <h3 className="c-broken-underline u-component-spacing">Change Password</h3>
                                <div className="c-form u-component-spacing--2x">

                                    <div className="c-form__input-group c-form__input-group--block-display">
                                        <div className="u-component-spacing">
                                        <label htmlFor='oldPassword' className='c-form__label--required c-form__label'>
                                            Enter your old password for your account
                                        </label>
                                        <div className="c-floating-label u-component-spacing">
                                            <FormGroup>
                                                <input
                                                    name="oldPassword"
                                                    id="oldPassword"
                                                    type={showOldPassword ? 'text' : 'password'}
                                                    className="c-floating-label__input form-control"
                                                    onChange={(e) => handleChange(e)}
                                                    placeholder=" "
                                                    />
                                                <Label className="c-floating-label__label" htmlFor="oldPassword">Old Password</Label>
                                                <button className="c-floating-label__show-password" type="button" onClick={toggleOldPassword}>{showOldPassword ? 'Hide' : 'Show'}</button>
                                                <FormFeedback>
                                                    <span id="password_LABEL"></span>
                                                </FormFeedback>
                                            </FormGroup>
                                        </div>
                                        </div>
                                        <hr className="u-component-spacing--2x" />
                                        <div className="u-component-spacing--2x">
                                        <label htmlFor='newPassword' className='c-form__label--required c-form__label'>
                                            Please enter a new password for your account
                                        </label>
                                        <div className="c-floating-label u-component-spacing">
                                            <FormGroup>
                                                <input
                                                    name="newPassword"
                                                    id="newPassword"
                                                    type={showNewPassword ? 'text' : 'password'}
                                                    className="c-floating-label__input form-control"
                                                    defaultValue=''
                                                    placeholder=" "
                                                    required aria-describedby="password-requirements"
                                                    pattern={getRegex(regex, 'PasswordComplexity')}
                                                    onChange={(e) =>
                                                        handlePatternValidation(e, 'VALIDATION_PasswordComplexityBelow')
                                                            ? handleChange(e)
                                                            : ""}
                                                    onBlur={(e) => handleRequiredCheck(e, 'VALIDATION_MandatoryEmail')
                                                        ? handlePatternValidation(e, 'VALIDATION_PasswordComplexityBelow')
                                                        : ""}
                                                />
                                                <Label className="c-floating-label__label" htmlFor="newPassword">New Password</Label>
                                                <button className="c-floating-label__show-password" type="button" onClick={toggleNewPassword}>{showNewPassword ? 'Hide' : 'Show'}</button>
                                                <FormFeedback>
                                                    <span id="newPassword_LABEL"></span>
                                                </FormFeedback>
                                            </FormGroup>
                                            </div>
                                        </div>

                                        <div id="password-requirements" className="c-password-requirements u-component-spacing">
                                            <p className="c-password-requirements__text"><Translation ID='PasswordMustContain' customClass='' /></p>
                                            <ul className="c-password-requirements__list">
                                                <li className="c-password-requirements__list-item"><Translation ID='Atleast8Characters' customClass='' /></li>
                                                <li className="c-password-requirements__list-item"><Translation ID='Atleast1Uppercase' customClass='' /></li>
                                                <li className="c-password-requirements__list-item"><Translation ID='Atleast1Lowercase' customClass='' /></li>
                                                <li className="c-password-requirements__list-item"><Translation ID='Atleast1Number' customClass='' /></li>
                                                <li className="c-password-requirements__list-item"><Translation ID='Atleast1Special' customClass='' /></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="u-flex-end u-component-spacing">
                                        <button className="js-change-panel c-cta" data-target="1" onClick={() => handleResetPassword()}>Reset Password</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}
export default ChangePassword_Modal;