import { PORTAL_FAILURE, PORTAL_REQUEST, PORTAL_SUCCESS } from "../actions/portals.actions"

const initialState = {
    isLoading: true,
    portals: []
}

export default function portals(state = initialState, action) {
    switch (action.type) {
        case PORTAL_REQUEST:
            return Object.assign({}, state, {
                isLoading: true
            })
        case PORTAL_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                portals: action.portals
            })
        case PORTAL_FAILURE:
            return Object.assign({}, state)
        default:
            return state
    }
}