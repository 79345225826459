import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, FormFeedback, FormGroup, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { authHeader } from '../../helpers/auth-header';
import { checkEmailIsValid, getApplicationType, getContactID, getRegex, getRegexValidationRules, getSupplierID, handleConfirm, handleEmailCheck, handleRequiredCheckAlt, handleTimedSuccess, handleUpdateContactPortalDetails, pageEnabled, showCustomError, showError, useQuery } from '../../helpers/general';
import ChangePasswordModal from '../Auth/ChangePassword_Modal';
import BackToDashboard from '../controls/BackToDashboard';
import Select2 from '../controls/Select2';
import Translation from '../controls/Translation';

const $ = require('jquery');

const SupplierUser = () => {
    const [user, setUser] = useState({ supplierID: -1, contactID: -1 });
    const [activeTab, setActiveTab] = useState('1');
    const [titles, setTitles] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [savedSearch, setSavedSearch] = useState([])
    const [selectedSavedSearch, setSelectedSavedSearch] = useState('');
    const [supplierUser, setSupplierUser] = useState({
        details: [{
            title: '',
            firstName: '',
            lastName: '',
            fax: '',
            email: '',
            mobile: '',
            jobTitle: '',
            department: '',
            office: '',
            webUserName: '',
            sendDaily: false,
            preferredLanuage: 'en',
            includeNonUKTenders: false
        }],
        permissions: null,
        hasEmailAlert: null
    });
    const [loggedInUserPermissions, setLoggedInUserPermissions] = useState({ isUserAdmin: false, canChangePermissions: false, canUpdateContacts: false });
    const history = useHistory();
    const [isPageEnabled, setIsPageEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [application, setApplication] = useState('');
    const [showNotificationsTab, setShowNotificationsTab] = useState(null);
    const toggleChangePassword = () => setShowChangePasswordModal(!showChangePasswordModal);

    let query = useQuery();
    let viewedUserId = query.get('uid');

    useEffect(() => {
        pageEnabled('[[CONTACT_NAME]]', '/dashboard').then(enabled => { setIsPageEnabled(enabled); });

        getApplicationType()
            .then(application => {
                setApplication(application);

                setUser(prev => ({
                    ...prev,
                    supplierID: getSupplierID(),
                    contactID: getContactID()
                }));
            });
    }, [])

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
        if (user.contactID > 0 && application !== '') {
            fetch(`./SupplierUsers/GetNameTitles`, {
                method: 'GET',
                headers: authHeader(false, true)
            })
                .then(response => response.json())
                .then(data => {
                    let vTitles = [];

                    vTitles.push({ label: "Select..", value: "-1" });

                    data.map(obj => vTitles.push({ label: obj.title, value: obj.title }));
                    setTitles(vTitles);
                })
                .catch(error => console.log('Error: ', error));


            fetch(`./Permissions/GetAllContactPermissions?systemName=${application}`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        setPermissions(data);
                    }
                })
                .catch(error => console.log('Error: ', error));

            fetch(`./Permissions/IsUserAdmin?contactID=${user.contactID}`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        setLoggedInUserPermissions(prevState => ({ ...prevState, IsUserAdmin: data }));
                    }
                })
                .catch(error => console.log('Error: ', error));

            // If user is viewing their own profile
            if (parseInt(viewedUserId)) {

                if (user.contactID === Number(viewedUserId)) {
                    setLoggedInUserPermissions(prevState => ({ ...prevState, canUpdateContacts: true }));
                } else {
                    fetch(`./Permissions/CanUserChangeOtherUserDetails?contactID=${user.contactID}`, {
                        method: 'GET',
                        headers: authHeader()
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data) {
                                setLoggedInUserPermissions(prevState => ({ ...prevState, canUpdateContacts: data }));
                            }
                        })
                        .catch(error => console.log('Error: ', error));
                }
            }

            fetch(`./Permissions/CanUserChangePermissions?contactID=${user.contactID}`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        setLoggedInUserPermissions(prevState => ({ ...prevState, canChangePermissions: data }));
                    }
                })
                .catch(error => console.log('Error: ', error));

            if (user.supplierID > 0 && application !== '') {
                fetch(`./Suppliers/GetAllSupplierSavedSearches?supplierID=${user.supplierID}&contactID=${user.contactID}&systemName=${application}`, {
                    method: 'GET',
                    headers: authHeader(false, false)
                })
                    .then(response => response.json())
                    .then(data => {
                        let vSearches = [];

                        vSearches.push({ label: "Select..", value: "-1" });

                        data.map(obj => vSearches.push({ label: obj.searchDescription, value: obj.searchID }));
                        setSavedSearch(vSearches);

                    })

                    .catch(error => console.log('Error: ', error))
            }

            fetch(`./Settings/ArePackagesEnabled`, {
                method: 'GET',
                headers: authHeader(false, true)
            })
                .then(response => response.json())
                .then(packagesEnabled => {
                    setShowNotificationsTab(packagesEnabled);
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }, [application, user.contactID, user.supplierID, viewedUserId]);


    useEffect(() => {
        if (user.supplierID > 0 && viewedUserId) {
            fetch(`./SupplierUsers/GetUsersSelectedSaveSearch?userID=${viewedUserId}`, {
                method: 'GET',
                headers: authHeader(false, false)
            })
                .then(response => response.json())
                .then(data => {
                    if (data !== '') {
                        setSelectedSavedSearch(data.toLowerCase());
                    }
                })
                .catch(err => console.log(err));

            fetch(`./SupplierUsers/GetContactDetails?userID=${viewedUserId}&supplierID=${user.supplierID}`, { method: 'GET', headers: authHeader() })
                .then(response => response.json())
                .then(data => {
                    if (data.success === true) {

                        setSupplierUser(prevState => ({
                            ...prevState,
                            details: data.objData
                        }));

                        //setSupplierUser(data.objData);
                    } else {
                        showError(data.error, data.message);
                        history.push('/dashboard');
                    }
                })
                .catch(err => console.log(err));

            fetch(`./Permissions/GetContactPermissionIDs?contactID=${viewedUserId}`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(data => {
                    setSupplierUser(prevState => ({
                        ...prevState,
                        permissions: data
                    }));
                })
                .catch(err => console.log(err));

            fetch(`./Suppliers/GetSupplierAllowance?supplierID=${user.supplierID}&packageService=pservice_emailalert`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(emailAlert => {
                    setSupplierUser(prevState => ({
                        ...prevState,
                        hasEmailAlert: emailAlert
                    }));
                });

        } else if (user.supplierID === 0) {
            showError('AddSupplier', 'Error No User')
        }
    }, [history, user.supplierID, viewedUserId]);

    function handleChange(e) {
        e.persist();
        let val = e.target.value;

        var userDetails = supplierUser.details;
        userDetails = { ...userDetails, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : val };
        supplierUser.details = userDetails;
    }

    function handlePermissionsChange(e, id) {
        e.persist();

        var tmpPermissions = supplierUser.permissions;
        let index = tmpPermissions.indexOf(id);
        if (e.target.checked) {
            if (index === -1) {
                tmpPermissions.push(id);
            }
        } else {
            if (index > -1) {
                tmpPermissions.splice(index, 1);
            }
        }

        var userArray = supplierUser;
        userArray.permsisions = tmpPermissions;

        setSupplierUser(userArray);
    }

    const handleSave = () => {
        let bProceedRegistering = true;
        let failedFields = [];

        $('.is-invalid').each(function (o, e) {
            bProceedRegistering = false;
            failedFields.push($(this).attr('id'))
        });

        $("input[required]").each(function (i, obj) {
            if ($(this).val() === '' && !$(this).hasClass('is-invalid')) {
                let fieldCheck = false;

                if (this.type === 'email') {
                    checkEmailIsValid($(this).attr('id'), $(this).val(), 'VALIDATION_default')
                        .then(vData => fieldCheck = vData);
                } else {
                    fieldCheck = handleRequiredCheckAlt($(this).attr('id'), $(this).val(), 'VALIDATION_default');
                }

                if (!fieldCheck) { failedFields.push($(this).attr('id')) }

                bProceedRegistering = bProceedRegistering && fieldCheck;
            }
        });

        if (bProceedRegistering) {
            if (viewedUserId) {
                handleConfirm(
                    viewedUserId > 0
                        ? `<p>Make changes to the account for ${supplierUser.details.firstName} ${supplierUser.details.lastName}?</p><hr /><p style={{marginBottom: "0.5em"}}><strong>PLEASE NOTE: </strong> This will update all your personal portal contact details you are linked with.<p>`
                        : `Would you like to create the account for ${supplierUser.firstName} ${supplierUser.lastName}?`,
                    function () {
                        saveDetails();
                    }
                );
            };
        } else {
            // Toggle tab of the first field to error
            let invalidInput = $(`#${failedFields[0]}`);
            let closest = invalidInput.closest('.tab-pane');
            let id = closest.attr('id');
            toggle(id);

            // Focus the field after a slight time out for rendering
            setTimeout(() => {
                document.getElementById(failedFields[0]).focus();
            }, 100);

            showCustomError('There is a problem with the information you are trying to save - please review any errors on screen and try again.', 'Cannot Save Details', 'error');
        }
    }

    const saveDetails = () => {
        if (viewedUserId) {
            fetch(`./SupplierUsers/UpdateSupplierUser?contactID=${viewedUserId}&supplierID=${user.supplierID}&loggedInUser=${user.contactID}`, {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify(supplierUser)
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success === true) {
                        handleUpdateContactPortalDetails(user.contactID);
                    } else {
                        showCustomError(data.message, 'Problem Updating Contact', 'error');
                    }
                })
                .catch(err => {
                    console.log('Error');
                });
        } else {
            if (application !== '') {
                fetch(`./SupplierUsers/CreateNewSupplierUser?supplierID=${user.supplierID}&systemName=${application}`, {
                    method: 'POST',
                    headers: authHeader(false, false),
                    body: JSON.stringify(supplierUser)
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.success === true) {
                        } else {
                            console.log('Error');
                            showError(data.error, data.message);
                        }
                    })
                    .catch(err => {
                        console.log('Error::', err);
                    });
            }
        }
    }

    const handleOpenChangePassword = () => {
        setIsLoading(true)
        toggleChangePassword();
    }

    if (isPageEnabled === true && supplierUser && supplierUser.details) {
        return (
            <>
                <Card className='main-content__inner'>
                    <BackToDashboard />

                    <CardBody>
                        <Card className='c-card u-component-spacing'>
                            <CardBody>
                                <h2 className='c-broken-underline'>
                                    {user.contactName}
                                </h2>
                                <div className='tabs u-component-spacing--2x'>
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '1' })}
                                                id='tab-0'
                                                data-toggle='tab'
                                                aria-controls='panel-0'
                                                aria-label='details'
                                                onClick={() => { toggle('1'); }}
                                            >
                                                <Translation ID='Details' />
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '2' })}
                                                id='tab-1'
                                                data-toggle='tab'
                                                aria-controls='panel-1'
                                                aria-label='contact details'
                                                onClick={() => { toggle('2'); }}
                                            >
                                                <Translation ID='ContactDetails' />
                                            </NavLink>
                                        </NavItem>
                                        {showNotificationsTab === true &&
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '3' })}
                                                    id='tab-2'
                                                    data-toggle='tab'
                                                    aria-controls='panel-2'
                                                    aria-label='notification settings'
                                                    onClick={() => { toggle('3'); }}
                                                >
                                                    <Translation ID='NotificationSettings' />
                                                </NavLink>
                                            </NavItem>
                                        }
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '4' })}
                                                id='tab-3'
                                                data-toggle='tab'
                                                aria-controls='panel-3'
                                                aria-label='contact permissions'
                                                onClick={() => { toggle('4'); }}
                                            >
                                                <Translation ID='Permissions' />
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>

                                <TabContent activeTab={activeTab}>
                                    <TabPane id='1' tabId='1'>
                                        <div className='tab-pane__inner'>
                                            <div className='c-form'>
                                                {(loggedInUserPermissions.IsUserAdmin || Number(viewedUserId) === Number(user.contactID)) &&
                                                    <input id="changePassword" type="submit" className="c-cta u-component-spacing" style={{ width: "15em" }} value="Change Password" onClick={() => handleOpenChangePassword()} />
                                                }

                                                {titles && Object.keys(titles).length !== 0 && Object.keys(supplierUser.details).length !== 0 &&
                                                    <div className='c-form__input-group'>
                                                        <label className='c-form__label' htmlFor='title'>
                                                            <Translation ID='Title' />
                                                        </label>
                                                        <Select2
                                                            id={'title'}
                                                            options={titles}
                                                            defaultValue={supplierUser.details.title}
                                                            change={(e) => handleChange(e)}
                                                            disabled={!loggedInUserPermissions.canUpdateContacts}
                                                        />
                                                    </div>
                                                }

                                                <div className='c-form__input-group'>
                                                    <label className='c-form__label' htmlFor='firstName'>
                                                        <Translation ID='FirstName' />
                                                    </label>
                                                    <input name='firstName' id='firstName' type='text' className='c-form__input' disabled={!loggedInUserPermissions.canUpdateContacts} defaultValue={supplierUser.details.firstName} onChange={(e) => handleChange(e)} />
                                                </div>

                                                <div className='c-form__input-group'>
                                                    <label className='c-form__label' htmlFor='lastName'>
                                                        <Translation ID='LastName' />
                                                    </label>
                                                    <input name='lastName' id='lastName' type='text' className='c-form__input' disabled={!loggedInUserPermissions.canUpdateContacts} defaultValue={supplierUser.details.lastName} onChange={(e) => handleChange(e)} />
                                                </div>

                                                <div className='c-form__input-group'>
                                                    <label className='c-form__label' htmlFor='jobTitle'>
                                                        <Translation ID='JobTitle' />
                                                    </label>
                                                    <input name='jobTitle' id='jobTitle' type='text' className='c-form__input' disabled={!loggedInUserPermissions.canUpdateContacts} defaultValue={supplierUser.details.jobTitle} onChange={(e) => handleChange(e)} />
                                                </div>

                                                <div className='c-form__input-group'>
                                                    <label className='c-form__label' htmlFor='department'>
                                                        <Translation ID='Department' />
                                                    </label>
                                                    <input name='department' id='department' type='text' className='c-form__input' disabled={!loggedInUserPermissions.canUpdateContacts} defaultValue={supplierUser.details.department} onChange={(e) => handleChange(e)} />
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane id='2' tabId='2'>
                                        <div className='tab-pane__inner'>
                                            <div className='c-form'>

                                                <div className='c-form__input-group'>
                                                    <Translation ID='TelephoneNumber' htmlFor='telephone' />
                                                    <input name='telephone' id='telephone' type='text' className='c-form__input' disabled={!loggedInUserPermissions.canUpdateContacts} defaultValue={supplierUser.details.telephone} onChange={(e) => handleChange(e)} />
                                                </div>

                                                <div className='c-form__input-group'>
                                                    <Translation ID='MobileNumber' htmlFor='mobile' />
                                                    <input name='mobile' id='mobile' type='text' className='c-form__input' disabled={!loggedInUserPermissions.canUpdateContacts} defaultValue={supplierUser.details.mobile} onChange={(e) => handleChange(e)} />
                                                </div>

                                                <div className='c-form__input-group'>
                                                    <Translation ID="EmailAddress" required={true} htmlFor='email' />
                                                    <input name="email" type="email" defaultValue={supplierUser.details.email} id="email" className='c-form__input form-control' disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                    <TabPane id='3' tabId='3'>
                                        <div className='tab-pane__inner'>
                                            {supplierUser.hasEmailAlert === true &&
                                                <div className='c-form'>
                                                    <div className='c-form__input-group'>
                                                        <input name='sendDaily' type='checkbox' id='sendDaily' className='c-form__checkbox' disabled={!loggedInUserPermissions.canUpdateContacts} defaultChecked={supplierUser.details.sendDaily} onChange={(e) => handleChange(e)} />
                                                        <Translation ID='ReceiveDailyEmails' htmlFor='sendDaily' />
                                                    </div>

                                                    {savedSearch && Object.keys(savedSearch).length !== 0 &&
                                                        <div className='c-form__input-group'>
                                                            <Translation ID='IncludeSavedSearch' htmlFor='savedSearch' />
                                                            <Select2
                                                                id='savedSearch'
                                                                disabled={!loggedInUserPermissions.canUpdateContacts}
                                                                options={savedSearch}
                                                                defaultValue={selectedSavedSearch}
                                                                change={(e) => handleChange(e)}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            }

                                            {supplierUser.hasEmailAlert === false &&
                                                <>
                                                    <h5 className="c-page-title u-component-spacing--1x">
                                                        <p>Your current package does not support Daily Tender Notification emails.</p>
                                                        <p>To receive daily notifications of opportunities that match your companys profile, you must upgrade your package</p>
                                                    </h5>
                                                    <button className="c-tender-actions__action-button c-tender-actions__action-button--upgrade u-component-spacing--2x" onClick={() => history.push(`/upgrade`)}>
                                                        Upgrade Package
                                                    </button>
                                                </>
                                            }
                                        </div>
                                    </TabPane>
                                    <TabPane id='4' tabId='4'>
                                        {supplierUser.permissions !== null &&
                                            <div className='tab-pane__inner'>
                                                <div className='c-form'>
                                                    {supplierUser.permissions.includes(1) &&
                                                        <h4>
                                                            ADMINISTRATOR ACCOUNT
                                                        </h4>
                                                    }
                                                    {!supplierUser.permissions.includes(1) && Object.keys(permissions).length > 0 &&
                                                        <>
                                                            {permissions.map((permission, i) => {
                                                                if (permission.permissionID !== 1) {
                                                                    return (
                                                                        <div className='c-form__input-group' key={i}>
                                                                            <input name={`permission${permission.permissionID}`} disabled={!loggedInUserPermissions.canChangePermissions}
                                                                                type='checkbox'
                                                                                id={`permission${permission.permissionID}`}
                                                                                className='c-form__checkbox'
                                                                                defaultChecked={supplierUser.permissions.includes(Number(permission.permissionID))}
                                                                                onChange={(e) => handlePermissionsChange(e, permission.permissionID)}
                                                                            />
                                                                            <label htmlFor={`permission${permission.permissionID}`} className='c-form__label' >{permission.description}</label>
                                                                        </div>
                                                                    );
                                                                } else {
                                                                    return null;
                                                                }
                                                            })}
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </TabPane>
                                </TabContent>

                                {(loggedInUserPermissions.canUpdateContacts || loggedInUserPermissions.canChangePermissions) &&
                                    <div className='u-flex-end u-component-spacing'>
                                        <button type='button' className='c-form__submit' onClick={() => handleSave()}>
                                            <Translation ID='SaveDetails' />
                                        </button>
                                    </div>
                                }
                            </CardBody>
                        </Card>
                    </CardBody>
                </Card>

                <ChangePasswordModal
                    isOpen={showChangePasswordModal}
                    toggle={toggleChangePassword}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    isCurrentUserAdmin={loggedInUserPermissions.IsUserAdmin}
                    contactID={viewedUserId}
                />
            </>
        );
    } else {
        return null;
    }
}

export default SupplierUser;