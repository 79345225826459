import React, { useState } from 'react';
import { Alert, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Classifications from './Classifications';
import { useEffect } from 'react';
import SpinnerOverlay from '../layout/SpinnerOverlay';
import Translation from '../controls/Translation';
import { useCallback } from 'react';

const ClassificationTypes = prop => {
    let { supplierID, isActive, updateDetailsPermission } = prop;
    const [activeTab, setActiveTab] = useState('classification-tab-0');
    const [businessClassificationTypes, setBusinessClassificationTypes] = useState([]);

    const toggle = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);

            var classTab = document.getElementById('classification-tab-select');
            if (classTab !== null) {
                classTab.value = tab;
            }
        }
    }
    
    useEffect(() => {
        fetch(`./BusinessClassifications/GetBusinessClassificationTypes`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                let vClassifications = [];

                data.map(obj => vClassifications.push({ id: obj.uniqueID, type: obj.type }));
                setBusinessClassificationTypes(vClassifications);
            })
            .catch(error => {
                console.log('Error: ', error);
            });
    }, []);

    const handleTabMenu = useCallback((tabMenu, event) => {
        if (tabMenu) {
            tabMenu.querySelector('#' + event.target.value).click();
        }
    }, []);

    useEffect(() => {
        if (Object.keys(businessClassificationTypes).length > 0) {
            const tabClassificationSelect = document.querySelector('.js-classifications-tab-select');
            const tabClassificationMenu = document.querySelector('.' + tabClassificationSelect.dataset.tabTarget);

            tabClassificationSelect.addEventListener('change', event => {
                handleTabMenu(tabClassificationMenu, event);
            });
            return function cleanup() {
                window.removeEventListener('change', handleTabMenu());
            }
        }
    }, [handleTabMenu, businessClassificationTypes]);

    if (businessClassificationTypes && Object.keys(businessClassificationTypes).length > 0) {
        return (
            <>
                <div className="c-tab-select">
                    <label className="c-tab-select__label" htmlFor="tab-select">Code View:</label>
                    <select id="classification-tab-select" className="c-tab-select__select js-classifications-tab-select" data-tab-target="js-classifications-tabs">
                        {businessClassificationTypes.map((classification, i) => {
                            return (
                                <option key={i} value={`classification-tab-${classification.id}`} >{classification.type}</option>)
                        })}
                    </select>
                </div>

                <div className='tabs'>
                    <Nav tabs className="js-classifications-tabs">
                        {businessClassificationTypes.map((classification, i) => {
                            return (
                                <NavItem key={i}>
                                    <NavLink
                                        className={classnames({ active: activeTab === `classification-tab-${classification.id}` })}
                                        id={`classification-tab-${classification.id}`}
                                        data-toggle='tab'
                                        aria-controls={`classification-tab-${classification.id}`}
                                        aria-label={classification.type}
                                        onClick={() => { toggle(`classification-tab-${classification.id}`); }}
                                    >
                                        {classification.type}
                                    </NavLink>
                                </NavItem>
                            )
                        })}
                    </Nav>
                </div>

                <TabContent activeTab={activeTab}>
                    {businessClassificationTypes.map((classification, i) => {
                        return (
                            <TabPane id={`classification-tab-${classification.id}`} tabId={`classification-tab-${classification.id}`} key={classification.id}>
                                <div className='tab-pane__inner top'>
                                    <div className='c-form'>
                                        <h4><Translation ID={classification.type.replace(' ', '')} /></h4>
                                        <Alert color="primary">
                                            <p style={{ textAlign: "center" }} > It is recommended that you select all relevent codes across all sectors - this gives you the benefit of potential opportunities and a direct invitiation from an organisation to tender.</p>
                                        </Alert>
                                        {isActive && `classification-tab-${classification.id}` === activeTab &&
                                            <Classifications
                                                filterSupplier={true}
                                                supplierID={supplierID}
                                                classificationTypeID={classification.id}
                                                classificationType={classification.type}
                                                updateDetailsPermission={updateDetailsPermission}
                                            />
                                        }
                                    </div>
                                </div>
                            </TabPane>
                        )
                    })}
                </TabContent>
            </>
        );
    } else {
        return <SpinnerOverlay />
    }
}

export default ClassificationTypes;