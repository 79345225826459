import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';

import 'bootstrap/dist/css/bootstrap.css';

import 'pace-js';
import 'pace-js/themes/green/pace-theme-minimal.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import App from './App';
import ScrollToTop from './components/controls/ScrollToTop';
import { MenuProvider } from './components/layout/MenuContext';
import NavMenu from './components/layout/NavMenu';
import StylesheetSelector from './components/layout/StylesheetSelector';
import { history } from './helpers/history';
import { unregister } from './registerServiceWorker';
import { store } from './store/index';



const rootElement = document.getElementById('root');

ReactDOM.render(
    <MenuProvider value={NavMenu}>
        <Provider store={store}>
            <Router history={history}>
                <StylesheetSelector>
                    <ScrollToTop />
                    <App />
                </StylesheetSelector>
            </Router>
        </Provider>
    </MenuProvider>,
    rootElement
);

unregister();