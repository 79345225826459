import React from 'react';

const PaymentError = () => {

    return (
        <>
            <div className="main-content u-container u-container--small container-fluid">
                <div className="u-flex-center u-component-spacing" style={{ color: "rgb(255, 255, 255)" }}>
                    <h2 className="u-component-spacing u-branded-underline">Payment Error!!</h2>
                </div>

                <div className="row u-component-spacing">
                    <div className="col-md u-component-spacing--2x">
                        <div className="card main-content__inner" style={{ height: "calc(100% - 1rem)" }} >
                            <div className="card-body">
                                <div className="c-card card u-component-spacing" style={{ height: "calc(100% - 1rem)" }}>
                                    <div className="card-body">
                                        <h3 className="c-broken-underline u-component-spacing">Password Reset Successful</h3>
                                        <div className="c-form u-component-spacing--2x">

                                            <div className="c-form__input-group c-form__input-group--block-display">
                                                <label className='c-form__label' style={{ display: 'contents' }}>
                                                    Error making a payment.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentError;