import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import SpinnerOverlay from '../layout/SpinnerOverlay';
import { getFileFromServer } from '../../helpers/controls';
import { downloadFile } from '../../helpers/general';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const ContractDetails = (props) => {
    let { id, contractID, setContractID, isOpen, toggle, isLoading, setIsLoading } = props;
    const [contract, setContract] = useState([]);

    useEffect(() => {
        if (parseInt(contractID) > 0 && contractID > 0) {
            fetch(`./Contracts/GetContractDetails?contractID=${contractID}`, {
                method: 'GET',
            })
                .then(response => response.json())
                .then(data => {
                    setContract(data);
                    setIsLoading(false);
                })
                .catch(error => {
                    console.log('Error: ', error);
                })
        }
    }, [contractID, setIsLoading]);

    const handleDownload = (uncPath) => {
        if (uncPath && uncPath !== '') {
            getFileFromServer(uncPath).then(data => {
                downloadFile(uncPath, data);
            });
        }
    }

    const closeModal = () => {
        setContractID(-1);
        toggle();
    }

    return (
        <Modal
            id={id}
            isOpen={isOpen}
            toggle={toggle}
            size="xl"
            centered
            backdrop="static"
            unmountOnClose
            scrollable={true}
        >
            <ModalHeader>
                <div className="modal-title">
                    {'Contract Details'}
                </div>
            </ModalHeader>
            <ModalBody>
                {!isLoading &&
                    <>
                        {contract && contract.contractDetails &&
                            <>
                                <dl className="dl-table">
                                    {
                                        contract.contractDetails.map((info, i) => {
                                            return (
                                                <div style={{ lineHeight: "1.8em" }} key={i}>
                                                    <dt>{info.label}</dt>
                                                    <dd>{info.value}</dd>
                                                </div>
                                            )
                                        })
                                    }
                                </dl>
                                <hr />
                                <Card color='primary' outline className='contractDetails_documents'>
                                    <h5 style={{ marginLeft: '20px', marginTop: '20px' }}>
                                        {'Contract Documents'}
                                    </h5>

                                    <dl className="dl-table">
                                        {contract.contractDocuments.length > 0 &&
                                            contract.contractDocuments.map((doc, i) => {
                                                return (
                                                    <Row key={i}>
                                                        <Col lg={2}>
                                                            <Button id='downloadDocument' name='downloadDocument' color='primary' outline onClick={(() => handleDownload(doc.uncPath))}>
                                                                <FontAwesomeIcon icon={faDownload} style={{ marginRight: '20px' }} />Download
                                                            </Button>
                                                        </Col>
                                                        <Col className='contractAttachmentLabel'>
                                                            {doc.name}
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                        }
                                        {contract.contractDocuments.length === 0 &&
                                            <div>No Documents</div>
                                        }
                                    </dl>
                                </Card>
                            </>
                        }
                        {(contract === null || contract.contractDocuments === null) &&
                            <h3>No Contract Details Found</h3>
                        }
                    </>
                }
                {isLoading &&
                    <SpinnerOverlay />
                }
            </ModalBody>
            <ModalFooter>
                <div className="p-2 text-right col-6">
                    <Button id="close" color="danger" size="sm" onClick={() => closeModal()}>{'Close'}</Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default ContractDetails;