import React, { useState } from 'react';

const ThemeSwitcher = () => {

    // Check for dark mode preference at the OS level
    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");

    // Get the user's theme preference from local storage, if it's available
    const selectedTheme = localStorage.getItem("theme");

    // Default next theme
    let nextTheme = "dark";

    // Default current theme
    let currentTheme = "light";

    // If a theme has been manually selected and set to storage, use that theme
    if (selectedTheme) {
        applyTheme(selectedTheme);
    }
    // If not theme has been manually selected, use system preferences
    else if (prefersDarkScheme.matches) {
        applyTheme('dark');
    }
    else {
        applyTheme('light');
    }

    const [themeMode, setThemeMode] = useState(nextTheme);

    // Apply the theme
    function applyTheme(theme) {
        // Determine which theme needs taking off
        const removeTheme = theme === 'dark' ? "light" : "dark";

        // Remove the current theme and add the new theme
        document.body.classList.remove(removeTheme + "-mode");
        document.body.classList.add(theme + "-mode");

        // Update current theme and next theme values for the click events
        nextTheme = removeTheme;
        currentTheme = theme;
    }

    const handleModeChange = e => {
        e.preventDefault();

        // Apply the next theme
        applyTheme(nextTheme);

        //Save the current preference to localStorage
        localStorage.setItem("theme", currentTheme);
        setThemeMode(nextTheme);
    }

    return (
        <div className="c-theme-switcher">
            <span>Theme switcher: </span>
            <button className="c-theme-switcher__button js-theme-toggle" onClick={((e) => handleModeChange(e))} >Switch to {themeMode} mode</button>
        </div>
    );
}

export default ThemeSwitcher;