import { faArchive, faExpandAlt, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Card, CardBody, CardHeader, UncontrolledTooltip } from 'reactstrap';
import { getApplicationType } from '../../helpers/general';
import { history } from '../../helpers/history';
import DataTable from '../controls/DataTable';
import Translation from '../controls/Translation';
import SupplierEOIsModal from '../Tenders/SupplierEOIs_Modal';

const moment = require('moment');
require('datatables.net-bs4');

const SupplierEOIs = props => {
    const { supplierID, CardClass, PanelName, TableName } = props;
    const [finalSearchString, setFinalSearchString] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const toggle = () => setShowModal(!showModal);
    const [includeArchived, setIncludeArchived] = useState(false)

    useEffect(() => {
        if (supplierID && supplierID > 0) {
            // statusID is enumb from CvExpiringTenders
            getApplicationType()
                .then(application => {
                    setFinalSearchString(`supplierID=${supplierID}&includeArchived=${includeArchived}&systemName=${application}`);
                });
        }
    }, [includeArchived, supplierID]);

    const handleExpandClick = () => {
        setIsLoading(true)
        toggle();
    }

    const handleArchivedChange = (e) => {
        e.persist();
        getApplicationType()
            .then(application => {
                setIncludeArchived(e.target.checked);
                setFinalSearchString(`supplierID=${supplierID}&showArchived=${e.target.checked}&systemName=${application}`)
            });
    }

    if (finalSearchString.length) {
        return (
            <>
                <Card className={CardClass} >
                    <CardHeader className="ic-interest">
                        <span>
                            <Translation ID={PanelName} />
                        </span>
                        <span style={{ justifyContent: 'right' }}>
                            <button id='expandWatched' onClick={(() => handleExpandClick())}>
                                <FontAwesomeIcon icon={faExpandAlt} style={{ marginRight: '20px' }} />
                            </button>
                        </span>
                    </CardHeader>
                    <CardBody className='padding-5-1 card-module'>
                        <div className='c-form__input-group'>
                            <input id={`${TableName}_includeArchived`} name='includeArchived' type='checkbox' className="c-form__checkbox" onChange={((e) => { handleArchivedChange(e) })} />
                            <Translation ID='IncludeArchived' htmlFor={`${TableName}_includeArchived`} />
                        </div>
                        <hr />
                        <DataTable
                            id={TableName}
                            columns={columns}
                            sorting
                            paging={false}
                            info={false}
                            defaultOrder={[3, "asc"]}
                            url="./Suppliers/GetSupplierEOIs"
                            params={finalSearchString}
                            isSearching={true}
                            scrollY='300px'
                            pagingType='full'
                            scrollCollapse={true}
                        />
                    </CardBody>
                </Card>

                <SupplierEOIsModal
                    id={`${TableName}_Modal`}
                    isOpen={showModal}
                    toggle={toggle}
                    supplierID={supplierID}
                    cardClass={CardClass}
                    panelName={PanelName}
                    tableName={`${TableName}_Modal_`}
                />
            </>
        )
    }
    else {
        return null;
    }
}

const ClosedTooltip = React.forwardRef((props, ref) => {
    return (
        <>
            <FontAwesomeIcon forwardedRef={ref} icon={faLock} />
            <UncontrolledTooltip placement="right" defaultOpen={false} target={ref}>
                Closed Opportunity
            </UncontrolledTooltip>

        </>
    )
});

const ArchivedTooltip = React.forwardRef((props, ref) => {
    return (
        <>
            <FontAwesomeIcon forwardedRef={ref} icon={faArchive} />
            <UncontrolledTooltip placement="right" defaultOpen={false} target={ref}>
                Archived
            </UncontrolledTooltip>

        </>
    )
});


const columns = [
    { name: "tenderFeedID", data: "tenderFeedID", visible: false },
    {
        name: "closedTender",
        header: "",
        data: "closedTender",
        createdCell: (td, data) => {
            let ref = React.createRef();
            ReactDOM.render(
                data === true ? <>
                    <ClosedTooltip ref={ref} />
                </> : null, td)
        },
        width: "1%"
    },
    {
        name: "title",
        header: "Tender",
        data: (data) => {
            return data.tenderFeedID + '|' + data.title
        },
        createdCell: (td, data) => {
            ReactDOM.render(
                <a
                    href={`/tender-details?tid=${data.split('|')[0]}`}
                    onClick={(e) => { e.preventDefault(); history.push(`/tender-details?tid=${data.split('|')[0]}`) }}
                    style={{ color: '#00A65A' }}>
                    {data.split('|')[1]}
                </a>, td
            )
        },
        width: "74%"
    },
    {
        name: "dateExpressedInterest",
        header: "Actioned",
        data: (data) => { return (data.dateExpressedInterest === '' ? '-' : moment(data.dateExpressedInterest).format('DD/MM/YYYY')) },
        width: "25%"
    },
    {
        name: "archived",
        header: "",
        data: "archived",
        createdCell: (td, data) => {
            let ref = React.createRef();
            ReactDOM.render(
                data === true ? <>
                    <ArchivedTooltip ref={ref} />
                </> : null, td)
        },
        width: "1%"
    }
];

export default SupplierEOIs;