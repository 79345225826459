import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { authHeader } from '../../helpers/auth-header';
import { getApplicationType, logIntoPortal } from '../../helpers/general';
import { history } from '../../helpers/history';
import EmptyTableRow from '../controls/EmptyTableRow';
import Translation from '../controls/Translation';

const CompanyPortal_Modal = (props) => {
    let { id, isOpen, toggle, cardClass, panelName, tableName, scrollable } = props;

    const { contactID, supplierID } = props;
    const [companyPortals, setCompanyPortals] = useState([]);

    useEffect(() => {
        if (supplierID > 0) {
            // statusID is enum from CvExpiringTenders
            getApplicationType()
                .then(application => {
                    fetch(`./Suppliers/GetSuppliersActiveOrganiserLinks?supplierID=${supplierID}&contactID=${contactID}&systemName=${application}`, {
                        method: 'GET',
                        headers: authHeader(false, false)
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data) {
                                setCompanyPortals(data);
                            }
                        })
                        .catch(err => {
                            console.log("ERROR", err);
                        })
                });
        }
    }, [contactID, supplierID]);

    return (
        <Modal
            id={id}
            isOpen={isOpen}
            toggle={toggle}
            size="xl"
            centered
            backdrop="static"
            unmountOnClose
            scrollable={scrollable}
        >
            <ModalBody>
                <Card className={cardClass} >
                    <CardHeader className="ic-interest">
                        <span>
                            <Translation ID={panelName} />
                        </span>
                    </CardHeader>
                    <CardBody className='padding-5-1 card-module'>
                        <table id={tableName} className='table'>
                            <thead>
                                <tr>
                                    <th style={{ borderBottom: "0.0625rem solid var(--border-color)", borderTop: "0", fontWeight: "500", padding: "0.5rem 0.75rem" }}>Portals</th>
                                    <th width="15%"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(companyPortals).length > 0 &&
                                    <>
                                        {companyPortals.map((portal, i) => {
                                            return (
                                                <tr key={i} className={i % 2 === 0 ? "odd" : "even"}>
                                                    <td style={{ padding: "0.75rem 0.75rem" }}>
                                                        {portal.status === 0 &&
                                                            <a
                                                                href={`/save-search/${portal.uniqueID}`}
                                                                onClick={(e) => { e.preventDefault(); history.push(`/organisation?oid=${portal.uniqueID}`) }}
                                                                style={{ color: '#00A65A' }}>
                                                                {portal.portalName}
                                                            </a>
                                                        }
                                                        {portal.status !== 0 &&
                                                            <span style={{ color: '#757575', fontStyle: 'italic', verticalAlign: '-webkit-baseline-middle' }}>{portal.portalName}</span>
                                                        }
                                                    </td>
                                                    <td >
                                                        {/* STATUS = 0: Supplier has register and link is valid */}
                                                        {portal.status === 0 &&
                                                            <button className="c-cta" style={{ fontSize: ".875rem", width:"10em" }} onClick={() => { logIntoPortal(true, portal.url, supplierID, contactID, -1, portal.uniqueID, portal.portalName) }}>
                                                                Login to portal
                                                            </button>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </>
                                }
                                {Object.keys(companyPortals).length === 0 &&
                                    <EmptyTableRow />
                                }
                            </tbody>
                        </table>
                    </CardBody>
                </Card>
            </ModalBody>
            <ModalFooter>
                <div className="p-2 text-right col-6">
                    <Button id="close" color="danger" size="sm" onClick={toggle}>{'Close'}</Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default CompanyPortal_Modal;