import React from 'react';
import { checkEmailIsValid, getApplicationType, handleConfirm, handleRegistrationValidationCheck, handleRequiredCheckAlt, showError } from '../../helpers/general';
import Translation from '../controls/Translation';
import { authHeader } from '../../helpers/auth-header';

const $ = require('jquery');

const Summary = props => {
    let { regDetails, setRegDetails, setRegComplete, priceExcVat, packagesEnabled } = props;

    const handleRegistration = async () => {
        let bProceedRegistering = true;
        $('.is-invalid').each(function (o, e) {
            bProceedRegistering = false;
        });

        $("input[required]").each(function (i, obj) {
            if ($(this).val() === '' && !$(this).hasClass('is-invalid')) {
                let fieldCheck = false;

                if (this.type === 'email') {
                    checkEmailIsValid($(this).attr('id'), $(this).val(), 'REG_VALIDATION_default').then(vData => fieldCheck = vData);
                } else {
                    fieldCheck = handleRequiredCheckAlt($(this).attr('id'), $(this).val(), 'REG_VALIDATION_default');
                }

                bProceedRegistering = bProceedRegistering && fieldCheck;
            }
        });

        // Check if email is still OK before we Register / Pay
        if (!packagesEnabled || (regDetails.tempPlan && regDetails.tempPlan > 0)) {
            bProceedRegistering = await handleRegistrationValidationCheck(bProceedRegistering, true, true, regDetails.tempSupplierID);

            if (bProceedRegistering) {
                if (priceExcVat > 0) {
                    registerSupplier(false);
                } else {
                    handleConfirm(
                        `I confirm the details are correct, and to register my details`,
                        function () {
                            registerSupplier(true);
                        }
                    );
                }
            } else {
                showError("RegistrationFieldValidation");
            }
        } else {
            showError("NoPackageSelected");
        }
    }

    const registerSupplier = (sendEmail) => {
        let data = [];
        Object.entries(regDetails).forEach(ctrl => {
            let name = ctrl[0];
            let value = ctrl[1];

            if (value) {
                data.push({
                    field: name,
                    value: value.value ? parseInt(value.value) : value
                });
            }
        });

        fetch(`./Registration/StartRegistration`, {
            method: 'POST',
            headers: authHeader(false, true),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                if (data.success === true) {
                    setRegDetails(prevState => ({
                        ...prevState,
                        tempSupplierID: data.objData
                    }));

                    let array = [];

                    let IDs = JSON.parse(sessionStorage.getItem('RegLinkedPortals'));
                    if (IDs) {
                        IDs.map(obj => array.push(obj));
                    }

                    fetch(`./Registration/AddSelectedRegistrationPortals?registrationID=${data.objData}`, {
                        method: 'POST',
                        headers: authHeader(false, true),
                        body: JSON.stringify(array)
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data.success === true) {
                                if (sendEmail) {
                                    getApplicationType().then(application => {
                                        fetch(`./Registration/SendRegistrationVerificationEmail?regID=${data.objData}&applicationName=${application}`, {
                                            method: 'GET',
                                            headers: authHeader(false, true),
                                        })
                                            .then(response => response.json())
                                            .then(data => {
                                                if (data === true) {
                                                    sessionStorage.removeItem('RegLinkedPortals');
                                                    setRegComplete(true)
                                                } else {
                                                    showError(data.error, data.message);
                                                }
                                            })
                                            .catch(err => { });
                                    });
                                }
                                else {
                                    sessionStorage.removeItem('RegLinkedPortals');
                                    setRegComplete(true)
                                }
                            } else {
                                showError(data.error, data.message);
                            }
                        })
                        .catch(err => {
                            console.log("Error");
                        });
                } else {
                    showError(data.error, data.message);
                }
            })
            .catch(err => { });
    }

    return (
        <>
            <section className="c-multi-step-form__panel" inert={"true".toString()}>
                <div className="c-multi-step-form__panel-content card-body">
                    <h3 className="c-broken-underline u-component-spacing">
                        <Translation ID='RegistrationSummary' />
                    </h3>
                    <div className="c-registration-summary u-component-spacing--2x">
                        <div className="c-registration-summary__user-details">
                            <h4 className="u-branded-underline">User Details</h4>
                            <dl className="u-component-spacing--2x">
                                <dt><Translation ID="Name" /></dt>
                                <dd>{regDetails.contactTitle} {regDetails.contactFirstName} {regDetails.contactLastName}</dd>
                                <dt><Translation ID="EmailAddress" /></dt>
                                <dd>{regDetails.contactEmailAddress}</dd>
                                <dt><Translation ID="TelephoneNumber" /></dt>
                                <dd>{regDetails.contactTelephone}</dd>
                            </dl>
                        </div>
                        <div className="c-registration-summary__company-details">
                            <h4 className="u-branded-underline">Company Details</h4>
                            <dl className="u-component-spacing--2x">
                                <dt><Translation ID="CompanyName" /></dt>
                                <dd>{regDetails.companyName}</dd>
                                <dt><Translation ID="CompanyAddress" /></dt>
                                <dd>
                                    <div className="c-address">
                                        <span className="c-address__address-line">{regDetails.addressLine1}</span>
                                        <span className="c-address__address-line">{regDetails.addressLine2}</span>
                                        <span className="c-address__address-line">{regDetails.addressCity}</span>
                                        <span className="c-address__address-line">{regDetails.addressCounty}</span>
                                        <span className="c-address__address-line">{regDetails.addressCountry}</span>
                                        <span className="c-address__address-line">{regDetails.addressPostCode}</span>
                                    </div>
                                </dd>

                                <dt><Translation ID="CompanyRegNo" /></dt>
                                <dd>{regDetails.companyRegNo}</dd>

                                <dt><Translation ID="VATRegNo" /></dt>
                                <dd>{regDetails.vatRegNo}</dd>

                                <dt><Translation ID="CompanyEmail" /></dt>
                                <dd>{regDetails.companyEmail}</dd>

                                <dt><Translation ID="CompanyPhone" /></dt>
                                <dd>{regDetails.companyTelephone}</dd>

                                <dt><Translation ID="Website" /></dt>
                                <dd>{regDetails.companyWebsite}</dd>
                            </dl>
                        </div>

                    </div>

                    <div className="u-flex-end u-component-spacing--2x">
                        <button id="buttonSummary" className="c-cta" type='button' onClick={handleRegistration}>
                            {(priceExcVat > 0) ? 'Proceed to Checkout' : <Translation ID='ConfirmAndRegister' />}
                        </button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Summary;