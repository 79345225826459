import { authHeader } from '../helpers/auth-header';

export const PORTAL_REQUEST = 'PORTAL_REQUEST'
export const PORTAL_SUCCESS = 'PORTAL_SUCCESS'
export const PORTAL_FAILURE = 'PORTAL_FAILURE'

export const portalActions = {
    searchPortals
};

function request() { return { type: PORTAL_REQUEST }; }
function success(portals) { return { type: PORTAL_SUCCESS, portals }; }
function failure(error) { return { type: PORTAL_FAILURE, error }; }

function searchPortals(search, supplierID, contactID) {
    return dispatch => {
        dispatch(request());
        return new Promise((resolve, reject) => {
            fetch(`./Organisations/GetIntendPortals?strSearch=${search}&supplierID=${supplierID}&contactID=${contactID}`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(data => {
                    setTimeout(() => {
                        dispatch(success(data));
                        resolve(data);
                    }, 1000);
                }).catch(err => {
                    console.log("Error: ", err);
                    dispatch(failure(err));
                    reject(err);
                })
        });
    }
}