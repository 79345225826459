import React from 'react';

const Loader = props => {

    let visible = props.Visible;

    if (visible === true) {
        return (
            <div className="loader-bg" />
        );
    } else {
        return null;
    }
}

export default Loader;