import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { CardBody } from 'reactstrap';
import { authHeader } from '../../helpers/auth-header';
import { getApplicationType } from '../../helpers/general';
import { history } from '../../helpers/history';
import Translation from '../controls/Translation';
const moment = require('moment');

const SubscriptionDetails = props => {
    const { supplierID } = props;
    const [packageDetails, setPackageDetails] = useState({});

    useEffect(() => {
        getApplicationType()
            .then(application => {
                fetch(`./Suppliers/GetSupplierPackageDetails?supplierID=${supplierID}&systemName=${application}`, {
                    method: 'GET',
                    headers: authHeader()
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data) {
                            setPackageDetails({ package: data.packageName, expiryDate: data.expiryDate, allowances: data.allowanceDetails })
                        }
                    });
            });

        return () => {
            setPackageDetails({});
        }
    }, [supplierID])

    return (
        <>
            {Object.keys(packageDetails).length > 0 && packageDetails.package &&
                <React.Fragment>
                    <dl className="dl-grid">
                        <dt><Translation ID='Package' /></dt>
                        <dd><Translation ID={packageDetails.package} /></dd>

                        <dt>Expiry Date</dt>
                        <dd>Your subscription will expire on {moment(packageDetails.expiryDate).format('DD/MM/YYYY')}</dd>

                        {Object.keys(packageDetails.allowances).length > 0 &&
                            <>
                                {
                                    Object.entries(packageDetails.allowances).map((allowanceDetails, i) => {
                                        return (
                                            <React.Fragment key={`${i}`}>
                                                <dt>{allowanceDetails[1].serviceName}</dt>
                                                {allowanceDetails[1].allowance === '[[SHOW_TICK]]' &&
                                                    <dd style={{ color: "#22b322" }} >
                                                        <FontAwesomeIcon icon={faCheckCircle} style={{ marginRight: '20px' }} />
                                                    </dd>
                                                }
                                                {allowanceDetails[1].allowance === '[[SHOW_CROSS]]' &&
                                                    <dd style={{ color: "#b32222" }} >
                                                        <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '20px' }} />
                                                    </dd>
                                                }
                                                {allowanceDetails[1].allowance !== '[[SHOW_TICK]]' && allowanceDetails[1].allowance !== '[[SHOW_CROSS]]' &&
                                                    <>
                                                        {allowanceDetails[1].allowance === '-1' &&
                                                            <dd>
                                                                Unlimited
                                                            </dd>
                                                        }
                                                        {allowanceDetails[1].allowance !== '-1' &&
                                                            <dd>
                                                            <span style={{ marginRight: "1em" }}>Allowance: <strong>{allowanceDetails[1].allowance}</strong></span>[ <span style={{fontStyle: "italic" }}>{allowanceDetails[1].allowanceUsed} used / {allowanceDetails[1].allowanceRemaining}  remaining</span> ]
                                                            </dd>
                                                        }
                                                    </>
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </>
                        }
                    </dl>
                    <CardBody>
                        <div>
                            <p>To purchase more add-ons, you must upgrade from a free account.</p>
                            <input type="submit" className="c-cta u-component-spacing" value="Upgrade Package" onClick={() => history.push('/upgrade')} />
                        </div>
                    </CardBody>
                </React.Fragment>
            }
            {Object.keys(packageDetails).length > 0 && packageDetails.package === null &&
                <p>Package: No package details to show</p>
            }
        </>
    );
}

export default SubscriptionDetails;
