import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getSupportDetails, handleBackground } from '../../helpers/general';
import Footer from '../layout/Footer';
import NavMenu from '../layout/NavMenu';

const PasswordResetFailure = () => {
    const [supportDetails, setSupportDetails] = useState({ telephoneNo: '', email: '', address: '' });

    useEffect(() => {
        handleBackground();

        getSupportDetails().then(data => {
            setSupportDetails({ telephoneNo: data.telephoneNo, email: data.email, address: data.address });
        });
    }, []);

    return (
        <>
            <NavMenu />
            <div className="main-content u-container u-container--small container-fluid">
                <div className="u-flex-center u-component-spacing">
                    <h2 className="u-component-spacing u-branded-underline">Password Reset</h2>
                </div>

                <div className="row u-component-spacing">
                    <div className="col-md u-component-spacing--2x">
                        <div className="card main-content__inner" style={{ height: "calc(100% - 1rem)" }} >
                            <div className="card-body">
                                <div className="c-card card u-component-spacing" style={{ height: "calc(100% - 1rem)" }}>
                                    <div className="card-body">
                                        <h3 className="c-broken-underline u-component-spacing">Password Reset Unsuccessful</h3>
                                        <div className="c-form u-component-spacing--2x">

                                            <div className="c-form__input-group c-form__input-group--block-display">
                                                <label className='c-form__label' style={{ display: 'contents' }}>
                                                    We're sorry, we were unable to reset your password.
                                                </label>
                                            </div>
                                            <div className="c-form__input-group c-form__input-group--block-display">
                                                <label className='c-form__label'>
                                                    <p>Please contact our support team:</p>
                                                    <p>Email - <a href={`mailto:${supportDetails.email}`}>{supportDetails.email}</a></p>
                                                    <p>Phone - <a href={`tel:${supportDetails.telephoneNo}`}>{supportDetails.telephoneNo}</a></p>
                                                </label>
                                            </div>

                                            <div className="u-flex-end u-component-spacing">
                                                <Link to='/home'>Return to home page</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default PasswordResetFailure;