import React, { useEffect, useState } from 'react';
import { FormGroup, FormFeedback } from "reactstrap";
import Select2 from '../controls/Select2';
import Translation from '../controls/Translation';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import { handleRequiredCheck, handlePatternValidation, getRegexValidationRules, getRegex, checkEmailIsValid, handleCheckRegistrationExists } from '../../helpers/general';
import { authHeader } from '../../helpers/auth-header';

require('datatables.net-bs4');

const CompanyUserDetails = props => {
    let { regDetails, setRegDetails, handleNextClick } = props;
    const [titles, setTitles] = useState([]);
    const [regex, setRegex] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const togglePassword = () => setShowPassword(!showPassword);

    function handleChange(e) {
        e.persist();
        setRegDetails(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    }

    const handleSelect2Change = e => {
        e.persist();
        setRegDetails(prevState => ({
            ...prevState,
            [e.target.id]: e.target.value
        }));
    }

    useEffect(() => {
        fetch(`./SupplierUsers/GetNameTitles`, {
            method: 'GET',
            headers: authHeader(false, true)
        })
            .then(response => response.json())
            .then(data => {
                let vTitles = [];

                vTitles.push({ label: "Select..", value: "-1" });

                data.map(obj => vTitles.push({ label: obj.title, value: obj.title }));
                setTitles(vTitles);
            })
            .catch(error => console.log('Error: ', error))

        getRegexValidationRules().then(vData => {
            setRegex(vData);
        });

        return () => {
            setTitles([]);
        }
    }, []);

    const handleEmailOnBlur = async (e) => {
        await handleCheckRegistrationExists (e.target.id, e.target.value, regDetails.tempSupplierID)
            .then(data => {
                if (data && data.uniqueID === 2) {
                    data.objData.contactPassword = '';

                    const password = document.getElementById('contactPassword');
                    if (password) {
                        password.value = '';
                        password.classList.remove("is-invalid");
                        password.classList.remove("is-valid");
                    }

                    setRegDetails(data.objData)
                    return true;
                }
            })
    }

    return (
        <section className="c-multi-step-form__panel c-multi-step-form__panel--in-view">
            <form className="c-multi-step-form__panel-content card-body">
                <h3 className="c-broken-underline u-component-spacing">
                    <Translation ID="PersonalDetails" />
                </h3>
                <div className="c-form c-form--two-column u-component-spacing--2x">
                    {titles && titles.length > 0 &&
                        <div className='c-form__input-group'>
                            <Translation ID="Title" fieldName='contactTitle' />
                            <Select2
                                id={'contactTitle'}
                                options={titles}
                                change={(e) => handleSelect2Change(e)}
                                defaultValue={regDetails.contactTitle}
                            />
                        </div>
                    }
                    <div className='c-form__input-group'>
                        <Translation ID="FirstName" required htmlFor='contactFirstName' />
                        <FormGroup>
                            <input name="contactFirstName" id="contactFirstName" type="text" className='c-form__input form-control' placeholder="e.g. John"
                                defaultValue={regDetails.contactFirstName} required
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => handleRequiredCheck(e, 'VALIDATION_MandatoryFirstName')}
                            />
                            <FormFeedback>
                                <span id="contactFirstName_LABEL"></span>
                            </FormFeedback>
                        </FormGroup>


                    </div>

                    <div className='c-form__input-group'>
                        <Translation ID="LastName" required htmlFor='contactLastName' />
                        <FormGroup>
                            <input name="contactLastName" type="text" id="contactLastName" className='c-form__input form-control' placeholder="e.g. Smith"
                                defaultValue={regDetails.contactLastName} required
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => handleRequiredCheck(e, 'VALIDATION_MandatoryLastName')}
                            />
                            <FormFeedback>
                                <span id="contactLastName_LABEL"></span>
                            </FormFeedback>
                        </FormGroup>
                    </div>

                    <div className='c-form__input-group'>
                        <Translation ID="EmailAddress" required htmlFor='contactEmailAddress' />
                        <FormGroup>
                            <input name="contactEmailAddress" type="text" id="contactEmailAddress" className='c-form__input form-control' placeholder="e.g. john@smith.com"
                                defaultValue={regDetails.contactEmailAddress} autoComplete="nope" required
                                onChange={(e) => { handleChange(e); checkEmailIsValid(e.target.id, e.target.value, 'VALIDATION_EmailComplexity') }}
                                onBlur={(e) => handleEmailOnBlur(e)} />
                            <FormFeedback>
                                <span id="contactEmailAddress_LABEL"></span>
                            </FormFeedback>
                        </FormGroup>
                    </div>

                    <div className='c-form__input-group'>
                        <Translation ID="TelephoneNumber" required htmlFor='contactTelephone' />
                        <input name="contactTelephone" id="contactTelephone" className='c-form__input form-control' type="tel" placeholder="e.g. +44 0123 456 789"
                            defaultValue={regDetails.contactTelephone} required
                            onChange={(e) => handleChange(e)}
                            onBlur={(e) => handleRequiredCheck(e, 'VALIDATION_MandatoryTelephone')}
                        />
                        <FormFeedback>
                            <span id="contactTelephone_LABEL"></span>
                        </FormFeedback>
                    </div>

                    <div className='c-form__input-group'>
                        <Translation ID="Password" required htmlFor='contactPassword' />
                        <FormGroup>
                            <input name="contactPassword" type={showPassword ? 'text' : 'password'} id="contactPassword" className='c-form__input form-control' placeholder="Please enter a password"
                                required aria-describedby="password-requirements"
                                pattern={getRegex(regex, 'PasswordComplexity')}
                                onChange={(e) =>
                                    handlePatternValidation(e, 'VALIDATION_PasswordComplexityBelow')
                                        ? handleChange(e)
                                        : ""}
                                onBlur={(e) => handleRequiredCheck(e, 'VALIDATION_MandatoryPassword')
                                    ? handlePatternValidation(e, 'VALIDATION_PasswordComplexityBelow')
                                    : ""}
                            />
                            <button className="c-floating-label__show-password" type="button" onClick={togglePassword}>{showPassword ? 'Hide' : 'Show'}</button>
                            <FormFeedback>
                                <span id="contactPassword_LABEL"></span>
                            </FormFeedback>
                        </FormGroup>

                        <div id="password-requirements" className="c-password-requirements u-component-spacing">
                            <p className="c-password-requirements__text"><Translation ID='PasswordMustContain' customClass='' /></p>
                            <ul className="c-password-requirements__list">
                                <li className="c-password-requirements__list-item"><Translation ID='Atleast8Characters' customClass='' /></li>
                                <li className="c-password-requirements__list-item"><Translation ID='Atleast1Uppercase' customClass='' /></li>
                                <li className="c-password-requirements__list-item"><Translation ID='Atleast1Lowercase' customClass='' /></li>
                                <li className="c-password-requirements__list-item"><Translation ID='Atleast1Number' customClass='' /></li>
                                <li className="c-password-requirements__list-item"><Translation ID='Atleast1Special' customClass='' /></li>
                            </ul>
                        </div>
                    </div>
                    <div className="u-flex-end u-component-spacing--2x">
                        <button id="buttonCompanyUserDetails" className="js-change-panel c-cta" type='button' onClick={(e) => handleNextClick(e, 'contact') }>Next step: Company Details</button>
                    </div>	
                </div>
            </form>
        </section>
    )
}

export default CompanyUserDetails;