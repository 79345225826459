import { PACKAGES_REQUEST, PACKAGES_ENABLED_FAILURE, PACKAGES_ENABLED_SUCCESS } from "../actions/packages.actions"

const initialState = {
    isEnabled: false,
    isFirstTime: true
}

const PackagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case PACKAGES_REQUEST:
            return Object.assign({}, state, {
                isEnabled: state.isEnabled
            })
        case PACKAGES_ENABLED_SUCCESS:
            return Object.assign({}, state, {
                isEnabled: action.data, isFirstTime: false
            })
        case PACKAGES_ENABLED_FAILURE:
            return state
        default:
            return state
    }
}

export default PackagesReducer