import React from 'react';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
import Translation from './Translation';
import { Link } from 'react-router-dom';
import { CardHeader } from 'reactstrap';

require('bootstrap-datepicker');

const BackToDashboard = () => {
    return (
        <CardHeader className="card-header card-header--no-icon">
            <div>
                <Link to='/dashboard' className="c-back-button">
                    <Translation ID="BackToDashboard" />
                </Link>
            </div>
        </CardHeader>
    )
}

export default BackToDashboard;