import { faExpandAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { getApplicationType } from '../../helpers/general';
import { history } from '../../helpers/history';
import DataTable from '../controls/DataTable';
import Translation from '../controls/Translation';
import SupplierTendersModal from '../Tenders/SupplierTenders_Modal';

const moment = require('moment');
require('datatables.net-bs4');

const SupplierTenders = props => {
    const { supplierID, CardClass, PanelName, TableName, StatusID } = props;
    const [searchFilters, setSearchFilters] = useState({showAll: false, days: 28})
    const [finalSearchString, setFinalSearchString] = useState('');
    const [showModal, setShowModal] = useState(false);
    const toggle = () => setShowModal(!showModal);

    useEffect(() => {
        if (supplierID && supplierID > 0) {
            // statusID is enum from CvExpiringTenders
            let days = searchFilters.days === '' ? 0 : searchFilters.days;
            getApplicationType()
                .then(application => {
                    setFinalSearchString(`supplierID=${supplierID}&statusID=${StatusID}&showall=${searchFilters.showAll}&days=${days}&systemName=${application}`)
                });
        }
    }, [StatusID, searchFilters.days, searchFilters.showAll, supplierID]);

    const handleExpandClick = () => {
        toggle();
    }

    const handleChange = (e) => {
        e.persist();
        let val = e.target.value;

        setSearchFilters(value => ({
            ...value,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : val
        }));
    }

    const handleShowAllChange = (e) => {
        e.persist();

        if (!e.target.checked) {
            setSearchFilters(value => ({
                ...value,
                'days': 28
            }));
            document.getElementById(`${TableName}_days`).disabled = false;
        }
        else {
            setSearchFilters(value => ({
                ...value,
                'days': 0
            }));

            document.getElementById(`${TableName}_days`).disabled = true;
        }
    }

    return (
        <>
            {finalSearchString.length && supplierID &&
                <Card className={CardClass} >
                    <CardHeader className="ic-expiring">
                        <span>
                            <Translation ID={PanelName} />
                        </span>
                        <span style={{ justifyContent: 'right' }}>
                            <button id='expandWatched' onClick={(() => handleExpandClick())}>
                                <FontAwesomeIcon icon={faExpandAlt} style={{ marginRight: '20px' }} />
                            </button>
                        </span>
                    </CardHeader>
                    <CardBody className='padding-5-1 card-module'>
                        <Row className="c-widget-filter">
                            <Col md={12}>
                                <div className="c-widget-filter__deadline-container">
                                    <Translation ID='ShowingOpportunitiesWithDeadlineIn' htmlFor={`${TableName}_days`} customClass="u-visually-hidden" />
                                    <span aria-hidden="true"><Translation ID='ShowingOpportunitiesWithDeadlineIn' customClass="c-widget-filter__deadline-label c-form__label" /></span>
                                    <input id={`${TableName}_days`} name='days' type="number" className="c-widget-filter__deadline-input" value={searchFilters.days} onChange={((e) => handleChange(e))} />
                                    <span aria-hidden="true"><Translation ID='Days' /></span>
                                </div>
                            </Col>
                            <Col md={12} className='c-form__input-group'>
                                <div className="c-widget-filter__checkbox-container custom-checkbox custom-control">
                                    <input id={`${TableName}_showAll`} name="showAll" type="checkbox" className="custom-control-input" onChange={((e) => { handleShowAllChange(e); handleChange(e) })} />
                                    <Translation ID='ShowAll' htmlFor={`${TableName}_showAll`} customClass="c-widget-filter__checkbox c-search-section__label c-checkbox-label" />
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <DataTable
                            id={TableName}
                            columns={columns}
                            paging={false}
                            info={false}
                            defaultOrder={[1, "asc"]}
                            url="./Suppliers/GetSupplierTenders"
                            params={finalSearchString}
                            isSearching={true}
                            scrollY='300px'
                            pagingType='full'
                            scrollCollapse={true}
                        />
                    </CardBody>
                </Card>
            }

            <SupplierTendersModal
                id={`${TableName}_Modal`}
                isOpen={showModal}
                toggle={toggle}
                statusID={StatusID}
                cardClass={CardClass}
                panelName={PanelName}
                tableName={`${TableName}_Modal_DataTable`}
                supplierID={supplierID}
            />
        </>
    )
}

const columns = [
    { name: "tenderFeedID", data: "tenderFeedID", visible: false },
    {
        name: "title",
        header: "Tender",
        data: (data) => {
            return data.tenderFeedID + '|' + data.title
        },
        createdCell: (td, data) => {
            ReactDOM.render(
                <a
                    href={`/tender-details?tid=${data.split('|')[0]}`}
                    onClick={(e) => { e.preventDefault(); history.push(`/tender-details?tid=${data.split('|')[0]}`) }}
                    style={{ color: '#00A65A' }}>
                    {data.split('|')[1]}
                </a>, td
            )
        },
        width: "75%"
    },
    {
        name: "docsAvailableUntil",
        header: "Deadline Date",
        data: (data) => { return (data.docsAvailableUntil === '' ? '-' : moment(data.docsAvailableUntil).format('DD/MM/YYYY')) }, 
        width: "25%"
    }
];

export default SupplierTenders;