import React, { useEffect, useState } from "react";
import { Card, CardBody, CardColumns, Col, Row } from "reactstrap";
import { authHeader } from "../../helpers/auth-header";
import { getApplicationType, getContactID, getSupplierID, handleNoBusinessClassifications } from "../../helpers/general";
import { history } from '../../helpers/history';
import CompanyDetails from "../DashboardModules/CompanyDetails";
import CompanyPortals from "../DashboardModules/CompanyPortals";
import CompanyUsers from "../DashboardModules/CompanyUsers";
import SavedSearches from "../DashboardModules/SavedSearches";
import SupplierEOIs from '../DashboardModules/SupplierEOIs';
import SupplierTenders from "../DashboardModules/SupplierTenders";

require('datatables.net-bs4');

const Dashboard = () => {
    const [user, setUser] = useState({ supplierID: -1, contactID: -1 });
    const [userPermissions, setUserPermissions] = useState({ canRegisterPortal: false, canPurchaseProducts: false });
    const [systemNotifications, setSystemNotifications] = useState({ notifications: [], importantNotifications: [] });

    useEffect(() => {
        setUser(prev => ({
            ...prev,
            supplierID: getSupplierID(),
            contactID: getContactID()
        }))

        if (sessionStorage.getItem('ShowBusinessClassificationWarning')) {
            handleNoBusinessClassifications('You have no Classification Codes', 'It is recommended that you select all relevent codes - this gives you the benefit of potential opportunities and a direct invitiation from an organisation to tender.')
                .then(response => {
                    if (response === true) {
                        sessionStorage.setItem('Classifications', true);
                        history.push('/my-company');
                    }
                })
            sessionStorage.removeItem('ShowBusinessClassificationWarning')
        }
    }, []);

    useEffect(() => {
        getApplicationType()
            .then(application => {
                fetch(`./Settings/GetNotifications?systemName=${application}&loggedIn=${true}`, {
                    method: 'GET',
                    headers: authHeader(false, true)
                })
                    .then(response => response.json())
                    .then(notificationsData => {
                        if (notificationsData) {
                            setSystemNotifications({ notifications: notificationsData.notifications, importantNotifications: notificationsData.importantNotifications });
                        }
                    })
                    .catch(err => {
                        console.log("ERROR")
                        console.log(err);
                    });
            })
    }, []);

    useEffect(() => {
        if (user.contactID > 0) {
            fetch(`./Permissions/GetContactPermissions?contactID=${user.contactID}`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(permissionsData => {
                    if (permissionsData) {
                        setUserPermissions(permissionsData);
                    }
                })
                .catch(error => console.log('Error: ', error));
        }
    }, [user.contactID])

    return (
        <>
            {Object.keys(systemNotifications.importantNotifications).length > 0 &&
                <div className="c-system-notifications c-system-notifications--important">
                    <h2 className="c-system-notifications__title">Important Notifications</h2>
                    <ul className="c-system-notifications__list">
                        {systemNotifications.importantNotifications.map((notification, i) => {
                            return (
                                <li className="c-system-notifications__list-item" key={i}>
                                    <span dangerouslySetInnerHTML={{ __html: notification.notificationText }} />
                                </li>
                            )
                        })
                        }
                    </ul>
                </div>
            }

            {Object.keys(systemNotifications.notifications).length > 0 &&
                <div className="c-system-notifications">
                    <h2 className="c-system-notifications__title">System Notifications</h2>
                    <ul className="c-system-notifications__list">
                        {systemNotifications.notifications.map((notification, i) => {
                            return (
                                <li className="c-system-notifications__list-item" key={i}>
                                    <span dangerouslySetInnerHTML={{ __html: notification.notificationText }} />
                                </li>
                            )
                        })
                        }
                    </ul>
                </div>
            }
                
            <Row>
                <Col>
                    <Card className="main-content__inner">
                        <CardBody>
                            <CardColumns>
                                <SupplierEOIs
                                    supplierID={user.supplierID }
                                    CardClass='card-tenders c-card'
                                    PanelName='MyActiveOpportunties'
                                    TableName='supplierEOIs'
                                />
                                <SupplierTenders
                                    supplierID={user.supplierID}
                                    CardClass='card-tenders c-card'
                                    PanelName='WatchedTenders'
                                    TableName='watchedTenders'
                                    StatusID={2}
                                />
                                <SupplierTenders
                                    supplierID={user.supplierID}
                                    CardClass='card-tenders c-card'
                                    PanelName='ViewedTenders'
                                    TableName='viewedTenders'
                                    StatusID={1}
                                />
                                <CompanyPortals
                                    supplierID={user.supplierID}
                                    contactID={user.contactID}
                                    CardClass='card-company c-card'
                                    PanelName='MyCompanyPortals'
                                    TableName='suppliersActivePortals'
                                    userPermissions={userPermissions}
                                />
                                <SavedSearches
                                    supplierID={user.supplierID}
                                    contactID={user.contactID}
                                    CardClass='card-tenders c-card'
                                    PanelName='SavedSearches'
                                    TableName='suppliersTenderFeedSearch'
                                    userPermissions={userPermissions}
                                />
                                <CompanyDetails
                                    supplierID={user.supplierID}
                                    CardClass='card-company c-card'
                                    PanelName='CompanyDetails'
                                />
                                <CompanyUsers
                                    supplierID={user.supplierID}
                                    CardClass='card-company c-card'
                                    PanelName='CompanyUsers'
                                    TableName='companyUsers'
                                    userPermissions={userPermissions}
                                />
                            </CardColumns>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Dashboard;