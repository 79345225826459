import React, { useEffect, useState } from "react";
import NavMenu from '../layout/NavMenu';
import Footer from "../layout/Footer";
import { getSupportDetails, handleBackground } from '../../helpers/general';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { authHeader } from '../../helpers/auth-header';

const PaymentFailed = () => {
    let location = useLocation();
    const history = useHistory();
    const [tempSupplierID, setTempSupplierID] = useState('');
    const [supportDetails, setSupportDetails] = useState({ telephoneNo: '', email: '', address: '' });

    useEffect(() => {
        handleBackground();


        getSupportDetails().then(data => {
            setSupportDetails({ telephoneNo: data.telephoneNo, email: data.email, address: data.address });
        });
    }, []);

    useEffect(() => {
        if (location.state && location.state.invoiceNo) {
            fetch(`./Payment/GetRegistrationIDBySell2PaymentID?sell2PaymentID=${location.state.invoiceNo}`, {
                method: 'GET',
                headers: authHeader(false, true),
            })
                .then(response => response.json())
                .then(data => {
                    setTempSupplierID(data);
                })
                .catch(err => {
                    console.log('error');
                });
        }
    }, [location.state]);

    const handleTryPaymentAgain = () => {
        history.push({
            pathname: '/register', state: { paymentTryAgain: true, tempSupplierID: tempSupplierID }
        })
    }

    return (
        <>
            <NavMenu />
            <div className="main-content u-container u-container--small container-fluid">
                <div className="u-flex-center u-component-spacing">
                    <h2 className="u-component-spacing u-branded-underline">Payment Failed</h2>
                </div>

                <div className="row u-component-spacing">
                    <div className="col-md u-component-spacing--2x">
                        <div className="card main-content__inner" style={{ height: "calc(100% - 1rem)" }} >
                            <div className="card-body">
                                <div className="c-card card u-component-spacing" style={{ height: "calc(100% - 1rem)" }}>
                                    <div className="card-body">
                                        <>
                                            <h3 className="c-broken-underline u-component-spacing">Problem Processing Payment</h3>
                                            <div className="c-form u-component-spacing--2x">
                                                <p>
                                                    Your card has not been charged.
                                                </p>
                                                <p>
                                                    There was a problem processing your payment
                                                    {location.state && location.state.errorMessage &&
                                                        <>
                                                            <span> with the following error: </span>
                                                            <span><strong>{location.state.errorMessage}</strong></span>
                                                        </>
                                                    }.
                                                </p>
                                                <p>
                                                    Below are some points to help with resolving the issue:
                                                </p>
                                                <ul>
                                                    <li style={{paddingTop: "0.5rem"}}>Only VISA and Mastercard can be used</li>
                                                    <li style={{ paddingTop: "0.5rem" }}>The card number is valid</li>
                                                    <li style={{ paddingTop: "0.5rem" }}>The Expiry Date is valid and current</li>
                                                    <li style={{ paddingTop: "0.5rem" }}>The 3 digit securiy code is correct for the card</li>
                                                </ul>

                                                <p>
                                                    If you still require further assistance, please contact Sell2 support on <strong><a className="u-no-wrap" href={`tel:${supportDetails.telephoneNo}`}>{supportDetails.telephoneNo}</a></strong> or <strong><a href={`mailto:${supportDetails.email}`}>{supportDetails.email}</a></strong>
                                                    {
                                                        location.state && location.state.invoiceNo &&
                                                        <>
                                                            <span> and quote the reference </span><span className="u-no-wrap"><strong>{location.state.invoiceNo}</strong></span>
                                                        </>
                                                    }
                                                </p>

                                                {tempSupplierID !== "" &&
                                                    <p>
                                                        <button className="js-change-panel c-cta" onClick={() => handleTryPaymentAgain()}>
                                                            Return to Payment
                                                        </button>
                                                    </p>
                                                }
                                            </div>
                                        </>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default PaymentFailed;