import React, { useEffect, useState } from "react";
import { FormGroup, FormFeedback } from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";
import NavMenu from '../layout/NavMenu';
import { handleBackground, handleRequiredCheck, getResourceString, showCustomError, checkEmailIsValid, sendForgottenPassword } from "../../helpers/general";
import Footer from "../layout/Footer";
import { Link } from "react-router-dom";

require('datatables.net-bs4');

const ForgottenPassword = () => {
    const [passwordSent, setPasswordSent] = useState(false);
    const [emailAddress, setemailAddress] = useState({});

    useEffect(() => {
        handleBackground();
    }, []);

    const handleSubmit = e => {
        let input = document.getElementById('email');

        if (emailAddress && emailAddress.email.length) {
            // If the input has not fulfilled the regex requirement - OR - the input has the class 'is-invalid' then start set success to failed
            if (input.validationMessage !== '' && input.classList.contains('is-invalid')) {
                // If the input has not got the is-invalid class - so it has not yet been verified by a user clicking in the input, then process the validation
                if (!input.classList.contains('is-invalid')) {
                    document.getElementById(input.id).classList.remove("is-valid");  // Remove green check
                    document.getElementById(input.id).classList.add("is-invalid");   // Add red X

                    getResourceString('VALIDATION_PatterDefault').then(data => {
                        document.getElementById(`${input.name}_LABEL`).innerText = data;
                    })
                }
                return false;
            }

            sendForgottenPassword(emailAddress.email)
                .then(response => {
                    if (response) {
                        setPasswordSent(true);
                    }
                    else {
                        showCustomError('There has been a problem sending the email - please make sure the email address is valid and also registered with the system.', 'Problem with the email address', 'Error')
                    }
                })
        }
    }


    const handleChange = e => {
        e.persist();

        setemailAddress(emailValue => ({
            ...emailValue,
            [e.target.name]: e.target.value
        }))
    }

    const handleEmailOnChange = (e) => {
        //if (handlePatternValidation(e.target.id, e.target.value, 'VALIDATION_EmailComplexity')) {
        if (checkEmailIsValid(e.target.id, e.target.value, 'VALIDATION_EmailComplexity')) {
            handleChange(e);
        }
    }

    const handleOnBlur = (e) => {
        if (handleRequiredCheck(e, 'VALIDATION_MandatoryEmail')) {
            (checkEmailIsValid(e.target.id, e.target.value, 'VALIDATION_EmailComplexity'));
        }
    }

    return (
        <>
            <NavMenu />
            <div className="main-content u-container u-container--small container-fluid">
                <div className="u-flex-center u-component-spacing" style={{ color: "rgb(255, 255, 255)" }}>
                    <h2 className="u-component-spacing u-branded-underline u-inverted-text">Forgotten Password</h2>
                </div>

                <div className="row u-component-spacing">
                    <div className="col-md u-component-spacing--2x">
                        <div className="card main-content__inner" style={{ height: "calc(100% - 1rem)" }} >
                            <div className="card-body">
                                <div className="c-card card u-component-spacing" style={{ height: "calc(100% - 1rem)" }}>
                                    <div className="card-body">
                                        {!passwordSent &&
                                            <>
                                                <h3 className="c-broken-underline u-component-spacing">What is your email address</h3>
                                                <div className="c-form u-component-spacing--2x">

                                                    <div className="c-form__input-group c-form__input-group--block-display">
                                                        <label htmlFor='email' className='c-form__label--required c-form__label'>
                                                            Please enter your account email address below
                                                        </label>
                                                        <FormGroup className="position-relative">
                                                        <input name="email" id="email" type="text" defaultValue='' className='c-form__input form-control' required
                                                            onChange={(e) => handleEmailOnChange(e)}
                                                            onBlur={(e) => handleOnBlur(e)} />
                                                            <FormFeedback>
                                                                <span id="email_LABEL"></span>
                                                            </FormFeedback>
                                                        </FormGroup>
                                                    </div>

                                                    <div className="u-flex-end u-component-spacing">
                                                        <button className="js-change-panel c-cta" data-target="1" onClick={() => handleSubmit()}>Send password reset email</button>
                                                    </div>
                                                </div>
                                            </>}

                                        {passwordSent &&
                                            <>
                                                <h3 className="c-broken-underline u-component-spacing">Email sent</h3>
                                                <div className="u-component-spacing--2x">
                                                    <p>An email has been sent to your email address.  Please check your inbox for instructions as to how you can reset your password.</p>
                                                    <div className="u-flex-end u-component-spacing--3x">
                                                        <Link to='/home'>Return to home page</Link>
                                                    </div>
                                                </div>
                                            </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default ForgottenPassword;