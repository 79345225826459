import classnames from 'classnames';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Card, CardBody, Col, CustomInput, FormFeedback, FormGroup, FormText, Input, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { authHeader } from '../../helpers/auth-header';
import {
    checkEmailIsValid, getApplicationType, getContactID, getSupplierID, handleConfirm, handleDuplicateCheck, handleEmailCheck, handleRequiredCheck, handleRequiredCheckAlt, handleSQRequiredCheck, handleSQRequiredCheckAlt,
    handleUpdateSupplierPortalDetails, pageEnabled, showCustomError, showError
} from '../../helpers/general';
import BackToDashboard from '../controls/BackToDashboard';
import Select2 from '../controls/Select2';
import Translation from '../controls/Translation';
import Certificates from './Certificates';
import ClassificationTypes from './ClassificationTypes';
import Insurance from './Insurance';
import SubscriptionDetails from './SubscriptionDetails';

const $ = require('jquery');

const SupplierProfile = () => {
    const [user, setUser] = useState({ supplierID: -1, contactID: -1 });
    const [tabList, setTabList] = useState([]);
    const [activeTab, setActiveTab] = useState('tab-0');
    const [supplierDetails, setSupplierDetails] = useState({});
    const [countries, setCountries] = useState([]);
    const [companyClassifications, setCompanyClassifications] = useState([]);
    const [supplierCompanyClassifications, setSupplierCompanyClassifications] = useState(null);
    const [standardQuestionnaire, setStandardQuestionnaire] = useState([]);
    const [standardQuestionnaireAnswers, setStandardQuestionnaireAnswers] = useState([]);
    const [sqGUID, setSqGUID] = useState(null);
    const [validationFields, setValidationFields] = useState([]);
    const [navItemEnabled, setNavItemEnabled] = useState({ sq: true, subscription: true });
    const [updateDetailsPermission, setUpdateDetailsPermission] = useState(false);
    const [isPageEnabled, setIsPageEnabled] = useState(false);

    useEffect(() => {
        pageEnabled('Nav_Account', '/404').then(enabled => { setIsPageEnabled(enabled); });

        setUser(prev => ({
            ...prev,
            supplierID: getSupplierID(),
            contactID: getContactID()
        }))
    }, [])

    const toggle = useCallback(tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);

            var supplierTab = document.getElementById('tab-select');

            if (supplierTab !== null) {
                supplierTab.value = tab;
            }
        }
    }, [activeTab])

    useEffect(() => {
        fetch(`./Countries/GetCountries`, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => response.json())
            .then(data => {
                let vCountries = [];

                vCountries.push({ label: "Select..", value: "-1" });

                data.map(obj => vCountries.push({ label: obj.title, value: obj.abbrev }));
                setCountries(vCountries);
            })
            .catch(err => {
                console.log(err);
            });

        if (sessionStorage.getItem('Classifications')) {
            toggle('tab-2');
            sessionStorage.removeItem('Classifications');
        } else if (sessionStorage.getItem('CompanyClassifications')) {
            toggle('tab-3')
            sessionStorage.removeItem('CompanyClassifications');
        }
    }, [toggle])


    useEffect(() => {
        if (user.contactID > 0) {
            fetch(`./Permissions/CanUserChangeCompanyDetails?contactID=${user.contactID}`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        setUpdateDetailsPermission(data);
                    }
                })
                .catch(error => console.log('Error: ', error));

        }
        fetch(`./Settings/GetWebSetting?settingName=ENABLE_STANDARDQUESTIONNAIRE`, {
            method: 'GET',
            headers: authHeader(false, true)
        })
            .then(response => response.json())
            .then(data => {
                setNavItemEnabled(prev => ({ ...prev, sq: (data === "1") }));
            })
            .catch(error => {
                console.log('Error: ', error);
            })

        fetch(`./Settings/ArePackagesEnabled`, {
            method: 'GET',
            headers: authHeader(false, true)
        })
            .then(response => response.json())
            .then(data => {
                setNavItemEnabled(prev => ({ ...prev, subscription: (data === true) }));
            })
            .catch(error => {
                console.log('Error: ', error);
            });

        fetch(`./StandardQuestionnaire/GetStandardQuestionnaireJSON`, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => response.json())
            .then(data => {
                setStandardQuestionnaire(data);
            })
            .catch(err => {
                console.log(err);
            });

        getApplicationType().then(application => {
            fetch(`./Suppliers/GetSupplierTabList?systemName=${application}`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(data => {
                    setTabList(data);
                })
                .catch(err => {
                    console.log(err);
                });
        });

        if (user.supplierID > 0) {
            fetch(`./Suppliers/GetSupplierDetails?supplierID=${user.supplierID}`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(data => {
                    setSupplierDetails(data);
                    setSqGUID(data.sqguid);
                })
                .catch(err => {
                    console.log(err);
                });

            fetch(`./Suppliers/GetSupplierCompanyClassifications?supplierID=${user.supplierID}`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(data => {
                    setSupplierCompanyClassifications(data);
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }, [user.contactID, user.supplierID])

    useEffect(() => {
        if (sqGUID !== "0") {
            fetch(`./StandardQuestionnaire/GetStandardQuestionnaireAnswers?supplierGUID=${sqGUID}`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(data => {
                    setStandardQuestionnaireAnswers(data.sqAnswers);
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }, [sqGUID])

    useEffect(() => {
        if (standardQuestionnaireAnswers) {
            standardQuestionnaireAnswers.answers?.forEach((answer, i) => {
                var element = $('#' + answer.questionID);
                // basic element?
                if (element[0]) {
                    if (element[0].type) {
                        if (element[0].type === 'date') {
                            var date = answer.answer.split('/').reverse().join('-');
                            element.text = date;
                            element.val(date);
                        }
                        else if (element[0].type === 'checkbox' && answer.answer === "True") {
                            element[0].checked = true;
                        }
                        else {
                            element.val(answer.answer);
                        }
                    }
                    else {
                        element[0].childNodes.forEach((node, i) => {
                            if (node.childNodes[1].textContent === answer.answer.toString()) {
                                node.childNodes[0].checked = true;
                            }
                        });
                    }
                }
            });
        }
    }, [standardQuestionnaireAnswers])

    useEffect(() => {
        if (supplierCompanyClassifications) {
            fetch(`./CompanyClassifications/GetCompanyClassification`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(data => {
                    let vClassifications = [];

                    data.map(obj => vClassifications.push({ label: obj.title, uniqueID: obj.uniqueID }));
                    setCompanyClassifications(vClassifications);
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }, [supplierCompanyClassifications])

    useEffect(() => {
        if (standardQuestionnaire.length > 0) {
            var fieldValidations = new Map();
            var questionList = new Map();
            standardQuestionnaire.forEach(section => {
                section.questions.forEach(question => {
                    questionList.set(question.questionID, question.question);
                    if (question.validation && question.validation.startsWith("Fields")) {
                        fieldValidations.set(question.questionID, question.validation);
                    }
                    if (question.subQuestions) {
                        question.subQuestions.forEach(subQ => {
                            questionList.set(subQ.subQuestionID, subQ.subQuestion);
                        })
                    }
                })
            })
            setValidationFields(fieldValidations);
        }
    }, [standardQuestionnaire])

    function handleChange(e) {
        e.persist();
        let val = e.target.value;

        if (e.target.type === 'date') {
            val = moment(val).format('DD/MM/YYYY HH:mm').toString();
        }

        setSupplierDetails(value => ({
            ...value,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : val
        }));
    }

    function handleCompanyClassificationChange(e) {
        let newArray = supplierCompanyClassifications;

        if (Number(e.target.value)) {
            let value = Number(e.target.value);

            if (e.target.checked) {
                newArray.push(value);
            } else {
                const index = newArray.indexOf(value);
                if (index > -1) {
                    newArray.splice(index, 1);
                }
            }

            setSupplierCompanyClassifications([...new Set(newArray)]);
        }
    }

    const handleSelect2Change = e => {
        e.persist();
        setSupplierDetails(prevState => ({
            ...prevState,
            [e.target.id]: e.target.value
        }));

        if (e.target.id === 'country') {
            var selectedValue = e.target.value === '-1' ? '' : e.target.value;
            handleRequiredCheckAlt(e.target.id, selectedValue, 'VALIDATION_MandatoryCountry');
        }
    }

    const handleSave = () => {
        let bProceedRegistering = true;
        let failedFields = [];
        $('.is-invalid').each(function (o, e) {
            bProceedRegistering = false;
            failedFields.push($(this).attr('id'))
        });

        $("input[required]").each(async function (i, obj) {
            if ($(this).val() === '' && !$(this).hasClass('is-invalid')) {
                let fieldCheck = false;

                if (this.type === 'email') {
                    checkEmailIsValid($(this).attr('id'), $(this).val(), 'VALIDATION_default')
                        .then(vData => fieldCheck = vData);
                } else {
                    fieldCheck = handleRequiredCheckAlt($(this).attr('id'), $(this).val(), 'VALIDATION_default');
                }

                if (!fieldCheck) { failedFields.push($(this).attr('id')) }

                bProceedRegistering = bProceedRegistering && fieldCheck;
            }
        });

        if (bProceedRegistering) {
            if (user.supplierID > 0) {
                handleConfirm(
                    `<p>Save changes to the company profile?</p><hr /><p style={{marginBottom: "0.5em"}}><strong>PLEASE NOTE: </strong>This will also update any active portals linked with your Sell2 account.<p>`,
                    function () {
                        saveDetails();
                    }
                );
            };
        } else {
            showError("CompanyProfileFieldValidation").then(data => {
                // Toggle tab of the first field to error
                let invalidInput = $(`#${failedFields[0]}`);
                let closest = invalidInput.closest('.tab-pane');
                let id = closest.attr('id');

                toggle(id);

                document.getElementById(failedFields[0]).focus();
            })
        }
    }

    const handleSaveSQ = () => {
        let bProceedRegistering = true;
        let failedFields = [];

        $('.is-invalid').each(function (i, obj) {
            bProceedRegistering = false;
            failedFields.push(obj.attributes.id.value);
        });

        $("input[requiredsq]").each(async function (i, obj) {
            if (obj.attributes.requiredsq.value === 1) {
                if (obj.value === '' && !obj.classList.contains('is-invalid')) {
                    let fieldCheck = false;

                    if (/^S\d+Q\d+$/.test(obj.attributes.id.value)) {
                        fieldCheck = handleSQRequiredCheckAlt(obj, obj.value, obj.attributes.type.value, 'VALIDATION_default', obj.attributes.mandatory.value, validationFields);
                    }
                    else {
                        fieldCheck = handleRequiredCheckAlt(obj.attributes.id.value, obj.value, 'VALIDATION_default');
                    }

                    if (!fieldCheck) { failedFields.push(obj.attributes.id.value) }

                    bProceedRegistering = bProceedRegistering && fieldCheck;
                }
            }
        });


        $("textarea[requiredsq]").each(async function (i, obj) {
            if (obj.attributes.requiredsq.value === 1) {
                if (obj.value === '' && !obj.classList.contains('is-invalid')) {
                    let fieldCheck = false;

                    if (/^S\d+Q\d+$/.test(obj.attributes.id.value)) {
                        fieldCheck = handleSQRequiredCheckAlt(obj, obj.value, obj.attributes.type.value, 'VALIDATION_default', obj.attributes.mandatory.value, validationFields);
                    }

                    if (!fieldCheck) { failedFields.push(obj.attributes.id.value) }

                    bProceedRegistering = bProceedRegistering && fieldCheck;
                }
            }
        });
        $("div[sq]").each(async function (i, obj) {
            var parentCounter = 0;
            obj.childNodes.forEach((radio, i) => {
                if (radio.childNodes[0].checked) { parentCounter++; }
            })
            if (parentCounter === 0) {
                handleSQRequiredCheckAlt(obj, obj.value, obj.attributes.type.value, 'VALIDATION_default', obj.attributes.mandatory.value, validationFields);
            }
        });

        if (bProceedRegistering) {
            if (user.supplierID) {
                handleConfirm(
                    `<p>Save Standard Questionnaire Answers?</p><hr />`,
                    function () {
                        saveSQ();
                    }
                );
            };
        } else {

            showError("CompanyProfileFieldValidation").then(data => {
                // Toggle tab of the first field to error
                let invalidInput = $(`#${failedFields[0]}`);
                let closest = invalidInput.closest('.tab-pane');
                let id = closest.attr('id');
                toggle(id);

                document.getElementById(failedFields[0]).focus();
            })
        }
    }

    const saveDetails = () => {
        if (user.contactID && user.supplierID) {
            let data = [];
            Object.entries(supplierDetails).forEach(ctrl => {
                let name = ctrl[0];
                let value = ctrl[1];

                data.push({
                    field: name,
                    value: value.value ? parseInt(value.value) : value
                });
            });

            fetch(`./Suppliers/UpdateSupplierDetails?supplierID=${user.supplierID}&contactID=${user.contactID}`, {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify(data)
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success === true) {
                        fetch(`./Suppliers/UpdateCompanyClassifications?supplierID=${user.supplierID}`, {
                            method: 'POST',
                            headers: authHeader(),
                            body: JSON.stringify(supplierCompanyClassifications.join(','))
                        })
                            .then(response => response.json())
                            .then(data => {
                                if (data === true) {
                                    handleUpdateSupplierPortalDetails(user.supplierID);
                                } else {
                                    console.log("Error");
                                    showCustomError('Error while save - please try again', 'Problem Updating', 'error');
                                }
                            })
                            .catch(err => {
                                console.log("Error");
                            });
                    } else {
                        console.log("Error");
                        showError(data.error, data.message);
                    }
                })
                .catch(err => {
                    console.log("Error");
                });
        }
    }

    const formatDateDefault = (value) => {
        if (value) {
            if (value.includes('1899') || value.includes('1900')) {
                return '';
            } else {
                return value ? moment(value).format('YYYY-MM-DD').toString() : '';
            }
        }
    }

    const handleEmailOnBlur = e => {
        handleEmailCheck(e.target.id, e.target.value, 'VALIDATION_MandatoryCompanyEmail', 'VALIDATION_EmailComplexity', 'Suppliers', e.target.id, 'VALIDATION_PublishEmailExists', '', '',  user.supplierID)
    } 

    const handleNameOnBlur = e => {

        if (handleRequiredCheck(e, 'VALIDATION_MandatoryCompanyName')) {
            handleDuplicateCheck(e, 'Suppliers', 'VALIDATION_CompanyNameExists', user.supplierID);
        }
    }

    const getSQControlType = (question) => {
        switch (question.fieldType.toLowerCase()) {
            case "text":
                return (<FormGroup>
                    <Input
                        bsSize="sm"
                        className="c-form__input"
                        id={`${question.questionID}`}
                        onBlur={(e) => handleSQRequiredCheck(e, 'VALIDATION_default', question.validation, validationFields)}
                        requiredsq={(question.validation && question.validation.toLowerCase() === "mandatory") ? 1 : 0}
                        mandatory={question.validation}
                    />
                    <FormFeedback>
                        <span id={`${question.questionID}_LABEL`}></span>
                    </FormFeedback>
                </FormGroup>)

            case "big text":
                return (<FormGroup>
                    <Input
                        type="textarea"
                        className="SQtextarea"
                        id={`${question.questionID}`}
                        onBlur={(e) => handleSQRequiredCheck(e, 'VALIDATION_default', question.validation, validationFields)}
                        requiredsq={(question.validation && question.validation.toLowerCase() === "mandatory") ? 1 : 0}
                        mandatory={question.validation}
                    />
                    <FormFeedback>
                        <span id={`${question.questionID}_LABEL`}></span>
                    </FormFeedback>
                </FormGroup >)

            case "number":
                return (<FormGroup>
                    <Input
                        bsSize="sm"
                        type="number"
                        className="c-form__input"
                        id={`${question.questionID}`}
                        onBlur={(e) => handleSQRequiredCheck(e, 'VALIDATION_default', question.validation, validationFields)}
                        requiredsq={(question.validation && question.validation.toLowerCase() === "mandatory") ? 1 : 0}
                        mandatory={question.validation}
                    />
                    <FormFeedback>
                        <span id={`${question.questionID}_LABEL`}></span>
                    </FormFeedback>
                </FormGroup >)

            case "date":
                return (<FormGroup>
                    <Input
                        type="date"
                        autoComplete="false"
                        className="c-form__input"
                        id={`${question.questionID}`}
                        onBlur={(e) => handleSQRequiredCheck(e, 'VALIDATION_default', question.validation, validationFields)}
                        requiredsq={(question.validation && question.validation.toLowerCase() === "mandatory") ? 1 : 0}
                        mandatory={question.validation}
                    />
                    <FormFeedback>
                        <span id={`${question.questionID}_LABEL`}></span>
                    </FormFeedback>
                </FormGroup >)

            case "checkbox":
                return (<FormGroup>
                    <CustomInput
                        type="checkbox"
                        className="ml-4"
                        id={`${question.questionID}`}
                        label=""
                        onBlur={(e) => handleSQRequiredCheck(e, 'VALIDATION_default', question.validation, validationFields)}
                        requiredsq={(question.validation && question.validation.toLowerCase() === "mandatory") ? 1 : 0}
                        mandatory={question.validation}
                    />
                    <FormFeedback>
                        <span id={`${question.questionID}_LABEL`}></span>
                    </FormFeedback>
                </FormGroup >)

            case "radio":
                return (<FormGroup>
                    <div id={`${question.questionID}`} sq="true" className="radio-inline" mandatory={question.validation}>{
                        question.subQuestions.map((item, i) => {
                            return <CustomInput
                                key={i}
                                type="radio"
                                className="ml-4"
                                id={`rdo${item.subQuestionID}`}
                                label={item.subQuestion}
                                name={question.questionID}
                                onBlur={(e) => handleSQRequiredCheck(e, 'VALIDATION_default', question.validation, validationFields)}
                                requiredsq={(question.validation && question.validation.toLowerCase() === "mandatory") ? 1 : 0}
                                mandatory={question.validation}
                            />
                        })}
                    </div>
                    <FormFeedback>
                        <span id={`${question.questionID}_LABEL`}></span>
                    </FormFeedback>
                </FormGroup >
                )

            default:
                return null;
        }
    }

    const saveSQ = () => {
        let sqAnswers = [];
        if (standardQuestionnaire.length > 0) {
            standardQuestionnaire.forEach(section => {
                section.questions.forEach(question => {
                    var inputAnswer = null;
                    if (question.subQuestions) {
                        question.subQuestions.forEach(subQ => {
                            if ($(document.getElementById(`rdo${subQ.subQuestionID}`)).is(':checked')) {
                                inputAnswer = subQ.subQuestion;
                            }
                        })
                    }
                    else {
                        if (question.fieldType.toLowerCase() === "checkbox") {
                            inputAnswer = $(document.getElementById(question.questionID)).is(':checked').toString();
                        }
                        else {
                            inputAnswer = $(document.getElementById(question.questionID)).val();
                        }
                    }
                    var answer = {
                        "QuestionID": question.questionID,
                        "Question": question.question,
                        "Answer": inputAnswer
                    }
                    sqAnswers.push(answer);
                })
            })
        }
        var newSQAnswers = {
            "answers": sqAnswers
        }
        setStandardQuestionnaireAnswers(newSQAnswers);

        fetch(`./StandardQuestionnaire/SendSQAnswers?strGUID=${sqGUID}`, {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify(newSQAnswers)
        }).then(response => response.json())
            .then(data => {
                if (data !== "Error" && data !== 0) {
                    setSqGUID(data);
                    fetch(`./Suppliers/SetSupplierGUID?strGUID=${data}&supplierID=${user.supplierID}`, {
                        method: 'POST',
                        headers: authHeader()
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data === Error.NoError || data === Error.DuplicateSQGUID) {
                                // Success (new save or already has GUID set)
                            } else {
                                showCustomError('Error while saving Standard Questionnaire Answers - please try again', 'Problem Updating', 'error');
                            }
                        })
                        .catch(err => {
                            console.log("Error");
                        });
                }
            })
            .catch(err => {
                console.log("Error");
            });
    }

    const handleTabMenu = useCallback((tabMenu, event) => {
        if (tabMenu) {
            tabMenu.querySelector('#' + event.target.value).click();
        }
    }, [])


    useEffect(() => {
        if (Object.keys(tabList).length > 0) {
            const tabSelect = document.querySelector('.js-tab-select');
            const tabMenu = document.querySelector('.' + tabSelect.dataset.tabTarget);

            tabSelect.addEventListener('change', event => {
                handleTabMenu(tabMenu, event);
            });

            return function cleanup() {
                window.removeEventListener('change', handleTabMenu());
            }
        }
    }, [handleTabMenu, tabList])

    if (isPageEnabled === true && supplierDetails && Object.keys(tabList).length > 0) {
        return (
            <>
                <Card className='main-content__inner'>
                    <BackToDashboard />

                    <CardBody>
                        <Card className='c-card u-component-spacing'>
                            <CardBody>
                                <h2 className='c-broken-underline'>
                                    {supplierDetails.name}
                                </h2>

                                <div className='u-component-spacing--2x'>
                                    <div className="c-tab-select">
                                        <label className="c-tab-select__label" htmlFor="tab-select">View:</label>
                                        <select id="tab-select" className="c-tab-select__select js-tab-select" data-tab-target="js-main-tabs" defaultValue={`tab-${activeTab}`}>
                                            {Object.entries(tabList).map((tab, i) => {
                                                if (tab[1].active) {
                                                    return (
                                                        <option key={i} value={tab[1].tabName} >{tab[0]}</option>
                                                    )
                                                } else {
                                                    return null;
                                                }
                                            })}
                                        </select>
                                    </div>
                                    <div className="c-adjacent-tabs">
                                        <div className='tabs'>
                                            <Nav tabs className="js-main-tabs">
                                                {Object.entries(tabList).map((tab, i) => {
                                                    if (tab[1].active) {
                                                        return (
                                                            <NavItem key={i}>
                                                                <NavLink
                                                                    className={classnames({ active: activeTab === `${tab[1].tabName}` })}
                                                                    id={`${tab[1].tabName}`}
                                                                    data-toggle='tab'
                                                                    aria-controls={`${tab[1].tabName}`}
                                                                    aria-label={`${tab[0].toLowerCase()}`}
                                                                    onClick={() => { toggle(`${tab[1].tabName}`); }}
                                                                >
                                                                    {`${tab[0]}`}
                                                                </NavLink>
                                                            </NavItem>
                                                        )
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                            </Nav>
                                        </div>

                                        <TabContent activeTab={activeTab}>
                                            <TabPane id='tab-0' tabId='tab-0'>
                                                <div className='tab-pane__inner'>
                                                    <div className='c-form'>

                                                        <div className='c-form__input-group'>
                                                            <Translation ID="CompanyName" required htmlFor='name' />
                                                            <FormGroup>
                                                                <input name='name' id="name" type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.name} required
                                                                    onChange={(e) => handleChange(e)}
                                                                    onBlur={(e) => handleNameOnBlur(e)}
                                                                />
                                                                <FormFeedback>
                                                                    <span id="name_LABEL"></span>
                                                                </FormFeedback>
                                                            </FormGroup>
                                                        </div>

                                                        <div className='c-form__input-group'>
                                                            <Translation ID="OtherName" htmlFor='otherName' />
                                                            <input name='otherName' id='otherName' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.otherName} onChange={(e) => handleChange(e)} />
                                                        </div>

                                                        <div className='c-form__input-group'>
                                                            <Translation ID="BuildingName" required htmlFor='building' customClass='c-form__label c-form__label--allow-wrap' />
                                                            <FormGroup>
                                                                <input name='building' id="building" type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.building} required
                                                                    onChange={(e) => handleChange(e)}
                                                                    onBlur={(e) => handleRequiredCheck(e, 'VALIDATION_MandatoryBuilding')}
                                                                />
                                                                <FormFeedback>
                                                                    <span id="building_LABEL"></span>
                                                                </FormFeedback>
                                                            </FormGroup>
                                                        </div>

                                                        <div className='c-form__input-group'>
                                                            <Translation ID="Street" required htmlFor='street' />
                                                            <FormGroup className="position-relative">
                                                                <input name='street' id='street' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.street} required
                                                                    onChange={(e) => handleChange(e)}
                                                                    onBlur={(e) => handleRequiredCheck(e, 'VALIDATION_MandatoryStreet')}
                                                                />
                                                                <FormFeedback>
                                                                    <span id="street_LABEL"></span>
                                                                </FormFeedback>
                                                            </FormGroup>
                                                        </div>

                                                        <div className='c-form__input-group'>
                                                            <Translation ID="TownCity" required htmlFor='city' />
                                                            <FormGroup className="position-relative">
                                                                <input name='city' id='city' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.city} required
                                                                    onChange={(e) => handleChange(e)}
                                                                    onBlur={(e) => handleRequiredCheck(e, 'VALIDATION_MandatoryTown')}
                                                                />
                                                                <FormFeedback>
                                                                    <span id="city_LABEL"></span>
                                                                </FormFeedback>
                                                            </FormGroup>
                                                        </div>

                                                        <div className='c-form__input-group'>
                                                            <Translation ID="CountyState" htmlFor='state' />
                                                            <input name='state' id='state' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.state} onChange={(e) => handleChange(e)} />
                                                        </div>

                                                        {countries && Object.keys(countries).length !== 0 && Object.keys(supplierDetails).length !== 0 &&
                                                            <div className="c-form__input-group">
                                                                <Translation ID="Country" required={true} htmlFor='country' />
                                                                <FormGroup className="position-relative">
                                                                    <Select2
                                                                        id='country'
                                                                        options={countries}
                                                                        defaultValue={supplierDetails.country}
                                                                        required={true}
                                                                        disabled={!updateDetailsPermission}
                                                                        change={(e) => handleSelect2Change(e)}
                                                                    />
                                                                    <FormFeedback>
                                                                        <span id="country_LABEL"></span>
                                                                    </FormFeedback>
                                                                </FormGroup>
                                                            </div>
                                                        }

                                                        <div className='c-form__input-group'>
                                                            <Translation ID="Postcode" required htmlFor='postalCode' />
                                                            <FormGroup className="position-relative">
                                                                <input name='postalCode' id='postalCode' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.postalCode} required
                                                                    onChange={(e) => handleChange(e)}
                                                                    onBlur={(e) => handleRequiredCheck(e, 'VALIDATION_MandatoryPostcode')}
                                                                />
                                                                <FormFeedback>
                                                                    <span id="postCode_LABEL"></span>
                                                                </FormFeedback>
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane id='tab-1' tabId='tab-1'>
                                                <div className='tab-pane__inner'>
                                                    <div className='c-form'>
                                                        <div className='c-form__input-group'>
                                                            <Translation ID="CompanyEmail" required htmlFor='publishEmail' />
                                                            <FormGroup className="position-relative">
                                                                <input name='publishEmail' id='publishEmail' type='email' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.publishEmail} required
                                                                    onChange={(e) => { handleChange(e); checkEmailIsValid(e.target.id, e.target.value, 'VALIDATION_EmailComplexity'); }}
                                                                    onBlur={(e) => handleEmailOnBlur(e)} />
                                                                <FormFeedback>
                                                                    <span id="publishEmail_LABEL"></span>
                                                                </FormFeedback>
                                                            </FormGroup>
                                                        </div>

                                                        <div className='c-form__input-group'>
                                                            <Translation ID="EnquiriesEmail" htmlFor='emailAdminEnquires' />
                                                            <input name='emailAdminEnquires' id='emailAdminEnquires' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.emailAdminEnquires} onChange={(e) => handleChange(e)} />
                                                        </div>
                                                        <div className='c-form__input-group'>
                                                            <Translation ID="HealthAndSafetyEmail" htmlFor='emailHealthSafety' customClass='c-form__label c-form__label--allow-wrap' />
                                                            <input name='emailHealthSafety' id='emailHealthSafety' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.emailHealthSafety} onChange={(e) => handleChange(e)} />
                                                        </div>
                                                        <div className='c-form__input-group'>
                                                            <Translation ID="Website" htmlFor='webSite' />
                                                            <input name='webSite' id='webSite' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.webSite} onChange={(e) => handleChange(e)} />
                                                        </div>

                                                        <div className='c-form__input-group'>
                                                            <Translation ID="TelephoneNumber" required htmlFor='phone' />
                                                            <FormGroup className="position-relative">
                                                                <input name="phone" id="phone" className='c-form__input' disabled={!updateDetailsPermission} type="text" defaultValue={supplierDetails.phone} required
                                                                    onChange={(e) => handleChange(e)}
                                                                    onBlur={(e) => handleRequiredCheck(e, 'VALIDATION_MandatoryTelephone')}
                                                                />
                                                                <FormFeedback>
                                                                    <span id="phone_LABEL"></span>
                                                                </FormFeedback>
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane id='tab-2' tabId='tab-2'>
                                                <div className='tab-pane__inner'>
                                                    <ClassificationTypes
                                                        supplierID={user.supplierID}
                                                        isActive={activeTab === 'tab-2'}
                                                        updateDetailsPermission={updateDetailsPermission}
                                                    />
                                                </div>
                                            </TabPane>
                                            <TabPane id='tab-3' tabId='tab-3'>
                                                {companyClassifications && Object.keys(companyClassifications).length > 0 &&
                                                    <div className='tab-pane__inner'>
                                                        <div className='c-form'>
                                                            {companyClassifications.map((collection, i) => {
                                                                return (
                                                                    <div className="c-form__input-group" style={{ maxWidth: "100% !important" }} key={i}>
                                                                        <div className="custom-checkbox custom-control" style={{ marginLeft: "10%" }}>
                                                                            <input id={`classification_${collection.uniqueID}`} name={`classification_${collection.uniqueID}`} disabled={!updateDetailsPermission} value={collection.uniqueID} type="checkbox" className="custom-control-input" defaultChecked={supplierCompanyClassifications.includes(collection.uniqueID)} onChange={(e) => handleCompanyClassificationChange(e)} />
                                                                            <label htmlFor={`classification_${collection.uniqueID}`} className='c-search-section__label c-checkbox-label'>{collection.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </TabPane>
                                            <TabPane id='tab-4' tabId='tab-4'>
                                                <div className='tab-pane__inner'>
                                                    <div className='c-form'>
                                                        <div className='c-form__input-group'>
                                                            <Translation ID="NumberOfStaff" htmlFor='noOfStaff' />
                                                            <input name='noOfStaff' id='noOfStaff' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.noOfStaff} onChange={(e) => handleChange(e)} />
                                                        </div>
                                                        <div className='c-form__input-group'>
                                                            <Translation ID="NumberOfContractors" htmlFor='noOfContractors' />
                                                            <input name='noOfContractors' id='noOfContractors' type='select' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.noOfContractors} onChange={(e) => handleChange(e)} />
                                                        </div>

                                                        <div className="c-form__input-group">
                                                            <div className="custom-checkbox custom-control">
                                                                <input id="vatRegistered" name="vatRegistered" type="checkbox" className="custom-control-input" disabled={!updateDetailsPermission} checked={supplierDetails.vatRegistered || false} onChange={(e) => handleChange(e)} />
                                                                <Translation ID="VATRegistered" htmlFor='vatRegistered' customClass='c-search-section__label c-checkbox-label' />
                                                            </div>
                                                        </div>

                                                        <div className="c-form__input-group">
                                                            <div className="custom-checkbox custom-control">
                                                                <input id="vatApplicable" name="vatApplicable" type="checkbox" className="custom-control-input" disabled={!updateDetailsPermission} checked={supplierDetails.vatApplicable || false} onChange={(e) => handleChange(e)} />
                                                                <Translation ID="VATApplicable" htmlFor='vatApplicable' customClass='c-search-section__label c-checkbox-label' />
                                                            </div>
                                                        </div>


                                                        <div className='c-form__input-group'>
                                                            <Translation ID="VATRegNo" htmlFor='vatRegNo' />
                                                            <FormGroup className="position-relative">
                                                                <input name='vatRegNo' id='vatRegNo' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.vatRegNo}
                                                                    onChange={(e) => handleChange(e)}
                                                                    onBlur={(e) => handleDuplicateCheck(e, 'Suppliers', 'VALIDATION_VATNoExists', user.supplierID)}
                                                                />
                                                                <FormText>
                                                                    <Translation ID="VATRegNoExample" />
                                                                </FormText>
                                                                <FormFeedback>
                                                                    <span id="vatRegNo_LABEL"></span>
                                                                </FormFeedback>
                                                            </FormGroup>
                                                        </div>
                                                        <div className='c-form__input-group'>
                                                            <Translation ID="CompanyRegNo" htmlFor='companyRegNo' customClass='c-form__label c-form__label--allow-wrap' />
                                                            <FormGroup className="position-relative">
                                                                <input name='companyRegNo' id='companyRegNo' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.companyRegNo}
                                                                    onChange={(e) => handleChange(e)}
                                                                    onBlur={(e) => handleDuplicateCheck(e, 'Suppliers', 'VALIDATION_CompanyRegExists', user.supplierID)}
                                                                />
                                                                <FormFeedback>
                                                                    <span id="companyRegNo_LABEL"></span>
                                                                </FormFeedback>
                                                            </FormGroup>
                                                        </div>
                                                        <div className='c-form__input-group'>
                                                            <Translation ID="DUNSNumber" htmlFor='duns' />
                                                            <input name='duns' id='duns' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.duns} onChange={(e) => handleChange(e)} />
                                                        </div>
                                                        <div className='c-form__input-group'>
                                                            <Translation ID="ConstructionLine" htmlFor='constructionlineRegNo' customClass='c-form__label c-form__label--allow-wrap' />
                                                            <input name='constructionlineRegNo' id='constructionlineRegNo' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.constructionlineRegNo} onChange={(e) => handleChange(e)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane id='tab-5' tabId='tab-5'>
                                                <div className='tab-pane__inner'>
                                                    <Certificates
                                                        supplierDetails={supplierDetails}
                                                        handleChange={handleChange}
                                                        formatDateDefault={formatDateDefault}
                                                        updateDetailsPermission={updateDetailsPermission}
                                                    />
                                                </div>
                                            </TabPane>
                                            <TabPane id='tab-6' tabId='tab-6'>
                                                <div className='tab-pane__inner'>
                                                    <Insurance
                                                        supplierDetails={supplierDetails}
                                                        handleChange={handleChange}
                                                        formatDateDefault={formatDateDefault}
                                                        updateDetailsPermission={updateDetailsPermission}
                                                    />
                                                </div>
                                            </TabPane>

                                            {navItemEnabled.sq &&
                                                <TabPane id='tab-7' tabId='tab-7'>
                                                    <div className='tab-pane__inner'>
                                                        <div className='c-form'>
                                                            <Alert color="primary">
                                                                <p style={{ textAlign: "center" }} > <Translation ID="StandardQuestionnaireHeading" /></p>
                                                            </Alert>
                                                            <div className='c-form__input-group'>
                                                                <Translation ID="StandardQuestionnaireKey" />
                                                                <label id='standardQuestionnaireKey'>{supplierDetails.sqguid === "0" ? '' : supplierDetails.sqguid}</label>
                                                            </div>
                                                            <div>
                                                                Standard Questionnaire:
                                                            </div>
                                                            <div>
                                                                {
                                                                    standardQuestionnaire.length ?
                                                                        standardQuestionnaire.map((section, i) => {
                                                                            let count = 1;
                                                                            return (
                                                                                <Row key={i} className="mb-3">
                                                                                    <Col>
                                                                                        <Row className="mb-2">
                                                                                            <Col>
                                                                                                <b className="">Section {section.sectionID.replace("S", "")}</b>   <p className="c-form__label">{section.section}</p>
                                                                                            </Col>
                                                                                        </Row>
                                                                                        <Row className="mb-2">
                                                                                            <Col>
                                                                                                {section.questions.map((question, j) => {
                                                                                                    return (
                                                                                                        <Row key={j} className="mb-2">
                                                                                                            <Col style={{ whiteSpace: 'pre-wrap' }}>
                                                                                                                {question.fieldType !== "Label"
                                                                                                                    ? <b className={question.validation === "Mandatory" ? 'c-form__label--required' : 'c-form__label'}>Question {count++}</b>
                                                                                                                    : <b></b>
                                                                                                                }
                                                                                                                <p className="c-form__label c-form__label--allow-wrap">{question.question.replaceAll("<br/>", "\n")}</p>
                                                                                                            </Col>
                                                                                                            {question.fieldType !== "Label"
                                                                                                                ? <Col className="mt-4">
                                                                                                                    {getSQControlType(question)}
                                                                                                                </Col> : null}
                                                                                                        </Row>
                                                                                                    )
                                                                                                })}
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                </Row>
                                                                            );
                                                                        })
                                                                        : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPane>
                                            }
                                            {navItemEnabled.subscription &&
                                                <TabPane id='tab-8' tabId='tab-8'>
                                                    <div className='tab-pane__inner'>
                                                        <SubscriptionDetails
                                                            supplierID={user.supplierID}
                                                            isActive={activeTab === 'tab-8'}
                                                        />
                                                    </div>
                                                </TabPane>
                                            }
                                        </TabContent>
                                    </div>
                                    <div className='u-flex-end u-component-spacing'>
                                        {activeTab === 'tab-7'
                                            ? <button type='button' className='c-form__submit' onClick={() => handleSaveSQ()} disabled={activeTab !== 'tab-7'}>
                                                <Translation ID='SaveSQ' />
                                            </button>
                                            : <button type='button' className='c-form__submit' onClick={() => handleSave()} disabled={activeTab === 'tab-7'}>
                                                <Translation ID='SaveAndUpdate' />
                                            </button>
                                        }
                                    </div>
                                </div>

                            </CardBody>
                        </Card>
                    </CardBody>
                </Card>
            </>
        );
    } else {
        return null;
    }
}

const Error = {
    NoError: 0,
    DuplicateSQGUID: 25,
};

export default SupplierProfile;