import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { authHeader } from "../../helpers/auth-header";
import { getApplicationType, getContactID, getSupplierID, pageEnabled, useQuery } from "../../helpers/general";
import TenderSearch from "../Tenders/TenderSearch";
import TenderSearchResults from "../Tenders/TenderSearchResults";

require('datatables.net-bs4');
const moment = require('moment');

const Tenderers = () => {
    const [user, setUser] = useState({ supplierID: -1, contactID: -1 });
    const [isSearching, setIsSearching] = useState(true);
    const [searchParams, setSearchParams] = useState({ searchObject: {}, prevLocation: '' });
    const [saveSearchTitle, setSaveSearchTitle] = useState('');
    const [firstLoad, setFirstLoad] = useState(true);
    const [isPageEnabled, setIsPageEnabled] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);

    let query = useQuery();
    let searchID = query.get('searchid');
    let location = useLocation();

    useEffect(() => {
        if (user.supplierID !== -1) {
            pageEnabled(user.supplierID > 0 ? 'Nav_Tenders' : 'Nav_PublicTenders', '/404').then(enabled => { setIsPageEnabled(enabled); });
        }
    }, [user.supplierID])

    useEffect(() => {
        setUser(prev => ({
            ...prev,
            supplierID: getSupplierID(),
            contactID: getContactID()
        }))
    }, [])

    useEffect(() => {
        if (user.supplierID >= 0) {
            if (location.state && location.state.searchObject) {
                setSearchParams({
                    searchObject: location.state.searchObject,
                    prevLocation: location.pathname
                });
            } else {
                getApplicationType()
                    .then(applicationName => {
                        if (searchID && searchID.length) {
                            fetch(`./Suppliers/GetSupplierSavedSearch?supplierID=${user.supplierID}&searchID=${searchID}`, {
                                method: 'GET',
                                headers: authHeader()
                            })
                                .then(response => response.json())
                                .then(data => {
                                    if (data.searchString) {
                                        setSearchParams({
                                            searchObject: JSON.parse(data.searchString),
                                            prevLocation: location.pathname
                                        });
                                        setIsSearching(true)
                                    } else {
                                        setSearchParams({
                                            searchObject: {
                                                keywords: '',
                                                region: '',
                                                organisationid: '0',
                                                dateAddedFrom: moment().subtract(120, 'days').format('YYYY-MM-DD'),
                                                dateAddedTo: moment().format('YYYY-MM-DD'),
                                                dateEOIFrom: '',
                                                dateEOITo: '',
                                                orderBy: 0,
                                                orderByDirection: 0,
                                                matchToCPV: false,
                                                includeEOIPassed: false,
                                                includeEOI: false,
                                                includeIgnored: false,
                                                includeCompanyEntries: false,
                                                supplierID: user.supplierID,
                                                systemName: applicationName
                                            },
                                            prevLocation: location.pathname
                                        });
                                    }
                                    setSaveSearchTitle(data.searchDescription);
                                })
                                .catch(err => {
                                    console.log(err);
                                });
                        } else {
                            setSearchParams({
                                searchObject: {
                                    keywords: '',
                                    region: '',
                                    organisationid: 0,
                                    dateAddedFrom: moment().subtract(120, 'days').format('YYYY-MM-DD'),
                                    dateAddedTo: moment().format('YYYY-MM-DD'),
                                    dateEOIFrom: '',
                                    dateEOITo: '',
                                    orderBy: 0,
                                    orderByDirection: 0,
                                    matchToCPV: false,
                                    includeEOIPassed: false,
                                    includeEOI: false,
                                    includeIgnored: false,
                                    includeCompanyEntries: false,
                                    supplierID: user.supplierID,
                                    systemName: applicationName
                                },
                                prevLocation: location.pathname
                            });
                        }
                    });
            }
            setFirstLoad(false);
        }
    }, [location.pathname, location.state, searchID, user.supplierID])


    if (isPageEnabled === true) {
        return (
            <main>
                {user.supplierID > 0 &&
                    <>
                        <TenderSearch
                            setIsSearching={setIsSearching}
                            searchParams={searchParams}
                            setSearchParams={setSearchParams}
                            searchID={searchID}
                            setSaveSearchTitle={setSaveSearchTitle}
                            saveSearchTitle={saveSearchTitle}
                            supplierID={user.supplierID}
                            setPageNumber={setPageNumber}
                        />

                        <TenderSearchResults
                            searchParams={searchParams}
                            isSearching={isSearching}
                            pages
                            loggedIn
                            setIsSearching={setIsSearching}
                            firstLoad={firstLoad}
                            layoutViewMode='TendersViewMode'
                            pageNumber={pageNumber}
                            setPageNumber={setPageNumber}
                        />
                    </>
                }
            </main>
        )
    } else {
        return null;
    }
}

export default Tenderers;