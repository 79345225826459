import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { authHeader } from '../../helpers/auth-header';
import DataTable from '../controls/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import Translation from '../controls/Translation';

const $ = require('jquery');
require('datatables.net-bs4');

const Classifications = props => {
    let { supplierID, filterSupplier, classificationTypeID, classificationType, updateDetailsPermission } = props;

    const [isSearching, setIsSearching] = useState(false);
    const [finalSearchParams, setFinalSearchParams] = useState({ searchObject: {} });

    const handleEnterKey = (e) => {
        if (e.key === 'Enter') {
            searchData();
        }
    }

    const searchData = () => {
        let searchText = document.getElementById(`businessClassificationSearch_${classificationTypeID}`);
        if (searchText !== null) {
            setFinalSearchParams({
                searchObject: {
                    businessClassificationSearch: searchText.value,
                    filterSupplier: filterSupplier,
                    supplierID: supplierID,
                    businessClassificationTypeID: classificationTypeID } })
            setIsSearching(true);
        }
    }

    function handleAddClick(row, data) {
        if (updateDetailsPermission) {
            fetch(`./Suppliers/AddTendererClassification?supplierID=${supplierID}&businessClassificationID=${data.UniqueID}`, {
                method: 'POST',
                headers: authHeader(),
                body: JSON.stringify('')
            })
                .then(response => response.json())
                .then(data => {
                    if (data === true) {
                        $(row).addClass("d-none");

                        let tableSupplierClassificationCodes = $(`#SupplierClassificationCodes_${classificationTypeID}Table`).DataTable();

                        if (tableSupplierClassificationCodes) {
                            tableSupplierClassificationCodes.ajax.reload();
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }

    function handleRemoveClick(row, data) {
        if (updateDetailsPermission) {
            fetch(`./Suppliers/RemoveTendererClassification?supplierID=${supplierID}&businessClassificationID=${data.UniqueID}`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(data => {
                    if (data === true) {
                        // Commented out the classification redraw due to speed issues
                        let tableSupplierClassificationCodes = $(`#SupplierClassificationCodes_${classificationTypeID}Table`).DataTable();

                        if (tableSupplierClassificationCodes) {
                            tableSupplierClassificationCodes.ajax.reload();
                        }
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }

    return (
        <>
            <div className="c-transfer-windows">
                <div className="c-transfer-windows__window">
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div className="u-flex-center u-component-spacing"><h4 className="u-branded-underline">Your <Translation ID={classificationType.replace(' ', '')} /></h4></div>
                                    <div className="card-success card u-component-spacing--2x">
                                        <DataTable
                                            id={`SupplierClassificationCodes_${classificationTypeID}`}
                                            columns={supplierColumns}
                                            sorting={false}
                                            paging={false}
                                            info={false}
                                            defaultOrder={[1, "asc"]}
                                            url="./Suppliers/GetSupplierClassificationCodes"
                                            params={`businessClassificationType=${classificationTypeID}&supplierID=${supplierID}`}
                                            isSearching={false}
                                            scrollY='550px'
                                            pagingType='full'
                                            scrollCollapse={true}
                                            onClick={handleRemoveClick}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div className="c-transfer-windows__window">
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    {finalSearchParams &&
                                        <>

                                        <div className="u-flex-center">
                                            <h4 className="u-branded-underline u-component-spacing">Add  <Translation ID={classificationType.replace(' ', '')} /></h4>
                                        </div>
                                        <div className="u-flex-center u-component-spacing--2x">
                                            <div className="c-curved-input">
                                                <div className="c-floating-label">
                                                    <input className="c-floating-label__input" data-error-message="" placeholder=" " type="search" name={`businessClassificationSearch`} id={`businessClassificationSearch_${classificationTypeID}`}
                                                        onKeyUp={(e) => handleEnterKey(e)} />
                                                    <label className="c-floating-label__label" htmlFor={`businessClassificationSearch_${classificationTypeID}`}>Search by Code or Keyword</label>
                                                </div>
                                                <input type="submit" className="c-curved-input__submit c-cta u-component-spacing" value="Search" onClick={searchData} />
                                            </div>
                                        </div>
                                        <div className="card-success card u-component-spacing--2x">
                                            {isSearching &&
                                                <DataTable
                                                    id={`ClassificationCodes_${classificationTypeID}`}
                                                    columns={addClassificationColumns}
                                                    sorting={false}
                                                    paging={false}
                                                    info={false}
                                                    defaultOrder={[1, "asc"]}
                                                    url="./BusinessClassifications/GetBusinessClassifications"
                                                    params={`filter=${JSON.stringify(finalSearchParams.searchObject)}`}
                                                    isSearching={isSearching}
                                                    scrollY='550px'
                                                    pagingType='full'
                                                    scrollCollapse={true}
                                                    onClick={handleAddClick}
                                                />
                                            }
                                            </div>
                                        </>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div className="c-transfer-windows__transfer-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" clipRule="evenodd" viewBox="0 0 88 88">
                        <path fill="#cccccc" d="M0 29.7V14.3h61V0l22 22-22 22V29.7H0Z" />
                        <path fill="#ebebeb" d="M88 58.3v15.4H27V88L5 66l22-22v14.3h61Z" />
                    </svg>
                </div>
            </div>
        </>
    )
}

const addClassificationColumns = [
    { name: "UniqueID", data: "UniqueID", visible: false },
    { name: "Code", data: "Code", width: "25%" },
    { name: "Description", data: "Description" },
    {
        name: "code",
        header: ' ',
        data: "Code",
        createdCell: (td) => {
            ReactDOM.render(<FontAwesomeIcon icon={faPlus} className={'green-icon'} />, td);
        },
        width: "1%"
    }
];

const supplierColumns = [
    { name: "UniqueID", data: "UniqueID", visible: false },
    { name: "Code", data: "Code", width: "25%" },
    { name: "Description", data: "Description" },
    {
        name: "code",
        header: ' ',
        data: "Code",
        createdCell: (td) => {
            ReactDOM.render(<FontAwesomeIcon icon={faMinus} className={'green-icon'} />, td);
        },
        width: "1%"
    }
];

export default Classifications;