import React, { useEffect, useState } from "react";
import { FormGroup, FormFeedback, Label } from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";
import NavMenu from '../layout/NavMenu';
import { handleBackground, handleRequiredCheck, handlePatternValidation, getRegexValidationRules, getRegex, isValidUUID } from "../../helpers/general";
import Footer from "../layout/Footer";
import { useLocation } from "react-router-dom";
import Translation from "../controls/Translation";
import { history } from '../../helpers/history';

require('datatables.net-bs4');

// This is to allow the SPA to use parameters - as the routing only allows useParams when directed from within the application
function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PasswordReset = () => {
    const [regex, setRegex] = useState([]);
    const [value, setValue] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const togglePassword = () => setShowPassword(!showPassword);

    let query = useQuery();
    const rid = query.get('rid');

    useEffect(() => {
        handleBackground();

        getRegexValidationRules().then(vData => {
            setRegex(vData);
        });
    }, []);


    useEffect(() => {
        if (rid.length === 0) {
            history.push('/home');
        } else if (isValidUUID(rid)) {
            fetch(`./SupplierUsers/VerifyPasswordReset?obGuid=${rid}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (!data) {
                        history.push('/home');
                    }
                })
                .catch(err => console.log('Err:', err));
        } else {
            history.push('/home');
        }
    }, [rid]);


    const handleChange = e => {
        e.persist();

        setValue(value => ({
            ...value,
            [e.target.name]: e.target.value
        }))
    }

    const handleResetPassword = e => {
        //e.preventDefault();

        fetch(`./SupplierUsers/ResetPassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({ 'Guid': rid, 'Password': value.newPassword })
        })
            .then(response => response.json())
            .then(data => {
                if (data) {
                    history.push('/password-success');
                } else {
                    history.push('/password-failure');
                }
            })
            .catch(err => console.log("Error: ", err));
    }

    return (
        <>
            <NavMenu />
            <div className="main-content u-container u-container--small container-fluid">
                <div className="u-flex-center u-component-spacing" style={{ color: "rgb(255, 255, 255)" }}>
                    <h2 className="u-component-spacing u-branded-underline">Forgotten Password</h2>
                </div>

                <div className="row u-component-spacing">
                    <div className="col-md u-component-spacing--2x">
                        <div className="card main-content__inner" style={{ height: "calc(100% - 1rem)" }} >
                            <div className="card-body">
                                <div className="c-card card u-component-spacing" style={{ height: "calc(100% - 1rem)" }}>
                                    <div className="card-body">
                                        <h3 className="c-broken-underline u-component-spacing">Reset Password</h3>
                                        <div className="c-form u-component-spacing--2x">

                                            <div className="c-form__input-group c-form__input-group--block-display">
                                                <label htmlFor='newPassword' className='c-form__label--required c-form__label'>
                                                    Please enter a new password for your account
                                                </label>
                                                <div className="c-floating-label u-component-spacing">
                                                    <FormGroup>
                                                        <input
                                                            name="newPassword"
                                                            type={showPassword ? 'text' : 'password'}
                                                            className="c-floating-label__input form-control"
                                                            placeholder=" "
                                                            id="newPassword"
                                                            defaultValue=''
                                                            required
                                                            aria-describedby="password-requirements"
                                                            pattern={getRegex(regex, 'PasswordComplexity')}
                                                            onChange={(e) =>
                                                                handlePatternValidation(e, 'VALIDATION_PasswordComplexityBelow')
                                                                    ? handleChange(e)
                                                                    : ""}
                                                            onBlur={(e) => handleRequiredCheck(e, 'VALIDATION_MandatoryEmail')
                                                                ? handlePatternValidation(e, 'VALIDATION_PasswordComplexityBelow')
                                                                : ""}
                                                        />
                                                        <Label className="c-floating-label__label" htmlFor="newPassword">Password</Label>
                                                        <button className="c-floating-label__show-password" type="button" onClick={togglePassword}>{showPassword ? 'Hide' : 'Show'}</button>
                                                        <FormFeedback>
                                                            <span id="newPassword_LABEL"></span>
                                                        </FormFeedback>
                                                    </FormGroup>
                                                </div>

                                                <div id="password-requirements" className="c-password-requirements u-component-spacing">
                                                    <p className="c-password-requirements__text"><Translation ID='PasswordMustContain' customClass='' /></p>
                                                    <ul className="c-password-requirements__list">
                                                        <li className="c-password-requirements__list-item"><Translation ID='Atleast8Characters' customClass='' /></li>
                                                        <li className="c-password-requirements__list-item"><Translation ID='Atleast1Uppercase' customClass='' /></li>
                                                        <li className="c-password-requirements__list-item"><Translation ID='Atleast1Lowercase' customClass='' /></li>
                                                        <li className="c-password-requirements__list-item"><Translation ID='Atleast1Number' customClass='' /></li>
                                                        <li className="c-password-requirements__list-item"><Translation ID='Atleast1Special' customClass='' /></li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <div className="u-flex-end u-component-spacing">
                                                <button className="js-change-panel c-cta" data-target="1" onClick={() => handleResetPassword()}>Reset Password</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default PasswordReset;