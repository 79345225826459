const initialState = {
    controlValues: []
}

const ControlsReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state
    }
}

export default ControlsReducer;