import React, { useEffect, useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Translation from '../controls/Translation';
import { authHeader } from '../../helpers/auth-header';
import { useCallback } from 'react';

const Insurance = prop => {
    let { supplierDetails, handleChange, formatDateDefault, updateDetailsPermission } = prop;
    const [activeTab, setActiveTab] = useState('insurance-tab-0');
    const [insuranceTabList, setInsuranceTabList] = useState([]);

    const toggle = tab => {
        if (activeTab !== tab) {
            setActiveTab(tab);

            var insuranceTab = document.getElementById('insurance-tab-select');
            if (insuranceTab !== null) {
                insuranceTab.value = tab;
            }
        }
    }

    useEffect(() => {

        fetch(`./Suppliers/GetInsuranceTabList`, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => response.json())
            .then(data => {
                setInsuranceTabList(data);
            })
            .catch(err => {
                console.log(err);
            });
    }, [])

    const handleTabMenu = useCallback((tabMenu, event) => {
        if (tabMenu && event.target.value) {
            tabMenu.querySelector('#' + event.target.value).click();
        }
    }, []);


    useEffect(() => {
        if (Object.keys(insuranceTabList).length > 0) {
            const tabInsuranceSelect = document.querySelector('.js-insurance-tab-select');
            const tabInsuranceMenu = document.querySelector('.' + tabInsuranceSelect.dataset.tabTarget);

            tabInsuranceSelect.addEventListener('change', event => {
                handleTabMenu(tabInsuranceMenu, event);
            });

            return function cleanup() {
                window.removeEventListener('change', handleTabMenu());
            }
        }
    }, [handleTabMenu, insuranceTabList]);

    if (Object.keys(insuranceTabList).length > 0) {
        return (
            <>
                <div className="c-tab-select">
                    <label className="c-tab-select__label" htmlFor="tab-select">Insurance View:</label>
                    <select id="insurance-tab-select" className="c-tab-select__select js-insurance-tab-select" data-tab-target="js-insurance-tabs" defaultValue={activeTab}>
                        {Object.entries(insuranceTabList).map((insurance, i) => {
                            if (insurance[1].active) {
                                return (
                                    <option key={i} value={insurance[1].tabName} >{`${insurance[0]}`}</option>)
                            } else {
                                return null;
                            }
                        })}
                    </select>
                </div>

                <div className='tabs'>
                    <Nav tabs className="js-insurance-tabs">
                        {Object.entries(insuranceTabList).map((insurance, i) => {
                            if (insurance[1].active) {
                                return (
                                    <NavItem key={i}>
                                        <NavLink
                                            className={classnames({ active: activeTab === `${insurance[1].tabName}` })}
                                            id={`${insurance[1].tabName}`}
                                            data-toggle='tab'
                                            aria-controls={`${insurance[1].tabName}`}
                                            aria-label={`${insurance[0].toLowerCase()}`}
                                            onClick={() => { toggle(`${insurance[1].tabName}`); }}
                                        >
                                            {`${insurance[0]}`}
                                        </NavLink>
                                    </NavItem>
                                )
                            } else {
                                return null;
                            }
                        })}
                    </Nav>
                </div>

                <TabContent activeTab={activeTab}>
                    <TabPane id='insurance-tab-0' tabId='insurance-tab-0'>
                        <div className='tab-pane__inner top'>
                            <div className='c-form'>
                                <h4><Translation ID="EmployerInsurance" /></h4>
                                <div className='c-form__input-group'>
                                    <Translation ID="Insurer" htmlFor='empInsurer' />
                                    <input name='empInsurer' id='empInsurer' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.empInsurer} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className='c-form__input-group'>
                                    <Translation ID="InsuranceNumber" htmlFor='empLiabilityPolicy' />
                                    <input name='empLiabilityPolicy' id='empLiabilityPolicy' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.empLiabilityPolicy} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className='c-form__input-group'>
                                    <Translation ID="InsuranceStartDate" htmlFor='empLiabilityStart' />
                                    <input id="empLiabilityStart" name="empLiabilityStart" className="c-form__input" disabled={!updateDetailsPermission} type="date" autoComplete="false" defaultValue={formatDateDefault(supplierDetails.empLiabilityStart)} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className='c-form__input-group'>
                                    <Translation ID="InsuranceEndDate" htmlFor='empLiabilityEnd' />
                                    <input id="empLiabilityEnd" name="empLiabilityEnd" className="c-form__input" disabled={!updateDetailsPermission} type="date" autoComplete="false" defaultValue={formatDateDefault(supplierDetails.empLiabilityEnd)} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className='c-form__input-group'>
                                    <Translation ID="InsuranceValuePerEvent" htmlFor='empLiabilityValue' />
                                    <input name='empLiabilityValue' id='empLiabilityValue' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.empLiabilityValue} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className='c-form__input-group'>
                                    <Translation ID="InsuranceValueAggregated" htmlFor='empLiabilityValueAgg' />
                                    <input name='empLiabilityValueAgg' id='empLiabilityValueAgg' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.empLiabilityValueAgg} onChange={(e) => handleChange(e)} />
                                </div>

                            </div>
                        </div>
                    </TabPane>
                    <TabPane id='insurance-tab-1' tabId='insurance-tab-1'>
                        <div className='tab-pane__inner'>
                            <div className='c-form'>
                                <h4><Translation ID="PublicLiabilityInsurance" /></h4>
                                <div className='c-form__input-group'>
                                    <Translation ID="Insurer" htmlFor='publicInsurer' />
                                    <input name='publicInsurer' id='publicInsurer' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.publicInsurer} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className='c-form__input-group'>
                                    <Translation ID="InsuranceNumber" htmlFor='publicLiabilityPolicy' />
                                    <input name='publicLiabilityPolicy' id='publicLiabilityPolicy' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.publicLiabilityPolicy} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className='c-form__input-group'>
                                    <Translation ID="InsuranceStartDate" htmlFor='publicLiabilityStart' />
                                    <input id="publicLiabilityStart" name="publicLiabilityStart" className="c-form__input" disabled={!updateDetailsPermission} type="date" autoComplete="false" defaultValue={formatDateDefault(supplierDetails.publicLiabilityStart)} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className='c-form__input-group'>
                                    <Translation ID="InsuranceEndDate" htmlFor='publicLiabilityEnd' />
                                    <input id="publicLiabilityEnd" name="publicLiabilityEnd" className="c-form__input" disabled={!updateDetailsPermission} type="date" autoComplete="false" defaultValue={formatDateDefault(supplierDetails.publicLiabilityEnd)} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className='c-form__input-group'>
                                    <Translation ID="InsuranceValuePerEvent" htmlFor='publicLiabilityValue' />
                                    <input name='publicLiabilityValue' id='publicLiabilityValue' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.publicLiabilityValue} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className='c-form__input-group'>
                                    <Translation ID="InsuranceValueAggregated" htmlFor='publicLiabilityValueAgg' />
                                    <input name='publicLiabilityValueAgg' id='publicLiabilityValueAgg' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.publicLiabilityValueAgg} onChange={(e) => handleChange(e)} />
                                </div>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane id='insurance-tab-2' tabId='insurance-tab-2'>
                        <div className='tab-pane__inner'>
                            <div className='c-form'>
                                <h4><Translation ID="ProfessionalIndemnityInsurance" /></h4>
                                <div className='c-form__input-group'>
                                    <Translation ID="Insurer" htmlFor='profIndemInsurer' />
                                    <input name='profIndemInsurer' id='profIndemInsurer' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.profIndemInsurer} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className='c-form__input-group'>
                                    <Translation ID="InsuranceNumber" htmlFor='profIndemPolicy' />
                                    <input name='profIndemPolicy' id='profIndemPolicy' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.profIndemPolicy} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className='c-form__input-group'>
                                    <Translation ID="InsuranceStartDate" htmlFor='profIndemStart' />
                                    <input id="profIndemStart" name="profIndemStart" className="c-form__input" disabled={!updateDetailsPermission} type="date" autoComplete="false" defaultValue={formatDateDefault(supplierDetails.profIndemStart)} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className='c-form__input-group'>
                                    <Translation ID="InsuranceEndDate" htmlFor='profIndemEnd' />
                                    <input id="profIndemEnd" name="profIndemEnd" className="c-form__input" disabled={!updateDetailsPermission} type="date" autoComplete="false" defaultValue={formatDateDefault(supplierDetails.profIndemEnd)} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className='c-form__input-group'>
                                    <Translation ID="InsuranceValuePerEvent" htmlFor='profIndemValue' />
                                    <input name='profIndemValue' id='profIndemValue' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.profIndemValue} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className='c-form__input-group'>
                                    <Translation ID="InsuranceValueAggregated" htmlFor='profIndemValueAgg' />
                                    <input name='profIndemValueAgg' id='profIndemValueAgg' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.profIndemValueAgg} onChange={(e) => handleChange(e)} />
                                </div>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane id='insurance-tab-3' tabId='insurance-tab-3'>
                        <div className='tab-pane__inner'>
                            <div className='c-form'>
                                <h4><Translation ID="CARLIInsurance" /></h4>
                                <div className='c-form__input-group'>
                                    <Translation ID="Insurer" htmlFor='carliInsurer' />
                                    <input name='carliInsurer' id='carliInsurer' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.carliInsurer} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className='c-form__input-group'>
                                    <Translation ID="InsuranceNumber" htmlFor='carliLiabilityNumber' />
                                    <input name='carliLiabilityNumber' id='carliLiabilityNumber' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.carliLiabilityNumber} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className='c-form__input-group'>
                                    <Translation ID="InsuranceStartDate" htmlFor='carliStartDate' />
                                    <input id="carliStartDate" name="carliStartDate" className="c-form__input" disabled={!updateDetailsPermission} type="date" autoComplete="false" defaultValue={formatDateDefault(supplierDetails.carliStartDate)} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className='c-form__input-group'>
                                    <Translation ID="InsuranceEndDate" htmlFor='carliEndDate' />
                                    <input id="carliEndDate" name="carliEndDate" className="c-form__input" disabled={!updateDetailsPermission} type="date" autoComplete="false" defaultValue={formatDateDefault(supplierDetails.carliEndDate)} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className='c-form__input-group'>
                                    <Translation ID="InsuranceValuePerEvent" htmlFor='carliValuePerEvent' />
                                    <input name='carliValuePerEvent' id='carliValuePerEvent' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.carliValuePerEvent} onChange={(e) => handleChange(e)} />
                                </div>
                                <div className='c-form__input-group'>
                                    <Translation ID="InsuranceValueAggregated" htmlFor='carliValueAggregated' />
                                    <input name='carliValueAggregated' id='carliValueAggregated' type='text' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.carliValueAggregated} onChange={(e) => handleChange(e)} />
                                </div>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane id='insurance-tab-4' tabId='insurance-tab-4'>
                        <div className='tab-pane__inner'>
                            <div className='c-form'>
                                <h4><Translation ID="OtherInsturances" /></h4>

                                <div className='c-form__input-group'>
                                    <Translation ID="OtherInsturances" htmlFor='otherInsurances' />
                                    <input name='otherInsurances' id='otherInsurances' type='textarea' className='c-form__input' disabled={!updateDetailsPermission} defaultValue={supplierDetails.otherInsurances} onChange={(e) => handleChange(e)} />
                                </div>
                            </div>
                        </div>
                    </TabPane>
                </TabContent>
            </>
        );
    } else {
        return (<></>);
    }
}

export default Insurance;