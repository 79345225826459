import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Alert, Button, Card, CardBody, Col, Row } from 'reactstrap';
import { authHeader } from "../../helpers/auth-header";
import { getApplicationType, getContactID, getSupplierID, goTo, handleConfirm, handleTimedSuccess, logIntoPortal, pageEnabled, registerOnPortal, showCustomError, useQuery } from '../../helpers/general';
import { history } from '../../helpers/history';
import BackToDashboard from '../controls/BackToDashboard';
import BackToOrganisationList from '../controls/BackToOrganisationList';
import ScrollArrow from '../controls/ScrollArrow';
import SpinnerOverlay from '../layout/SpinnerOverlay';
import TenderSearchResults from '../Tenders/TenderSearchResults';

const moment = require('moment');

const TenderDetails = () => {

    const [user, setUser] = useState({ supplierID: -1, contactID: -1 });
    const { tenders } = useSelector(state => state);
    const [organisationDetails, setOrganisationDetails] = useState({});
    const [isSearching, setIsSearching] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);
    const [searchParams, setSearchParams] = useState({ searchObject: {}, prevLocation: '' });
    const [isAuthorised, setIsAuthorised] = useState(null);
    const [packageService, setPackageService] = useState({ id: 0, price: 0 });
    const [packagesEnabled, setPackagesEnabled] = useState(false);
    const [portalPermissions, setPortalPermissions] = useState({ canRegisterPortal: false, canPurchaseProducts: false })
    const [isPageEnabled, setIsPageEnabled] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [showFeeds, setShowFeeds] = useState(null);

    let query = useQuery();
    let portalID = query.get('oid');
    let location = useLocation();

    useEffect(() => {
        setUser(prev => ({
            ...prev,
            supplierID: getSupplierID(),
            contactID: getContactID()
        }))
    }, []);

    useEffect(() => {
        pageEnabled('Nav_Portals', '/404').then(enabled => { setIsPageEnabled(enabled); });

        if (isAuthorised === null && user.supplierID > 0) {
            fetch(`./Permissions/PermissionsPortals?contactID=${user.contactID}`, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        setPortalPermissions({
                            canRegisterPortal: data.canRegisterPortal,
                            canPurchaseProducts: data.canPurchaseProducts
                        });
                    }
                })
                .catch(error => console.log('Error: ', error));

            getApplicationType()
                .then(application => {
                    fetch(`./Organisations/IsOrganiserVisibleOnSystem?organiserID=${portalID}&application=${application}`, {
                        method: 'GET',
                        headers: authHeader(false, false) // MADE PUBLIC, NEED TO REVIEW AFTER BLPD
                    })
                        .then(response => response.json())
                        .then(authorised => {
                            if (authorised) {
                                fetch(`./Organisations/GetOrganisationDetails?portalID=${portalID}&supplierID=${user.supplierID}&contactID=${user.contactID}&systemName=${application}`, {
                                    method: 'GET',
                                    headers: authHeader()
                                })
                                    .then(response => response.json())
                                    .then(data => {
                                        setOrganisationDetails(data);

                                        fetch(`./Settings/ArePackagesEnabled`, {
                                            method: 'GET',
                                            headers: authHeader(false, true)
                                        })
                                            .then(response => response.json())
                                            .then(packagesEnabledData => {
                                                setPackagesEnabled(packagesEnabledData)

                                                if (data.apiUserID === 1) {
                                                    fetch(`./Suppliers/GetSupplierAllowance?supplierID=${user.supplierID}&packageService=pservice_intendorganiserfeed`, {
                                                        method: 'GET',
                                                        headers: authHeader()
                                                    })
                                                        .then(response => response.json())
                                                        .then(showIntendFeeds => {
                                                            setShowFeeds(!packagesEnabledData || showIntendFeeds);

                                                            if (packagesEnabledData === false || showIntendFeeds === true) {
                                                                setSearchParams({
                                                                    searchObject: {
                                                                        keywords: '',
                                                                        region: '',
                                                                        organisationid: portalID,
                                                                        dateAddedFrom: moment().subtract(6, 'months').format('YYYY-MM-DD'),
                                                                        dateAddedTo: moment().format('YYYY-MM-DD'),
                                                                        dateEOIFrom: '',
                                                                        dateEOITo: '',
                                                                        orderBy: 0,
                                                                        orderByDirection: 0,
                                                                        matchToCPV: false,
                                                                        includeEOIPassed: false,
                                                                        includeEOI: false,
                                                                        includeIgnored: false,
                                                                        includeCompanyEntries: false,
                                                                        supplierID: user.supplierID,
                                                                        systemName: application
                                                                    },
                                                                    prevLocation: `${location.pathname}${location.search}`
                                                                });
                                                            } else {
                                                                setSearchParams({
                                                                    searchObject: {
                                                                        systemName: ''
                                                                    },
                                                                    prevLocation: `${location.pathname}${location.search}`
                                                                });
                                                            }
                                                        });
                                                    setFirstLoad(false);
                                                } else {
                                                    fetch(`./Suppliers/GetSupplierAllowance?supplierID=${user.supplierID}&packageService=pservice_organiserpublicfeed`, {
                                                        method: 'GET',
                                                        headers: authHeader()
                                                    })
                                                        .then(response => response.json())
                                                        .then(showPublicFeeds => {
                                                            setShowFeeds(showPublicFeeds);

                                                            if (showPublicFeeds === true) {
                                                                setSearchParams({
                                                                    searchObject: {
                                                                        keywords: '',
                                                                        region: '',
                                                                        organisationid: portalID,
                                                                        dateAddedFrom: moment().subtract(6, 'months').format('YYYY-MM-DD'),
                                                                        dateAddedTo: moment().format('YYYY-MM-DD'),
                                                                        dateEOIFrom: '',
                                                                        dateEOITo: '',
                                                                        orderBy: 0,
                                                                        orderByDirection: 0,
                                                                        matchToCPV: false,
                                                                        includeEOIPassed: false,
                                                                        includeEOI: false,
                                                                        includeIgnored: false,
                                                                        includeCompanyEntries: false,
                                                                        supplierID: user.supplierID,
                                                                        systemName: application
                                                                    },
                                                                    prevLocation: `${location.pathname}${location.search}`
                                                                });
                                                            }
                                                            else {
                                                                setSearchParams({
                                                                    searchObject: {
                                                                        systemName: ''
                                                                    },
                                                                    prevLocation: `${location.pathname}${location.search}`
                                                                });
                                                            }
                                                        });
                                                    setFirstLoad(false);
                                                }
                                            })
                                            .catch(err => {
                                                console.log(err);
                                            })
                                    })
                                    .catch(error => {
                                        console.log('Error: ', error);
                                    });

                                fetch(`./Packages/GetPackageServiceDetails?packageService=pservice_portals`, {
                                    method: 'GET',
                                    headers: authHeader(false, false)
                                })
                                    .then(response => response.json())
                                    .then(data => {
                                        if (data) {
                                            setPackageService({ id: data.uniqueID, price: data.priceExc })
                                        }
                                    })
                                    .catch(err => {
                                        console.log("ERROR", err);
                                    });

                                fetch(`./Settings/ArePackagesEnabled`, {
                                    method: 'GET',
                                    headers: authHeader(false, true)
                                })
                                    .then(response => response.json())
                                    .then(data => {
                                        setPackagesEnabled(data)
                                    })
                                    .catch(err => {
                                        console.log(err);
                                    })

                                setIsAuthorised(true);
                            } else {
                                handleTimedSuccess('error', 'Portal Unavailable', 'The portal is unavailable to view on this system.', 3000,
                                    () => {
                                        history.goBack()
                                    })
                            }
                        })
                })
        }
    }, [isAuthorised, location.pathname, location.search, portalID, user.contactID, user.supplierID]);

    useEffect(() => {
        window.addEventListener('registeredOnPortal', (e) => {
            if (sessionStorage.getItem('registeredOnPortal')) {

                setOrganisationDetails(prevState => ({
                    ...prevState,
                    registered: true,
                    status: 0
                }));

                sessionStorage.removeItem('registeredOnPortal');
            }
        })
    }, []);

    const handleGoToPortal = (registered, url, portalName) => {
        logIntoPortal(registered, url, user.supplierID, user.contactID, -1, portalID, portalName);
    }


    const changePortalStatus = (status) => {
        setOrganisationDetails(prev => ({
            ...prev,
            'status': status
        }))
    }

    const addPortalToBasket = (portalID, portalName) => {
        let searchData = {
            "PackageServiceID": packageService.id,
            "TendererPackageServiceID": portalID,
            "Description": portalName,
            "PriceExcVat": packageService.price
        };

        fetch(`./Payment/AddBasketItem?supplierID=${user.supplierID}&contactID=${user.contactID}`, {
            method: 'POST',
            headers: authHeader(false, false),
            body: JSON.stringify(searchData)
        })
            .then(response => response.json())
            .then(data => {
                if (data > 0) {
                    changePortalStatus(2);
                }
            })
            .catch(err => {
                console.log("ERROR", err);
            })
    }

    const handleRemoveFromBasket = uniqueID => {
        if (uniqueID) {
            let removeData = {
                "SupplierID": user.supplierID,
                "ContactID": user.contactID,
                "PackageServiceID": packageService.id,
                "TendererPackageServiceID": uniqueID
            };

            fetch(`./Payment/RemoveBasketItem`, {
                method: 'POST',
                headers: authHeader(false, false),
                body: JSON.stringify(removeData)
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        changePortalStatus(1);
                    }
                })
                .catch(err => {
                    console.log("ERROR", err);
                })

        }
    }

    const linkPortalToAccount = (uniqueID) => {
        let searchData = {
            "SupplierID": user.supplierID,
            "ContactID": user.contactID,
            "PackageServiceID": packageService.id,
            "TendererPackageServiceID": uniqueID
        };

        getApplicationType()
            .then(application => {
                fetch(`./Suppliers/LinkPortalToAccount?systemName=${application}`, {
                    method: 'POST',
                    headers: authHeader(false, false),
                    body: JSON.stringify(searchData)
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data) {
                            changePortalStatus(0)

                            handleTimedSuccess('success', 'Account Linked', `Your account has been linked - portal allowance has been used.`);
                        } else {
                            showCustomError("A problem occurred while retrieving your portal link, please try again.", "Cannot link portal", "error")
                        }
                    })
                    .catch(err => {
                        console.log("ERROR", err);
                    })
            });
    }

    const handleSelectPortal = (portalID, portalName, isRegistered) => {
        getApplicationType()
            .then(application => {
                fetch(`./Suppliers/SupplierHasAvailableAllowance?supplierID=${user.supplierID}&packageService=pservice_portals&systemName=${application}`, {
                    method: 'GET',
                    headers: authHeader(false, false)
                })
                    .then(allowanceResponse => allowanceResponse.json())
                    .then(allowanceData => {
                        if (allowanceData && allowanceData > 0) {
                            handleConfirm(
                                `You currently have ${allowanceData} portal allowance - would you like to use your portal allowance and continue?`,
                                function () {
                                    if (isRegistered) {
                                        linkPortalToAccount(portalID, portalName, 0)
                                    } else {
                                        registerOnPortal(portalID, organisationDetails.entityName, user.supplierID, user.contactID, application);
                                    }
                                }
                            );

                        } else {
                            addPortalToBasket(portalID, portalName)
                        }
                    })
            });
    }

    const handleRegisterOnPortal = (portalID, portalName, supplierID, contactID) => {
        getApplicationType()
            .then(application => {
                registerOnPortal(portalID, portalName, supplierID, contactID, application)
            });
    }

    if (isPageEnabled === true && organisationDetails) {
        return (
            <>
                <Card className="main-content__inner">
                    {location.state === undefined &&
                        <BackToDashboard />
                    }
                    {location.state !== undefined && location.state.prevLocation && location.state.prevLocation.startsWith('/organisation') &&
                        <BackToOrganisationList
                            portalSearchObject={location.state.portalSearchObject}
                            prevLocation={location.state.prevLocation}
                        />
                    }

                    <CardBody>
                        <div className="c-card card u-component-spacing">
                            <div className="card-body">
                                <h2 className="c-broken-underline">{organisationDetails.entityName}</h2>
                            </div>
                        </div>
                    </CardBody>
                    <div style={{ padding: "0 1.25rem" }}>
                        <Row>
                            <Col xl={3}>
                                <section className="c-card card card-opportunity" style={{ position: "sticky", top: "70px" }}>
                                    <div className="card-header ic-details">
                                        <h3>{organisationDetails.entityName}</h3>
                                    </div>
                                    <div className="card-body" style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", gap: "1rem 5rem", flexDirection: "column" }}>
                                        <div>
                                            {organisationDetails.apiUserID === 1 && <p className="c-result__tender-source c-result__tender-source--in-tend">In-tend Organisation</p>}
                                            {organisationDetails.apiUserID === 2 && <p className="c-result__tender-source c-result__tender-source--eu">OJEU Organisation</p>}
                                            {organisationDetails.apiUserID === 7 && <p className="c-result__tender-source c-result__tender-source--uk--cf">Contracts Finder Organisation</p>}
                                            {organisationDetails.apiUserID === 8 && <p className="c-result__tender-source c-result__tender-source--uk--fts">Find a Tender Organisation</p>}
                                        </div>
                                        <div>
                                            <h4 className="u-branded-underline u-component-spacing">Address</h4>
                                            <div className="c-address u-component-spacing">
                                                <span className="c-address__address-line">{organisationDetails.building}</span>
                                                <span className="c-address__address-line">{organisationDetails.street}</span>
                                                <span className="c-address__address-line">{organisationDetails.town}</span>
                                                <span className="c-address__address-line">{organisationDetails.county}</span>
                                                <span className="c-address__address-line">{organisationDetails.country}</span>
                                                <span className="c-address__address-line">{organisationDetails.postCode}</span>
                                            </div>
                                        </div>
                                        <div>
                                            {(organisationDetails.telephone || organisationDetails.mainContactEmail || organisationDetails.website) &&
                                                <>
                                                    <h4 className="u-branded-underline u-component-spacing">Contact Information</h4>
                                                    <dl className="u-component-spacing">
                                                        {organisationDetails.telephone !== '' &&
                                                            <>
                                                                <dt className="u-component-spacing">Phone</dt>
                                                                <a href={`tel:${organisationDetails.telephone}`} className='c-text-link c-page-intro'><u>{organisationDetails.telephone}</u></a>
                                                            </>
                                                        }

                                                        {organisationDetails.mainContactEmail !== '' &&
                                                            <>
                                                                <dt className="u-component-spacing">Email</dt>
                                                                <dd>
                                                                    <a href={`mailto:${organisationDetails.mainContactEmail}`} className='c-text-link c-page-intro'><u>{organisationDetails.mainContactEmail}</u></a>
                                                                </dd>
                                                            </>
                                                        }
                                                        {organisationDetails.website !== '' &&
                                                            <>
                                                                <dt className="u-component-spacing">Website</dt>
                                                                <dd>
                                                                    <a href={`${organisationDetails.website}`} target="_blank" rel="noreferrer" className='c-text-link c-page-intro'><u>{organisationDetails.website}</u></a>
                                                                </dd>
                                                            </>
                                                        }
                                                    </dl>
                                                </>
                                            }

                                            {organisationDetails.apiUserID === 1 && (organisationDetails.status === 1 || organisationDetails.status === 2) &&
                                                <Alert color="danger" className="u-component-spacing--2x" style={{ textAlign: "center", textWrap: "balance" }}>
                                                    Purchase this portal for Single Sign On to the portal
                                                </Alert>
                                            }

                                            {/* STATUS = 0: Purchase not required */}
                                            <div className="c-tender-actions" style={{ flexWrap: "wrap" }}>


                                                {organisationDetails.apiUserID === 1 &&
                                                    <>
                                                        {/* STATUS = 0: No limitation */}
                                                        {organisationDetails.status === 0 &&
                                                            <>
                                                                {organisationDetails.registerControlStatus > 0 && !organisationDetails.registered && (organisationDetails.registerControlStatus === 1 || !packagesEnabled) && portalPermissions.canRegisterPortal &&
                                                                    <button id='register' className="c-tender-actions__action-button c-tender-actions__action-button--100 c-tender-actions__action-button--register u-component-spacing--2x" onClick={() => { handleRegisterOnPortal(portalID, organisationDetails.entityName, user.supplierID, user.contactID) }}>
                                                                        Register with Organisation
                                                                    </button>
                                                                }

                                                                {organisationDetails.registered &&
                                                                    <button className="c-cta u-component-spacing--2x c-tender-actions__action-button--100" onClick={() => { handleGoToPortal(organisationDetails.registered, organisationDetails.portalURL, organisationDetails.entityName) }}>
                                                                        Log in to supplier portal
                                                                    </button>
                                                                }
                                                            </>
                                                        }

                                                        {/* STATUS = 1: Supplier has no allowance, requires portal purchase, BASKET icon */}
                                                        {organisationDetails.status === 1 &&
                                                            <button className="c-tender-actions__action-button c-tender-actions__action-button--100 c-tender-actions__action-button--add-to-basket" disabled={!portalPermissions.canPurchaseProducts} onClick={(() => handleSelectPortal(portalID, organisationDetails.entityName, organisationDetails.registered))}>
                                                                Add to basket
                                                            </button>
                                                        }

                                                        {/* STATUS = 2: Portal already in the basket, BASKET REMOVE icon*/}
                                                        {organisationDetails.status === 2 &&
                                                            <>
                                                                <Button size="sm" color='link' className='c-text-link' style={{ width: "100%", textAlign: "center", display: "block" }} disabled={!portalPermissions.canPurchaseProducts} onClick={(() => handleRemoveFromBasket(portalID))} >
                                                                    Remove from basket
                                                                </Button>
                                                                <button id={`removeBasketPortal-${portalID}`} className="c-tender-actions__action-button c-tender-actions__action-button--100 c-tender-actions__action-button--view-basket" disabled={!portalPermissions.canPurchaseProducts} onClick={() => history.push(`/basket`)}>
                                                                    View basket
                                                                </button>
                                                            </>
                                                        }

                                                        {/* STATUS = 3: Free account, requires account upgrade, GREEN ARROW icon*/}
                                                        {organisationDetails.status === 3 &&
                                                            <button className="c-tender-actions__action-button c-tender-actions__action-button--100 c-tender-actions__action-button--upgrade u-component-spacing--2x" title='Package Upgrade Required' onClick={() => history.push(`/upgrade`)}>
                                                                Upgrade
                                                            </button>
                                                        }

                                                        {/* STATUS = 4: Supplier has available portal allowance, portal allowance portalPermissions.canRegisterPortal && to be used - already registered on another Sell2, Link account.  LINK button
                                                        STATUS = 5: Unlimited balance */}
                                                        {(organisationDetails.status === 4 || organisationDetails.status === 5) && organisationDetails.registered && portalPermissions.canRegisterPortal &&
                                                            <button className="c-tender-actions__action-button c-tender-actions__action-button--100 c-tender-actions__action-button--link u-component-spacing--2x" onClick={() => { handleSelectPortal(portalID, organisationDetails.entityName, organisationDetails.registered) }}>
                                                                Link Existing Sell2 Registration
                                                            </button>
                                                        }

                                                        {/* STATUS = 4: Supplier has available portal allowance, portal allowance to be used - needs to register.  LINK button
                                                        STATUS = 5: Unlimited balance */}
                                                        {(organisationDetails.status === 4 || organisationDetails.status === 5) && !organisationDetails.registered &&
                                                            <>
                                                                {portalPermissions.canRegisterPortal &&
                                                                    <button id='register' className="c-tender-actions__action-button c-tender-actions__action-button--100 c-tender-actions__action-button--register u-component-spacing--2x" onClick={() => { handleRegisterOnPortal(portalID, organisationDetails.entityName, user.supplierID, user.contactID) }}>
                                                                        Register with Organisation
                                                                    </button>
                                                                }
                                                            </>
                                                        }

                                                        {/* STATUS = 6: Portal has been purchased, but not registered with yet, REGISTER button */}
                                                        {organisationDetails.status === 6 && portalPermissions.canRegisterPortal &&
                                                            <button id='register' className="c-tender-actions__action-button c-tender-actions__action-button--100 c-tender-actions__action-button--register u-component-spacing--2x" onClick={() => { handleRegisterOnPortal(portalID, organisationDetails.entityName, user.supplierID, user.contactID) }}>
                                                                Register with Organisation
                                                            </button>
                                                        }

                                                        {!organisationDetails.registered && organisationDetails.status !== 3 &&
                                                            <button className="c-tender-actions__action-button c-tender-actions__action-button--100 c-tender-actions__action-button--portal-link" onClick={() => { handleGoToPortal(organisationDetails.registered, organisationDetails.portalURL, organisationDetails.entityName) }}>
                                                                Go to supplier portal
                                                            </button>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </Col>
                            <Col xl={9} className='portal-tender-feed-scroll'>
                                {showFeeds === true &&
                                    <>
                                        <h2 className="c-page-title u-component-spacing--1x">Organisations active opportunities - published in the last 6 months'</h2>
                                    </>
                                }
                                {showFeeds === false &&
                                    <>
                                        <h4 className="c-page-title u-component-spacing--1x">
                                            Feeds for this organisation are unavailable.  {organisationDetails.apiUserID === 1 ? 'You need to upgrade your package to view the Organisation Feeds' : 'This service can be purchased from the Products page.'}
                                        </h4>
                                        {organisationDetails.apiUserID === 1 &&
                                            <button className="c-tender-actions__action-button c-tender-actions__action-button--upgrade u-component-spacing--2x" onClick={() => history.push(`/upgrade`)}>
                                                Upgrade
                                            </button>}
                                        {organisationDetails.apiUserID !== 1 &&
                                            <button id='purchaseSaveSearches' className="c-tender-actions__action-button c-tender-actions__action-button--purchase u-component-spacing--2x" onClick={(() => goTo('/products'))}>
                                                Purchase Organsation Public Feeds
                                            </button>
                                        }

                                    </>
                                }
                                <TenderSearchResults
                                    searchParams={searchParams}
                                    isSearching={isSearching}
                                    pages
                                    loggedIn
                                    setIsSearching={setIsSearching}
                                    firstLoad={firstLoad}
                                    showOrganisationTenders={true}
                                    layoutViewMode='PortalTenderViewMode'
                                    pageNumber={pageNumber}
                                    setPageNumber={setPageNumber}
                                    hideFeed={showFeeds !== true}
                                />
                            </Col>
                        </Row>
                    </div>
                </Card>
                <ScrollArrow />
                {tenders.isLoading &&
                    <SpinnerOverlay />
                }
            </>
        );
    } else {
        return null;
    }
}

export default TenderDetails;