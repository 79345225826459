import React, { useEffect, useState } from 'react';
import { getApplicationType } from '../../helpers/general';

/**
 * The theme components only imports it's theme CSS-file. These components are lazy
 * loaded, to enable "code splitting" (in order to avoid the themes being bundled together)
 */
const BLPD = React.lazy(() => import('../themes/BLPD'));
const SEShared = React.lazy(() => import('../themes/SEShared'));
const Sell2UK = React.lazy(() => import('../themes/Sell2UK'));
const LiveDemo = React.lazy(() => import('../themes/LiveDemo'));

const StylesheetSelector = props => {
    let { children } = props;

    const [appName, setAppName] = useState('');

    useEffect(() => {
        getApplicationType().then(application => {
            setAppName(application.toUpperCase());
        });
    }, [])

    const Loading = () => <></>;

    if (appName.length) {
        return (
            <>
                <React.Suspense fallback={<Loading /> }>
                    {appName === 'SELL2BLPD' && <BLPD />}
                    {appName === 'SELL2SESHARED' && <SEShared />}
                    {appName === 'SELL2UK' && <Sell2UK />}
                    {appName === 'SELL2LIVEDEMO' && <LiveDemo />}
                    {children}
                </React.Suspense>
            </>
        );
    } else {
        return null;
    }
};

export default StylesheetSelector;