import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, Card, CardBody, CardHeader, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { getApplicationType } from '../../helpers/general';
import { history } from '../../helpers/history';
import DataTable from '../controls/DataTable';
import Translation from '../controls/Translation';

const moment = require('moment');

const SupplierTenders_Modal = props => {
    let { id, isOpen, toggle, cardClass, panelName, tableName, supplierID, statusID } = props;
    const [searchFilters, setSearchFilters] = useState({ showAll: false, days: 28 })
    const [finalSearchString, setFinalSearchString] = useState('');


    useEffect(() => {
        if (supplierID) {
            // statusID is enum from CvExpiringTenders
            let days = searchFilters.days === '' ? 0 : searchFilters.days;
            getApplicationType()
                .then(application => {
                    setFinalSearchString(`supplierID=${supplierID}&statusID=${statusID}&showall=${searchFilters.showAll}&days=${days}&systemName=${application}`)
                });
        }
    }, [searchFilters.days, searchFilters.showAll, statusID, supplierID]);

    const handleChange = (e) => {
        e.persist();
        let val = e.target.value;

        setSearchFilters(value => ({
            ...value,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : val
        }));
    }

    const handleShowAllChange = (e) => {
        e.persist();

        if (!e.target.checked) {
            setSearchFilters(value => ({
                ...value,
                'days': 28
            }));
            document.getElementById(`${tableName}_modal_days`).disabled = false;
        }
        else {
            setSearchFilters(value => ({
                ...value,
                'days': 0
            }));

            document.getElementById(`${tableName}_modal_days`).disabled = true;
        }
    }

    return (
        <Modal
            id={id}
            isOpen={isOpen}
            toggle={toggle}
            size="xl"
            centered
            backdrop="static"
            unmountOnClose
        >
            <ModalBody>
                {finalSearchString && finalSearchString.length &&
                    <Card className={cardClass}>
                        <CardHeader className="ic-expiring">
                            <span>
                                <Translation ID={panelName} />
                            </span>
                        </CardHeader>
                        <CardBody className='padding-5-1 card-module'>
                            <Row className="c-widget-filter">
                                <Col md={12}>
                                    <div className="c-widget-filter__deadline-container">
                                        <Translation ID='ShowingOpportunitiesWithDeadlineIn' htmlFor={`${tableName}_modal_days`} customClass="u-visually-hidden" />
                                        <span aria-hidden="true"><Translation ID='ShowingOpportunitiesWithDeadlineIn' customClass="c-widget-filter__deadline-label c-form__label" /></span>
                                        <input id={`${tableName}_modal_days`} name='days' type="number" className="c-widget-filter__deadline-input" value={searchFilters.days} onChange={((e) => handleChange(e))} />
                                        <span aria-hidden="true"><Translation ID='Days' /></span>
                                    </div>
                                </Col>
                                <Col md={12} className='c-form__input-group'>
                                    <div className="c-widget-filter__checkbox-container custom-checkbox custom-control">
                                        <input id={`${tableName}_modal_showAll`} name="showAll" type="checkbox" className="custom-control-input" onChange={((e) => { handleShowAllChange(e); handleChange(e) })} />
                                        <Translation ID='ShowAll' htmlFor={`${tableName}_modal_showAll`} customClass="c-widget-filter__checkbox c-search-section__label c-checkbox-label" />
                                    </div>
                                </Col>
                            </Row>
                            <hr />
                            <DataTable
                                id={tableName}
                                columns={columns}
                                info={false}
                                defaultOrder={[1, "asc"]}
                                url="./Suppliers/GetSupplierTenders"
                                params={finalSearchString}
                                isSearching={true}
                                scrollY='550px'
                                pagingType='full'
                                scrollCollapse={true}
                            />
                        </CardBody>
                    </Card>
                }
            </ModalBody>
            <ModalFooter>
                <div className="p-2 text-right col-6">
                    <Button id="close" color="danger" size="sm" onClick={toggle}>{'Close'}</Button>
                </div>
            </ModalFooter>
        </Modal>
    )
}

const columns = [
    { name: "tenderFeedID", data: "tenderFeedID", visible: false },
    {
        name: "title",
        header: "Tender",
        data: (data) => {
            return data.tenderFeedID + '|' + data.title
        },
        createdCell: (td, data) => {
            ReactDOM.render(
                <a
                    href={`/tender-details/${data.split('|')[0]}`}
                    onClick={(e) => { e.preventDefault(); history.push(`/tender-details/${data.split('|')[0]}`) }}
                    style={{ color: '#00A65A' }}>
                    {data.split('|')[1]}
                </a>, td
            )
        },
        width: "75%"
    },
    {
        name: "docsAvailableUntil",
        header: "Deadline Date",
        data: (data) => { return (data.docsAvailableUntil === '' ? '-' : moment(data.docsAvailableUntil).format('DD/MM/YYYY')) },
        width: "25%"
    }
];

export default SupplierTenders_Modal;